<template>
  <DatePicker
    locale="jp"
    hide-input-icon
    auto-apply
    :enable-time-picker="false"
    format="yyyy年MM月dd日"
    model-type="yyyy-MM-dd"
    :text-input="editable"
    :text-input-options="{ enterSubmit: false, tabSubmit: false, openMenu: true, format: ['yyyyMMdd', 'yyyy-MM-dd'] }"
    :id="id"
    :name="name"
    :week-start="0"
    :min-date="minDate"
    :max-date="maxDate"
  />
</template>
<script lang="ts">
import { defineComponent } from 'vue'
/** 日付ボックスコントロール */
export default defineComponent({
  /** 名称 */
  name: 'date-control',
  /** プロパティ */
  props: {
    /** パラメータ */
    parameter: { default: undefined },
    /** ID */
    id: { type: String, default: '' },
    /** タグ名 */
    name: { type: String, default: '' },
    /** スタイル */
    style: { type: String, default: '' },
    /** 最小日 */
    minDate: { type: String, default: '' },
    /** 最大日 */
    maxDate: { type: String, default: '' },
    /** 編集可能 */
    editable: { type: Boolean, default: true }
  },
  emits: ['input'],
  /** セットアップスクリプト */
  setup(props, context) {},
  /** コンピュート定義 */
  computed: {
    /** 設定値 */
    modelValue: {
      /** 値取得 */
      get(): boolean {
        if (this.parameter && this.id) {
          return this.parameter[this.id]
        }
        return false
      },
      /** 値設定 */
      set(setValue: boolean) {
        /** パラメータ有無 */
        if (this.parameter && this.id) {
          this.parameter[this.id] = setValue as never
          this.$emit('input', setValue)
        }
      }
    }
  }
})
</script>

<style lang="stylus">
.dp__month_year_row
  height 45px !important
  padding 5px !important
.dp__month_year_wrap
  flex-direction row-reverse
.dp__menu, .dp__input
  font-size unset !important
.dp__input
  height 34px
  padding 6px 12px !important
  font-size 14px !important
  line-height 1.42857143 !important
  color #555 !important
  background-color #fff !important
  background-image none !important
  border 1px solid #ccc !important
  border-radius 4px !important
.dp__input:focus
  border-color #66afe9 !important
</style>
