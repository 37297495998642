import { Constants } from '@/modules/constants'
import { RoutingService, ViewService } from '@/modules/services'
import { defineComponent, VueElement } from 'vue'

/** 基底ビュークラス */
export default defineComponent({
  /** 名称 */
  name: 'base-view',
  /** プロパティ */
  props: {},
  /** セットアップスクリプト */
  setup(props, context) {
    /** Viewサービス定義 */
    const viewService = new ViewService()
    return { viewService }
  },
  /** マウントイベント */
  mounted() {
    this.$nextTick(async () => {
      // Viewサービス再定義
      this.viewService = new ViewService(this)
      // 初期化処理
      await this.initialize()

      // ロードイベント定義
      document.onload = async () => {
        await this.ready()
      }
      await this.ready()
    })
  },
  /** 関数定義 */
  methods: {
    /** 初期化処理 */
    async initialize() {},
    /** ロードイベント */
    async ready() {
      $('[data-toggle=offcanvas]').off('click')
      $('[data-toggle=offcanvas]').on('click', function () {
        $('.row-offcanvas').toggleClass('active')
        $(this).toggleClass('fa-angle-double-left fa-angle-double-right')
      })
    },
    /** 処理名取得 */
    getAction(mode: string) {
      if (mode == 'view') {
        return '参照'
      } else if (mode == 'edit') {
        return '編集'
      } else if (mode == 'add') {
        return '登録'
      } else if (mode == 'delete') {
        return '削除'
      } else {
        return ''
      }
    },
    /** タイトル設定 */
    setView(title: string, bodyClass: string) {
      // タイトル設定
      document.title = '{0}｜{1}'.format(String(process.env.VUE_APP_SYSTEM_NAME), title)
      // Bodyクラス設定
      document.body.className = ''

      const classArray: string[] = bodyClass.split(' ')
      classArray.forEach(async (key: string) => {
        // クラス存在チェック
        if (document.body.classList && !document.body.classList.contains(key)) {
          // クラスを追加
          document.body.classList.add(key)
        }
      })
      const screenName = document.getElementById('screenName')
      if (screenName) {
        screenName.innerHTML = '{0}'.format(title)
      }
    },
    /**
     * ページ遷移
     * @param name 遷移先名
     * @param params パラメータ
     */
    goto(name: string, params?: any) {
      // ページ遷移
      new RoutingService(this).goto(name, params)
    },
    /** ローディング表示 */
    showLoading() {
      // ローディング表示
      this.viewService.showLoading()
    },
    /** ローディング非表示 */
    hideLoading() {
      // ローディング非表示
      this.viewService.hideLoading()
    },
    /**
     * 情報メッセージ表示
     * @param message メッセージ
     * @param title タイトル
     * @param callback コールバック関数
     */
    showInformation(message: string, title?: string, callback?: () => void) {
      // 情報メッセージ表示
      this.viewService.showInformation(message, title, callback)
    },
    /**
     * 警告メッセージ表示
     * @param message メッセージ
     * @param title タイトル
     * @param callback コールバック関数
     */
    showWarning(message: string, title?: string, callback?: () => void) {
      // 警告メッセージ表示
      this.viewService.showWarning(message, title, callback)
    },
    /**
     * 確認メッセージ表示
     * @param message メッセージ
     * @param title タイトル
     * @param response 応答処理
     */
    showConfirm(message: string, title?: string, response?: (responseCode: Constants.Message.ResponseCode) => void) {
      // 確認メッセージ表示
      this.viewService.showConfirm(message, title, response)
    },
    /**
     * 質問メッセージ表示
     * @param message メッセージ
     * @param title タイトル
     * @param response 応答処理
     */
    showQuestion(message: string, title?: string, response?: (responseCode: Constants.Message.ResponseCode) => void) {
      // 質問メッセージ表示
      this.viewService.showQuestion(message, title, response)
    },
    /** 先頭へ移動 */
    scrollTop() {
      window.scroll({ top: 0, behavior: 'smooth' })
    }
  }
})
