import { RegexpService } from './'
/**
 * 環境変数サービス
 */
export class EnvironmentService {
  /**
   * 設定値取得
   * @param envKey キー
   * @param defaultValue デフォルト値
   * @returns 設定値
   */
  public static get(envKey: string, defaultValue?: string): string {
    // 値を取得
    return process.env[envKey] ? String(process.env[envKey]) : defaultValue ? defaultValue : ''
  }
  /**
   * 設定値取得(Number型)
   * @param envKey キー
   * @param defaultValue デフォルト値
   * @returns 設定値(Number型)
   */
  public static getNumber(envKey: string, defaultValue?: number): number {
    // 値を取得
    const value: string = this.get(envKey)
    // 入力有無
    if (value && RegexpService.isNumber(value)) {
      // 数値変換
      return value as any as number
    } else {
      return defaultValue ? defaultValue : 0
    }
  }
  /**
   * 設定値取得(Boolean型)
   * @param envKey キー
   * @param defaultValue デフォルト値
   * @returns 設定値(Boolean型)
   */
  public static getBoolean(envKey: string, defaultValue?: boolean): boolean {
    // 値を取得
    const value: string = this.get(envKey)
    // 入力有無
    if (value && value.toUpperCase() !== 'FALSE' && value !== '0') {
      // trueを返す
      return true
    } else {
      // falseを返す
      return defaultValue ? defaultValue : false
    }
  }
  /**
   * 設定値取得(配列型)
   * @param envKey キー
   * @param separator 区切り文字
   * @returns 設定値(配列型)
   */
  public static getArray(envKey: string, separator?: string): Array<string> {
    // 値を取得
    const value: string = this.get(envKey)
    // 入力有無
    if (value) {
      // 配列変換
      return value.split(separator ? separator : ',')
    } else {
      return []
    }
  }
}
