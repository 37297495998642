/**
 * 定数定義
 */
export namespace Constants {
  /** リストアイテム */
  export namespace ListItem {
    /** 管理者アカウント選択項目 */
    export const AccountTypeItems = [{ value: '99', text: 'システム管理者' }]
    /** ユーザー種別選択項目 */
    export const UserTypeItems = [
      { value: '01', text: '作業者' },
      { value: '05', text: '印刷工程担当' },
      { value: '07', text: '工程管理者' },
      { value: '09', text: '管理者' }
    ]
    /** はい・いいえ選択項目 */
    export const YesNoItems = [
      { value: '0', text: 'いいえ' },
      { value: '1', text: 'はい' }
    ]
  }
  /**
   * API関連定義
   */
  export namespace Api {
    /**
     * レスポンスコード
     */
    export enum ResponseCode {
      /** 正常終了 */
      OK = 200,
      /** 警告 */
      WARN = 299,
      /** NOT FOUND */
      NOT_FOUND = 404,
      /** INTERNAL SERVER ERROR */
      INTERNAL_ERROR = 500
    }
  }
  /**
   * メッセージ関連定義
   */
  export namespace Message {
    /** 応答結果 */
    export enum ResponseCode {
      /** はい */
      YES = 'yes',
      /** いいえ */
      NO = 'no',
      /** キャンセル */
      CANCEL = 'cancel',
      /** 閉じる */
      CLOSE = 'close'
    }
  }
  /**
   * クライアント関連定義
   */
  export namespace Client {
    /**
     * ブラウザ種別
     */
    export enum BrowserType {
      /** IE */
      IE,
      /** Edge */
      Edge,
      /** ChromiumEdge */
      ChromiumEdge,
      /** Chrome */
      GoogleChrome,
      /** Safari */
      Safari,
      /** FireFox */
      FireFox,
      /** Opera */
      Opera,
      /** その他 */
      Other
    }
  }
  /**
   * 日付関連定義
   */
  export namespace Date {
    /**
     * 曜日
     */
    export const WEEK: Array<string> = ['日', '月', '火', '水', '木', '金', '土']
    export const WEEKMAP: Array<any> = [
      { label: '日', value: 0 },
      { label: '月', value: 1 },
      { label: '火', value: 2 },
      { label: '水', value: 3 },
      { label: '木', value: 4 },
      { label: '金', value: 5 },
      { label: '土', value: 6 }
    ]
  }
  /**
   * 書式関連定義
   */
  export namespace Format {
    /**
     * 日付書式種別
     */
    export enum DateFormatType {
      /** なし: yyyy-MM-dd */
      NONE = 1,
      /** 年月日: yyyy/MM/dd */
      YMD,
      /** 年月日(日本語表記): yyyy年MM月dd日 */
      YMD_JP,
      /** 省略形式: yy/MM/dd */
      SHORT,
      /** 省略形式(日本語表記): yy年MM月dd日 */
      SHORT_JP,
      /** 曜日付き: yyyy/MM/dd (WW) */
      WITH_WEEK,
      /** 曜日付き(日本語表記): yyyy年MM月dd日 (WW) */
      WITH_WEEK_JP,
      /** 時間: hh:mm:ss */
      TIME,
      /** 時間(日本語表記): hh時mm分ss秒 */
      TIME_JP,
      /** 時分: hh:mm */
      HOUR_MIN,
      /** 時分(日本語表記): hh時mm分 */
      HOUR_MIN_JP,
      /** 年: yyyy */
      YEAR,
      /** 年(日本語表記): yyyy年 */
      YEAR_JP,
      /** 年月: yyyy/MM */
      YEAR_MONTH,
      /** 年月(日本語表記): yyyy/MM */
      YEAR_MONTH_JP,
      /** 日時: yyyy/MM/dd hh:mm:ss */
      DATE_TIME,
      /** 日時(日本語表記): yyyy年MM月dd日 hh時mm分ss秒 */
      DATE_TIME_JP,
      /** 投稿日時: yyyy-MM-dd hh:mm:ss */
      POSTED_DATETIME,
      /** タイムスタンプ: yyyyMMddhhmmssfff */
      TIMESTAMP,
      /** タイムスタンプ(年月日): yyyyMMdd */
      TIMESTAMP_DATE,
      /** タイムスタンプ(年月日時分秒): yyyyMMddhhmmss */
      TIMESTAMP_DATE_TIME,
      /** タイムスタンプ(時分秒): hhmmss */
      TIMESTAMP_TIME,
      /** 日本標準時: yyyy-MM-ddThh:mm:ss+0900 */
      JST,
      /** 作業予定表形式: M月d日（W） */
      WORK_LIST,
      /** 月日: M月d日 */
      MONTH_DAY,
      /** 月日: M/d */
      MONTH_DAY_SHORT
    }
  }

  /**
   * ログレベル
   */
  export enum LogLevel {
    /** 正常 */
    OK = 'OK',
    /** 情報 */
    INFO = 'INFO',
    /** 警告 */
    WARN = 'WARN',
    /** エラー */
    ERROR = 'ERROR',
    /** 例外 */
    EXCEPTION = 'EXCEPTION'
  }
}
