<template>
  <default-layout>
    <form v-if="!showPreview" role="form" class="form-horizontal" @submit.prevent>
      <div class="row ctm_margin-b-no ctm_margin-t-10">
        <div class="col-md-12">
          <ol class="breadcrumb ctm_margin-b-no">
            <li><router-link :to="{ name: 'top' }">ホーム</router-link></li>
            <li><router-link :to="{ name: 'request-list' }">依頼一覧</router-link></li>
            <li class="active">依頼｜{{ mode == 'edit' ? '編集' : '新規登録' }}</li>
          </ol>
        </div>
      </div>
      <div class="page-header">
        <h1 class="h3">
          <i class="glyphicon glyphicon-list-alt"></i> 依頼｜{{ mode == 'edit' ? '編集' : '新規登録' }}
        </h1>
      </div>
      <div class="">
        <div class="form-group">
          <div class="">
            <label for="shopName" class="control-label col-md-3">
              <strong class="h6">受注番号</strong> <span class="label label-danger">必須</span>
            </label>
            <div class="col-md-3">
              <input type="text" class="form-control" id="orderNo" v-model="parameter.orderNo" />
              <p v-if="errors.orderNo" class="caution" v-html="errors.orderNo" />
            </div>
          </div>
        </div>
        <!-- form-group -->
        <div class="form-group">
          <div class="">
            <label for="searchKana" class="control-label col-md-3">
              <strong class="h6">タイトル（誌名）</strong> <span class="label label-danger">必須</span>
            </label>
            <div class="col-md-9">
              <input type="text" class="form-control" id="magazineName" v-model="parameter.magazineName" />
              <p v-if="errors.magazineName" class="caution" v-html="errors.magazineName" />
            </div>
          </div>
        </div>
        <!-- form-group -->
        <div class="form-group">
          <div class="">
            <label for="url1" class="control-label col-md-3">
              <strong class="h6">納品日</strong> <span class="label label-danger">必須</span>
            </label>
            <div class="col-md-5">
              <div class="ctm_block clearfix">
                <div class="ctm_width-200 ctm_float-left">
                  <div class="input-group">
                    <date-control id="deliverDate" v-model="parameter.deliverDate" />
                  </div>
                  <p v-if="errors.deliverDate" class="caution" v-html="errors.deliverDate" />
                </div>
              </div>
              <!-- ctm_block -->
            </div>
          </div>
        </div>
        <!-- form-group -->
        <div class="form-group">
          <div class="">
            <label for="url1" class="control-label col-md-3">
              <strong class="h6">作業予定日</strong> <span class="label label-danger">必須</span>
            </label>
            <div class="col-md-5">
              <div class="ctm_block clearfix">
                <div class="ctm_width-200 ctm_float-left">
                  <div class="input-group">
                    <date-control id="workDate" v-model="parameter.workDate" />
                  </div>
                  <p v-if="errors.workDate" class="caution" v-html="errors.workDate" />
                </div>
              </div>
              <!-- ctm_block -->
            </div>
          </div>
        </div>
        <!-- form-group -->
        <div class="form-group">
          <div class="">
            <label for="searchKana" class="control-label col-md-3">
              <strong class="h6">数量</strong> <span class="label label-danger">必須</span>
            </label>
            <div class="col-md-3">
              <input type="text" class="form-control" id="amount" v-model="parameter.amount" />
              <p v-if="errors.amount" class="caution" v-html="errors.amount" />
            </div>
          </div>
        </div>
        <!-- form-group -->
        <div class="form-group">
          <div class="">
            <label for="searchKana" class="control-label col-md-3">
              <strong class="h6">総ページ数</strong> <span class="label label-danger">必須</span>
            </label>
            <div class="col-md-3">
              <input type="text" class="form-control" id="totalPageCount" v-model="parameter.totalPageCount" />
              <p v-if="errors.totalPageCount" class="caution" v-html="errors.totalPageCount" />
            </div>
          </div>
        </div>
        <!-- form-group -->

        <div class="form-group">
          <div class="">
            <label for="floor" class="control-label col-md-3"><strong class="h6">表1</strong></label>
          </div>
          <div class="col-md-9">
            <div class="table-responsive">
              <table class="table table-bordered delivery_table">
                <thead>
                  <tr>
                    <th class="col-xs-3 text-center">印刷項目</th>
                    <th class="col-xs-3 text-center">通数</th>
                    <th class="col-xs-3 text-center">追加済み</th>
                    <th class="col-xs-3 text-center">今回の追加数</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(data, index) in parameter.printItems" :key="index">
                    <td class="no-padding text-center">
                      <input type="text" class="form-control" id="" v-model="data.printItemName" />
                    </td>
                    <td class="text-center">{{ $filters.toNumberFormat(data.serialCount) }}</td>
                    <td class="text-center">{{ $filters.toNumberFormat(data.addedCount) }}</td>
                    <td class="number text-center">
                      <input type="text" class="form-control" id="" v-model="data.addCount" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- form-group -->
        <div class="form-group">
          <div class="">
            <label for="floor" class="control-label col-md-3"><strong class="h6">タスク作成</strong></label>
          </div>
          <div class="col-md-9">
            <div class="table-responsive">
              <table class="table table-bordered works_table">
                <thead>
                  <tr>
                    <th
                      v-for="(data, index) in parameter.taskItems"
                      :key="index"
                      v-html="data.taskItemName"
                      class="col-xs-1 text-center"
                    />
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td v-for="(data, index) in parameter.taskItems" :key="index" class="text-center">
                      <div class="clearfix">
                        <div class="d-inline-block">
                          <checkbox-control
                            :parameter="data"
                            id="isActive"
                            :name="'defaultCheck' + index"
                            :disabled="mode == 'edit'"
                          />
                        </div>
                      </div>
                      <!-- checkbox -->
                    </td>
                  </tr>
                  <tr>
                    <td
                      v-for="(data, index) in parameter.taskItems"
                      :key="index"
                      :class="{ 'number text-center': true, blank_cell: !data.isActive }"
                    >
                      <input
                        v-if="data.isActive"
                        type="text"
                        :class="{ 'form-control': true, 'text-center': mode == 'edit' }"
                        id=""
                        v-model="data.taskCount"
                        :readonly="mode == 'edit'"
                      />
                      <input v-else type="text" class="form-control" id="" disabled />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- form-group -->
        <div class="form-group" v-if="mode == 'edit'">
          <div class="">
            <label for="floor" class="control-label col-md-3"><strong class="h6">タスク追加用</strong></label>
          </div>
          <div class="col-md-9">
            <div class="table-responsive">
              <table class="table table-bordered works_table">
                <thead>
                  <tr>
                    <th
                      v-for="(data, index) in parameter.taskItems"
                      :key="index"
                      v-html="data.taskItemName"
                      class="col-xs-1 text-center"
                    />
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td v-for="(data, index) in parameter.taskItems" :key="index" class="text-center">
                      <div class="clearfix">
                        <div class="d-inline-block">
                          <checkbox-control :parameter="data" id="isActiveForEdit" :name="'editCheck' + index" />
                        </div>
                      </div>
                      <!-- checkbox -->
                    </td>
                  </tr>
                  <tr>
                    <td
                      v-for="(data, index) in parameter.taskItems"
                      :key="index"
                      :class="{ 'number text-center': true, blank_cell: !data.isActiveForEdit }"
                    >
                      <input
                        v-if="data.isActiveForEdit"
                        type="text"
                        class="form-control"
                        id=""
                        v-model="data.taskCountForEdit"
                      />
                      <input v-else type="text" class="form-control" id="" disabled />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- form-group -->
        <div class="form-group">
          <div>
            <label for="floor" class="control-label col-md-3"><strong class="h6">表3</strong></label>
          </div>
          <div class="col-md-9 d-flex">
            <div class="table-responsive tb1">
              <table class="table table-bordered calc_table">
                <tbody>
                  <tr>
                    <td class="text-center bg_cl_1" rowspan="2">
                      <p>P</p>
                      <div class="input-group ol-md-2 d-block">
                        <input type="text" class="form-control" id="pValue" v-model="parameter.pValue" />
                      </div>
                    </td>
                    <td class="text-center bg_cl_2">
                      <p>V</p>
                      <div class="input-group ol-md-2 d-block">
                        <input type="text" class="form-control" id="vValue" v-model="parameter.vValue" />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-center">
                      <p>M</p>
                      <div class="input-group ol-md-2 d-block">
                        <input type="text" class="form-control" id="mValue" v-model="parameter.mValue" />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="d-flex tb2 batu">
              <span></span>
            </div>
            <div class="table-responsive tb3">
              <table class="table table-bordered calc_table">
                <tbody>
                  <tr>
                    <td class="text-center bg_cl_3">
                      <p>Q</p>
                      <div class="input-group ol-md-2 d-block">
                        <input type="text" class="form-control" id="qValue" v-model="parameter.qValue" />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="d-flex tb4 batu">
              <span></span>
            </div>
            <div class="table-responsive tb5">
              <table class="table table-bordered calc_table">
                <tbody>
                  <tr>
                    <td class="text-center bg_cl_1" rowspan="3">
                      <span>売上高<br />PQ</span>
                    </td>
                    <td class="text-center bg_cl_2" colspan="2">
                      <span>変動費 VQ</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-center" rowspan="2">
                      <span>粗利益<br />MQ</span>
                    </td>
                    <td class="text-center">
                      <span>固定費 F</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-center bg_cl_4">
                      <span>経常利 G</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- form-group -->
        <div class="form-group">
          <div class="">
            <label for="searchKana" class="control-label col-md-3"><strong class="h6">備考</strong></label>
            <div class="form-floating col-md-9">
              <textarea class="form-control" id="floatingTextarea" v-model="parameter.remarks" />
            </div>
          </div>
        </div>
        <!-- form-group -->
      </div>
      <div class="">
        <div class="">
          <label for="floor" class="control-label col-md-3"><strong class="h6"></strong></label>
        </div>
        <div class="form-group">
          <button v-if="mode == 'add'" type="submit" class="btn btn-success" @click="onPreview">
            <i class="glyphicon glyphicon-pencil"></i>タスクプレビュー
          </button>
          <button v-if="mode == 'edit'" type="submit" class="btn btn-success" @click="onUpdate">
            <i class="glyphicon glyphicon-edit"></i> 更新する
          </button>
          <button type="submit" class="btn" @click="onCancel">
            <i class="glyphicon glyphicon-remove"></i> キャンセル
          </button>
        </div>
        <!-- form-group -->
      </div>
    </form>
    <template v-else>
      <div class="row ctm_margin-b-no ctm_margin-t-10">
        <div class="col-md-12">
          <ol class="breadcrumb ctm_margin-b-no">
            <li><router-link :to="{ name: 'top' }">ホーム</router-link></li>
            <li class="active">タスクプレビュー</li>
          </ol>
        </div>
      </div>
      <h1 class="h3"><i class="glyphicon glyphicon-list-alt"></i> タスクプレビュー</h1>
      <div class="page-header">
        <div class="row">
          <div class="col-md-12">
            <h2 class="h4"><span class="heading-note">作業予定表</span></h2>
          </div>

          <div class="col-md-12">
            <work-table
              showTodayTitle
              :workDate="parameter.workDate"
              :timeAreas="timeAreas"
              :workItems="workItems"
              :works="works"
              @move="onMoveTask"
              @showDetail="onShowDetail"
            />
          </div>

          <div class="">
            <div class="form-group">
              <button v-if="mode == 'add'" type="submit" class="btn btn-success" @click="onSave">
                <i class="glyphicon glyphicon-pencil"></i>タスク登録
              </button>
              <button v-if="mode == 'edit'" type="submit" class="btn btn-success" @click="onUpdateExecute">
                <i class="glyphicon glyphicon-edit"></i> タスク更新
              </button>
              <button type="submit" class="btn" @click="onReturn">
                <i class="glyphicon glyphicon-remove"></i> キャンセル
              </button>
            </div>
            <!-- form-group -->
          </div>
        </div>
      </div>
    </template>
  </default-layout>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue'
import { BaseView } from '@/components/view'
import { DefaultLayout } from '../../components/layout'
import { pageInfo } from '@/store/PageInfo'
import { ApiService } from '@/modules/services/ApiService'
import { BaseApiRequest, WorkItem } from '@/modules/types'
import { RegexpService } from '@/modules/services'
import { Constants } from '@/modules/constants'
import { CheckboxControl, DateControl } from '@/components/controls'
import { WorkTable } from '../common/parts'

/** 依頼登録画面 */
export default defineComponent({
  /** 名称 */
  name: 'request-regist',
  /** 継承元指定 */
  extends: BaseView,
  /** コンポーネント */
  components: {
    DefaultLayout,
    CheckboxControl,
    DateControl,
    WorkTable
  },
  /** データ定義 */
  data: () => ({
    /** モード */
    mode: 'add',
    /** 依頼情報ID */
    requestId: '',
    /** プレビュー表示 */
    showPreview: false,
    /** 時間帯 */
    timeAreas: Array<any>(),
    /** 作業項目 */
    workItems: Array<any>(),
    /** パラメータ */
    parameter: {
      /** ID */
      id: '',
      /** 受注番号 */
      orderNo: '',
      /** タイトル */
      magazineName: '',
      /** 納品日 */
      deliverDate: '',
      /** 作業予定日 */
      workDate: '',
      /** 数量 */
      amount: '',
      /** 総ページ数 */
      totalPageCount: '',
      /** 印刷項目リスト */
      printItems: Array<{
        /** 印刷項目コード */
        printItemCode?: string
        /** 印刷項目名 */
        printItemName: string
        /** 通数 */
        serialCount?: string
        /** 追加済み */
        addedCount?: string
        /** 追加数 */
        addCount?: string
        /** 並び順 */
        sortNo?: number
      }>(),
      /** 作業タスク */
      taskItems: Array<{
        /** 作業タスクコード */
        taskItemCode: string
        /** 作業タスク名 */
        taskItemName: string
        /** 有効フラグ */
        isActive: boolean
        /** タスク数 */
        taskCount?: number
        /** 有効フラグ(編集用) */
        isActiveForEdit: boolean
        /** タスク数(編集用) */
        taskCountForEdit?: number
        /** 並び順 */
        sortNo?: number
      }>(),
      /** P値 */
      pValue: 0,
      /** V値 */
      vValue: 0,
      /** M値 */
      mValue: 0,
      /** Q値 */
      qValue: 0,
      /** 備考 */
      remarks: ''
    },
    errors: {
      /** 受注番号 */
      orderNo: '',
      /** タイトル */
      magazineName: '',
      /** 納品日 */
      deliverDate: '',
      /** 作業予定日 */
      workDate: '',
      /** 数量 */
      amount: '',
      /** 総ページ数 */
      totalPageCount: ''
    }
  }),
  /** セットアップスクリプト */
  setup(props, context) {
    const works: any = ref({})
    return { works }
  },
  /** 関数定義 */
  methods: {
    /** 初期化処理 */
    async initialize() {
      /** モード取得 */
      this.mode = this.$route.meta.mode ? String(this.$route.meta.mode) : 'add'
      // 依頼情報ID取得
      this.requestId = this.mode == 'edit' && pageInfo.parameter ? pageInfo.parameter.requestId : '0'

      // View定義
      if (this.mode == 'edit') {
        this.setView('依頼｜編集', 'request-regist')
      } else {
        this.setView('依頼｜新規登録', 'request-regist')
      }
      // データ読み込み
      await this.loadData()
    },
    /** データ読み込み */
    async loadData() {
      try {
        // ローディング開始
        this.showLoading()

        // パラメータ初期化
        this.parameter = {
          /** ID */
          id: this.requestId,
          /** 受注番号 */
          orderNo: '',
          /** タイトル */
          magazineName: '',
          /** 納品日 */
          deliverDate: '',
          /** 作業予定日 */
          workDate: '',
          /** 数量 */
          amount: '',
          /** 総ページ数 */
          totalPageCount: '',
          /** 印刷項目リスト */
          printItems: [],
          /** 作業タスク */
          taskItems: [],
          /** P値 */
          pValue: 0,
          /** V値 */
          vValue: 0,
          /** M値 */
          mValue: 0,
          /** Q値 */
          qValue: 0,
          /** 備考 */
          remarks: ''
        }

        this.timeAreas = []
        this.workItems = []

        // API定義
        const api: ApiService = new ApiService('/deliver/view')
        // POSTデータ定義
        const postData: BaseApiRequest = new BaseApiRequest({ deliverId: this.requestId })
        // データ取得処理
        const response = await api.post(postData)

        // 処理結果判定
        if (response.statusCode == Constants.Api.ResponseCode.OK) {
          // 処理成功
          // 依頼情報取得
          const deliverInfo: any = response.responseData.deliverInfos ? response.responseData.deliverInfos : {}

          // パラメータに設定
          this.parameter.orderNo = deliverInfo.order_no ? deliverInfo.order_no : ''
          this.parameter.magazineName = deliverInfo.magazine_name ? deliverInfo.magazine_name : ''
          this.parameter.deliverDate = deliverInfo.deliver_date ? deliverInfo.deliver_date : ''
          this.parameter.workDate = deliverInfo.work_date ? deliverInfo.work_date : ''
          this.parameter.amount = deliverInfo.amount ? deliverInfo.amount : ''
          this.parameter.totalPageCount = deliverInfo.total_page_count ? deliverInfo.total_page_count : ''
          this.parameter.pValue = deliverInfo.p_value ? deliverInfo.p_value : 0
          this.parameter.vValue = deliverInfo.v_value ? deliverInfo.v_value : 0
          this.parameter.mValue = deliverInfo.m_value ? deliverInfo.m_value : 0
          this.parameter.qValue = deliverInfo.q_value ? deliverInfo.q_value : 0
          this.parameter.remarks = deliverInfo.remarks ? deliverInfo.remarks : ''

          // タスク情報取得
          const taskInfos: any[] = deliverInfo && deliverInfo.deliver_task_infos ? deliverInfo.deliver_task_infos : []
          // 印刷項目取得
          const printItems: any[] =
            deliverInfo && deliverInfo.deliver_print_infos ? deliverInfo.deliver_print_infos : []

          // 印刷項目設定
          for (let i = 0; i < 5; i++) {
            // 範囲内チェック
            if (i < printItems.length) {
              // 印刷項目追加
              this.parameter.printItems.push({
                /** 印刷項目コード */
                printItemCode: '',
                /** 印刷項目名 */
                printItemName: printItems[i].print_item_name,
                /** 通数 */
                serialCount: printItems[i].serial_count,
                /** 追加済み */
                addedCount: printItems[i].added_count,
                /** 追加数 */
                addCount: printItems[i].add_count,
                /** 並び順 */
                sortNo: i + 1
              })
            } else {
              // 印刷項目追加
              this.parameter.printItems.push({
                /** 印刷項目コード */
                printItemCode: '',
                /** 印刷項目名 */
                printItemName: '',
                /** 通数 */
                serialCount: '',
                /** 追加済み */
                addedCount: '',
                /** 追加数 */
                addCount: '',
                /** 並び順 */
                sortNo: i + 1
              })
            }
          }
          // 時間帯取得
          const timeAreas: any[] = response.responseData.timeAreas
          // 時間帯を設定
          for (let i = 0; i < timeAreas.length; i++) {
            // 時間帯追加
            this.timeAreas.push(timeAreas[i])
          }
          // タスク項目取得
          const taskItems: any[] = response.responseData.taskItems
          // タスク情報を設定
          for (let i = 0; i < taskItems.length; i++) {
            const task = taskInfos.find((x) => x.task_item_code == taskItems[i].code)
            // タスク情報有無
            if (task) {
              // タスクあり
              this.parameter.taskItems.push({
                taskItemCode: taskItems[i].code,
                taskItemName: taskItems[i].attr_01,
                taskCount: task.task_count,
                isActive: true,
                isActiveForEdit: false,
                taskCountForEdit: 1,
                sortNo: i + 1
              })
            } else {
              // タスクなし
              this.parameter.taskItems.push({
                taskItemCode: taskItems[i].code,
                taskItemName: taskItems[i].attr_01,
                taskCount: 1,
                isActive: false,
                isActiveForEdit: false,
                taskCountForEdit: 1,
                sortNo: i + 1
              })
            }
            // 作業項目追加
            this.workItems.push(taskItems[i])
          }
        } else {
          // 取得失敗
          this.showWarning(response.statusMessage, '初期化失敗')
        }
      } finally {
        // ローディング解除
        this.hideLoading()
      }
    },
    /** 登録処理 */
    async onSave() {
      // 登録確認
      this.showConfirm(
        '登録します<br />よろしいですか？',
        '登録確認',
        async (response: Constants.Message.ResponseCode) => {
          // 応答判定
          if (response == Constants.Message.ResponseCode.YES) {
            // 登録処理
            try {
              // ローディング開始
              this.showLoading()
              // API定義
              const api: ApiService = new ApiService('/deliver/regist')
              // POSTデータ定義
              const postData: BaseApiRequest = new BaseApiRequest({
                parameter: JSON.stringify(this.parameter)
              })
              // 登録処理
              const response = await api.post(postData)
              // 結果判定
              if (response.statusCode == Constants.Api.ResponseCode.OK) {
                // 正常終了
                this.showInformation('登録しました', '登録完了', async () => {
                  // 一覧へ遷移
                  this.goto('request-list')
                })
              } else {
                // エラーメッセージ表示
                this.showWarning(response.statusMessage, '登録失敗')
              }
            } finally {
              // ローディング解除
              this.hideLoading()
            }
          }
        }
      )
    },
    /** 更新処理 */
    async onUpdateExecute() {
      // 登録確認
      this.showConfirm(
        '更新します<br />よろしいですか？',
        '更新確認',
        async (response: Constants.Message.ResponseCode) => {
          // 応答判定
          if (response == Constants.Message.ResponseCode.YES) {
            // 登録処理
            try {
              // ローディング開始
              this.showLoading()
              // API定義
              const api: ApiService = new ApiService('/deliver/regist')
              // POSTデータ定義
              const postData: BaseApiRequest = new BaseApiRequest({
                parameter: JSON.stringify(this.parameter)
              })
              // 登録処理
              const response = await api.post(postData)
              // 結果判定
              if (response.statusCode == Constants.Api.ResponseCode.OK) {
                this.goto('request-list')
                // // 正常終了
                // this.showInformation('更新しました', '更新完了', async () => {
                //   // 一覧へ遷移
                //   this.goto('request-list')
                // })
              } else {
                // エラーメッセージ表示
                this.showWarning(response.statusMessage, '更新失敗')
              }
            } finally {
              // ローディング解除
              this.hideLoading()
            }
          }
        }
      )
    },
    /** 入力チェック */
    async inputCheck() {
      let check = true
      const orderNoErrors: string[] = []
      const magazineNameErrors: string[] = []
      const deliverDateErrors: string[] = []
      const workDateErrors: string[] = []
      const amountErrors: string[] = []
      const totalPageCountErrors: string[] = []
      // 受注番号チェック
      if (!this.parameter.orderNo) {
        orderNoErrors.push('受注番号が入力されていません')
        check = false
      }
      // タイトルチェック
      if (!this.parameter.magazineName) {
        magazineNameErrors.push('タイトル（誌名）が入力されていません')
        check = false
      }
      // 納品日チェック
      if (!this.parameter.deliverDate) {
        deliverDateErrors.push('納品日が入力されていません')
        check = false
      }
      // 作業予定日チェック
      if (!this.parameter.workDate) {
        workDateErrors.push('作業予定日が入力されていません')
        check = false
      }
      // 数量チェック
      if (!this.parameter.amount) {
        amountErrors.push('数量が入力されていません')
        check = false
      } else if (!RegexpService.isNumber(this.parameter.amount)) {
        amountErrors.push('数量は数字で入力してください')
        check = false
      }
      // 総ページ数チェック
      if (!this.parameter.totalPageCount) {
        totalPageCountErrors.push('総ページ数が入力されていません')
        check = false
      } else if (!RegexpService.isNumber(this.parameter.totalPageCount)) {
        totalPageCountErrors.push('総ページ数は数字で入力してください')
        check = false
      }
      // エラー設定
      this.errors.orderNo = orderNoErrors.length > 0 ? orderNoErrors.join('<br />') : ''
      this.errors.magazineName = magazineNameErrors.length > 0 ? magazineNameErrors.join('<br />') : ''
      this.errors.deliverDate = deliverDateErrors.length > 0 ? deliverDateErrors.join('<br />') : ''
      this.errors.workDate = workDateErrors.length > 0 ? workDateErrors.join('<br />') : ''
      this.errors.amount = amountErrors.length > 0 ? amountErrors.join('<br />') : ''
      this.errors.totalPageCount = totalPageCountErrors.length > 0 ? totalPageCountErrors.join('<br />') : ''

      // チェック結果を返す
      return check
    },
    /** キャンセル処理 */
    async onCancel() {
      // 一覧へ遷移
      this.goto('request-list')
    },
    /** プレビュー表示 */
    async onPreview() {
      // 入力チェック
      const checked = await this.inputCheck()
      // チェック結果判定
      if (checked) {
        try {
          // ローディング開始
          this.showLoading()
          // 作業予定初期化
          this.works = {}
          // 作業予定作成
          for (let i = 0; i < this.parameter.taskItems.length; i++) {
            // 作業項目取得
            const task = this.parameter.taskItems[i]
            this.works[task.taskItemCode] = {}
            this.works[task.taskItemCode]['01'] = []
            this.works[task.taskItemCode]['02'] = []
            this.works[task.taskItemCode]['03'] = []
            const workItems: Array<WorkItem> = []
            // 作業チェック済み判定
            if (task.isActive) {
              for (let j = 0; j < Number(task.taskCount); j++) {
                // 作業予定追加
                workItems.push({
                  id: i.padStart(3) + j.padStart(3),
                  magazineTitle: this.parameter.magazineName,
                  workDate: this.parameter.workDate,
                  timeAreaCode: '01',
                  workCode: task.taskItemCode,
                  workName: task.taskItemName,
                  userId: '',
                  userName: '',
                  remarks: this.parameter.remarks,
                  backgroundColor: '',
                  isMissLoss: false,
                  isDelete: false
                })
              }
            }
            this.works[task.taskItemCode]['01'] = workItems
          }
          // プレビュー表示
          this.showPreview = true
        } finally {
          // ローディング非表示
          this.hideLoading()
        }
      }
      // 先頭へ移動
      this.scrollTop()
    },
    /** 更新処理 */
    async onUpdate() {
      // 入力チェック
      const checked = await this.inputCheck()
      // チェック結果判定
      if (checked) {
        try {
          // ローディング開始
          this.showLoading()
          // 作業予定初期化
          this.works = {}
          let showPreview = false
          // 作業予定作成
          for (let i = 0; i < this.parameter.taskItems.length; i++) {
            // 作業項目取得
            const task = this.parameter.taskItems[i]
            this.works[task.taskItemCode] = {}
            this.works[task.taskItemCode]['01'] = []
            this.works[task.taskItemCode]['02'] = []
            this.works[task.taskItemCode]['03'] = []
            const workItems: Array<WorkItem> = []
            // 追加有無
            if (task.isActiveForEdit && Number(task.taskCountForEdit) > 0) {
              showPreview = true
            }
            // 作業チェック済み判定
            if (task.isActive || task.isActiveForEdit) {
              let taskCount = task.isActive ? Number(task.taskCount) : 0
              taskCount += task.isActiveForEdit ? Number(task.taskCountForEdit) : 0
              for (let j = 0; j < taskCount; j++) {
                // 作業予定追加
                workItems.push({
                  id: i.padStart(3) + j.padStart(3),
                  magazineTitle: this.parameter.magazineName,
                  workDate: this.parameter.workDate,
                  timeAreaCode: '01',
                  workCode: task.taskItemCode,
                  workName: task.taskItemName,
                  userId: '',
                  userName: '',
                  remarks: this.parameter.remarks,
                  backgroundColor: '',
                  isMissLoss: false,
                  isDelete: false
                })
              }
            }
            this.works[task.taskItemCode]['01'] = workItems
          }
          // プレビュー表示有無
          if (showPreview) {
            // プレビュー表示
            this.showPreview = true
            // 先頭へ移動
            this.scrollTop()
          } else {
            // 更新処理
            await this.onUpdateExecute()
          }
        } finally {
          // ローディング非表示
          this.hideLoading()
        }
      }
    },
    /** プレビューから戻る */
    async onReturn() {
      // プレビュー解除
      this.showPreview = false
      // 先頭へ移動
      this.scrollTop()
    }
  }
})
</script>
<style lang="stylus" scoped>
td.number, td.no-padding
  padding 0px
  input
    border none
</style>
