<template>
  <div id="overlay">
    <div class="cv-spinner">
      <span class="spinner"></span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

/**
 * ローディング
 */
export default defineComponent({
  /** 名称 */
  name: 'loading',
  /** セットアップスクリプト */
  setup(props, context) {}
})
</script>

<style>
#overlay {
  position: fixed;
  top: 0;
  z-index: 999999;
  width: 100%;
  height: 100%;
  display: none;
  background: rgba(33, 33, 33, 0.8);
}
.cv-spinner {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.spinner {
  width: 40px;
  height: 40px;
  border: 4px #ddd solid;
  border-top: 4px #2e93e6 solid;
  border-radius: 50%;
  animation: sp-anime 0.8s infinite linear;
}
@keyframes sp-anime {
  100% {
    transform: rotate(360deg);
  }
}
</style>
