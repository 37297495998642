import { Mutation, Action, VuexModule, getModule, Module } from 'vuex-module-decorators'
import store from '@/store'
import { EnvironmentService } from '@/modules/services'

/** ページ情報インターフェース */
export interface PageState {
  /** ローディング */
  loading: boolean
  /** メニュー表示 */
  showMenu: boolean
  /** カレントページ */
  currentRoute?: any
  /** パラメータ */
  parameter?: any
  /** 顧客コード */
  customerCode?: string
}

/** ページ情報 */
@Module({ dynamic: true, store, name: 'page', namespaced: true, preserveState: true })
class PageInfo extends VuexModule implements PageState {
  // デフォルト定義
  private readonly defaultLoading: boolean = false
  private readonly defaultShowMenu: boolean = false
  private readonly defaultCurrentRoute: any = {}
  private readonly defaultParameter: any = {}
  private readonly defaultCustomerCode: string = EnvironmentService.get('VUE_APP_DEFAULT_CUSTOMER_CODE')

  /** ローディングフラグ */
  public loading: boolean = this.defaultLoading
  /** メニュー表示フラグ */
  public showMenu: boolean = this.defaultShowMenu
  /** カレントページ情報 */
  public currentRoute?: any = this.defaultCurrentRoute
  /** パラメータ */
  public parameter?: any = this.defaultParameter
  /** 顧客コード */
  public customerCode?: string = this.defaultCustomerCode

  /** 値設定 */
  @Mutation
  public set(param: any): void {
    this.loading = param.loading
    this.showMenu = param.showMenu
    this.currentRoute = param.currentRoute
    this.parameter = param.parameter
    this.customerCode = param.customerCode
  }

  /** ローディング設定 */
  @Mutation
  public setLoading(value: boolean): void {
    this.loading = value
  }

  /** メニュー表示設定 */
  @Mutation
  public setShowMenu(value: boolean): void {
    this.showMenu = value
  }

  /** カレントページ情報設定 */
  @Mutation
  public setCurrentRoute(value?: any): void {
    this.currentRoute = value
  }

  /** パラメータ設定 */
  @Mutation
  public setPageParameter(value?: any): void {
    this.parameter = value
  }

  /** 顧客コード設定 */
  @Mutation
  public setCustomerCodeMutation(value?: string): void {
    this.customerCode = value
  }

  /** クリア */
  @Mutation
  public clear(): void {
    this.loading = this.defaultLoading
    this.showMenu = this.defaultShowMenu
    this.currentRoute = this.defaultCurrentRoute
    this.parameter = this.defaultParameter
  }

  /** ローディング表示 */
  @Action({})
  public showLoading(): void {
    this.setLoading(true)
  }

  /** ローディング非表示 */
  @Action({})
  public hideLoading(): void {
    this.setLoading(false)
  }

  /**
   * メニュー切り替え
   * @param show 表示フラグ
   */
  @Action({})
  public toggleMenu(show?: boolean): void {
    this.setShowMenu(show !== undefined ? show : !this.showMenu)
  }

  /**
   * カレントページ情報設定
   * @param route ページ情報
   */
  @Action({})
  public setRoute(route?: any): void {
    this.setCurrentRoute(route)
  }

  /**
   * パラメータ設定
   * @param parameter パラメータ
   */
  @Action({})
  public setParameter(parameter?: any): void {
    this.setPageParameter(parameter)
  }

  /**
   * パラメータクリア
   */
  @Action({})
  public clearParameter(): void {
    this.setPageParameter(this.defaultParameter)
  }
}

const pageInfoModule = getModule(PageInfo)

// ローカルストレージに情報がある場合読み込む
if (sessionStorage.getItem('app')) {
  const json: string = sessionStorage.getItem('app') as string
  const strageData = JSON.parse(json)
  if (strageData.page) {
    pageInfoModule.set(strageData.page)
  }
}

export const pageInfo = pageInfoModule
