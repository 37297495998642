<template>
  <default-layout>
    <h1 class="page-header h3"><i class="glyphicon glyphicon-home"></i> ホーム</h1>
    <div class="page-header">
      <h2 class="ctm_home-title ctm-dashboard-box text-primary">⽂唱堂 タスク管理システムへようこそ！</h2>
      <p class="ctm_home-text">「⽂唱堂 タスク管理システム」では、○○○を管理することができます。</p>
    </div>
    <!-- page-header -->
    <div class="page-header">
      <div class="row">
        <div class="col-sm-12 col-md-4">
          <div class="thumbnail">
            <img class="img-rounded" src="/images/no-image.jpg" />
            <div class="caption">
              <h3>納品予定表</h3>
              <p>納品予定表の説明文が入ります。</p>
              <p class="text-center">
                <a href="#" class="btn btn-primary" role="button"
                  ><i class="glyphicon glyphicon-share-alt"></i> マニュアルを見る</a
                >
              </p>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-4">
          <div class="thumbnail">
            <img class="img-rounded" src="/images/no-image.jpg" />
            <div class="caption">
              <h3>作業予定表</h3>
              <p>作業予定表の説明文が入ります。</p>
              <p class="text-center">
                <a href="#" class="btn btn-primary" role="button"
                  ><i class="glyphicon glyphicon-share-alt"></i> マニュアルを見る</a
                >
              </p>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-4">
          <div class="thumbnail">
            <img class="img-rounded" src="/images/no-image.jpg" />
            <div class="caption">
              <h3>○○○○○○○○○○</h3>
              <p>○○○○○○○○○○の説明文が入ります。</p>
              <p class="text-center">
                <a href="#" class="btn btn-primary" role="button"
                  ><i class="glyphicon glyphicon-share-alt"></i> マニュアルを見る</a
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- page-header -->
  </default-layout>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { BaseView } from '@/components/view'
import { DefaultLayout } from '../../components/layout'

/** トップページ画面 */
export default defineComponent({
  /** 名称 */
  name: 'top',
  /** 継承元指定 */
  extends: BaseView,
  /** コンポーネント */
  components: {
    DefaultLayout
  },
  /** セットアップスクリプト */
  setup(props, context) {},
  /** 関数定義 */
  methods: {
    /** 初期化処理 */
    async initialize() {
      // View定義
      this.setView('HOME', 'top-page')
    }
  }
})
</script>
