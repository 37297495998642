import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DatePicker = _resolveComponent("DatePicker")!

  return (_openBlock(), _createBlock(_component_DatePicker, {
    locale: "jp",
    "hide-input-icon": "",
    "auto-apply": "",
    "enable-time-picker": false,
    format: "yyyy年MM月dd日",
    "model-type": "yyyy-MM-dd",
    "text-input": _ctx.editable,
    "text-input-options": { enterSubmit: false, tabSubmit: false, openMenu: true, format: ['yyyyMMdd', 'yyyy-MM-dd'] },
    id: _ctx.id,
    name: _ctx.name,
    "week-start": 0,
    "min-date": _ctx.minDate,
    "max-date": _ctx.maxDate
  }, null, 8, ["text-input", "id", "name", "min-date", "max-date"]))
}