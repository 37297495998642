import { pageInfo } from '@/store/PageInfo'
/**
 * ルーティングサービス
 */
export class RoutingService {
  private _view: any
  constructor(vue: any) {
    this._view = vue
  }
  /**
   * ページ遷移
   * @param name 遷移先ページ名
   * @param params パラメータ
   */
  public goto(name: string, params?: { [key: string]: any }): void {
    // パラメータ有無
    if (params) {
      // パラメータ設定
      pageInfo.setParameter(params)
    } else {
      // パラメータクリア
      pageInfo.clearParameter()
    }
    // ページ遷移処理
    this._view.$router.push({ name: name })
  }
}
