<template>
  <default-layout>
    <div class="row ctm_margin-b-no ctm_margin-t-10">
      <div class="col-md-12">
        <ol class="breadcrumb ctm_margin-b-no">
          <li><router-link :to="{ name: 'top' }">ホーム</router-link></li>
          <li class="active">タスク管理</li>
        </ol>
      </div>
    </div>
    <h1 class="h3"><i class="glyphicon glyphicon-list-alt"></i> タスク管理</h1>
    <div class="page-header">
      <div class="row">
        <div class="col-md-4">
          <router-link :to="{ name: 'request-regist' }" class="btn btn-success btn-block" role="button">
            <i class="glyphicon glyphicon-pencil"></i> 新規作成 or インポート
          </router-link>
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="col-md-12">
        <div v-for="(item, index) in lists" :key="index" class="table-responsive">
          <table class="table table-bordered works_table">
            <thead>
              <tr>
                <th class="col-xs-1 text-left" :colspan="taskItems.length + 2">
                  <a
                    href=""
                    class="fs-4"
                    @click="
                      (e) => {
                        e.preventDefault()
                        onEdit(item.id)
                        return
                      }
                    "
                  >
                    {{ $filters.toMonthDateShort(item.deliver_date) }} {{ item.magazine_name }}
                    {{ $filters.toNumberFormat(item.amount) }}
                    <i class="glyphicon glyphicon-share"></i>
                  </a>
                </th>
              </tr>
              <tr>
                <th class="col-xs-1 text-center">担当者</th>
                <th class="col-xs-1 text-center">進捗</th>
                <th v-for="(task, index) in taskItems" :key="index" class="col-xs-1 text-center" v-html="task.value" />
              </tr>
            </thead>
            <tbody>
              <tr v-for="(user, userIndex) in item.userMasters" :key="userIndex">
                <td class="text-center">{{ user.user_name ? user.user_name : '担当者未定' }}</td>
                <td class="text-center">{{ getStatus(user.workInfos) }}</td>
                <td v-for="(task, index) in taskItems" :key="index" class="text-center">
                  <template v-if="String(task.code).substring(0, 2) == '03'">
                    {{ user.workInfos[task.code] ? $filters.toNumberFormat(user.workInfos[task.code].work_count) : '' }}
                  </template>
                  <template v-else>
                    {{
                      user.workInfos[task.code] ? $filters.toMonthDateShort(user.workInfos[task.code].work_date) : ''
                    }}
                  </template>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </default-layout>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { BaseView } from '@/components/view'
import { DefaultLayout } from '../../components/layout'
import { ApiService } from '@/modules/services/ApiService'
import { BaseApiRequest } from '@/modules/types'
import { Constants } from '@/modules/constants'

/** タスク一覧画面 */
export default defineComponent({
  /** 名称 */
  name: 'task-list',
  /** 継承元指定 */
  extends: BaseView,
  /** コンポーネント */
  components: {
    DefaultLayout
  },
  /** データ定義 */
  data: () => ({
    /** 一覧データ */
    lists: Array<any>(),
    /** タスク項目 */
    taskItems: Array<any>()
  }),
  /** セットアップスクリプト */
  setup(props, context) {},
  /** 関数定義 */
  methods: {
    /** 初期化処理 */
    async initialize() {
      // View定義
      this.setView('タスク管理', 'task-list')
      // 検索処理
      await this.loadData(false)
    },
    /** 検索処理 */
    async loadData(showAlert: boolean): Promise<void> {
      try {
        // ローディング開始
        this.showLoading()

        // リスト初期化
        this.lists = []
        this.taskItems = []

        // API定義
        const api: ApiService = new ApiService('/task/list')

        // POSTデータ定義
        const postData: BaseApiRequest = new BaseApiRequest({ deliverDateFrom: new Date().ToString() })

        // POST処理
        const response = await api.post(postData)

        // 処理結果判定
        if (response.statusCode == Constants.Api.ResponseCode.OK) {
          // 戻り値取得
          const deliverInfos: any[] = response.responseData.deliverInfos
          const taskItems: any[] = response.responseData.taskItems
          // タスク項目追加
          for (let i = 0; i < taskItems.length; i++) {
            this.taskItems.push({ code: taskItems[i].code, value: taskItems[i].attr_01 })
          }
          // リストに追加
          for (let i = 0; i < deliverInfos.length; i++) {
            this.lists.push(deliverInfos[i])
          }
        }

        // メッセージ表示有無
        if (showAlert && this.lists.length == 0) {
          // 対象データなし
          this.showInformation('対象データがありません', '検索結果')
        }
      } finally {
        // ローディング終了
        this.hideLoading()
      }
    },
    /** 編集処理 */
    onEdit(id: string) {
      // 編集ページへ遷移
      this.goto('request-edit', { requestId: id })
    },
    getStatus(works: any) {
      const work: any = works['000']
      const workCount = work.work_count
      const startedCount = work.started_count
      const completeCount = work.complete_count
      const missLossCount = work.miss_loss_count
      const cancelCount = work.cancel_count
      // 未実施判定
      if (startedCount == 0 && completeCount == 0 && missLossCount == 0) {
        return '未了'
      } else if (workCount == completeCount) {
        return '完了'
      } else {
        return '作業中'
      }
    }
  }
})
</script>
<style lang="stylus">
.task-list
  .works_table
    th
      width calc(100% / 13) !important
</style>
