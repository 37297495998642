<template>
  <public-layout>
    <form class="form-signin" ref="loginForm" @submit.prevent>
      <h3 class="form-signin-heading">⽂唱堂｜管理画面</h3>
      <div class="form-group">
        <div class="input-group">
          <div class="input-group-addon">
            <i class="glyphicon glyphicon-user"></i>
          </div>
          <input
            type="text"
            class="form-control"
            name=""
            id="username"
            placeholder="ユーザーID"
            autocomplete="off"
            v-model="parameter.loginId"
          />
        </div>
        <p v-if="errors.loginId" class="input-group valid-error">{{ errors.loginId }}</p>
      </div>
      <div class="form-group">
        <div class="input-group">
          <div class="input-group-addon">
            <i class="glyphicon glyphicon-lock"></i>
          </div>
          <input
            type="password"
            class="form-control"
            name=""
            id="password"
            placeholder="パスワード"
            v-model="parameter.password"
            autocomplete="off"
          />
        </div>
        <p v-if="errors.password" class="input-group valid-error">{{ errors.password }}</p>
      </div>

      <!-- <div class="form-group">
          <p class="caution">※パスワードが違います。</p>
        </div> -->

      <input class="checkbox-control" type="checkbox" id="remember" name="remember" />
      <label for="remember" class="checkbox-control-label"> ログイン状態を保存する </label>
      <button class="btn btn-lg btn-primary btn-block" @click="login">ログイン</button>
    </form>
  </public-layout>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { BaseView } from '@/components/view'
import { loginInfo, LoginState } from '@/store/LoginInfo'
import { PublicLayout } from '../../components/layout'
import { ApiService } from '@/modules/services/ApiService'
import { BaseApiRequest, BaseApiResponse } from '@/modules/types'
import { Constants } from '@/modules/constants'

/** ログイン画面 */
export default defineComponent({
  /** 名称 */
  name: 'login',
  /** 継承元指定 */
  extends: BaseView,
  /** コンポーネント */
  components: {
    PublicLayout
  },
  /** セットアップスクリプト */
  setup(props, context) {},
  /** データ定義 */
  data: () => ({
    /** パラメータ */
    parameter: {
      /** ログインID */
      loginId: '',
      /** パスワード */
      password: ''
    },
    /** エラー情報 */
    errors: {
      /** ログインID */
      loginId: '',
      /** パスワード */
      password: ''
    }
  }),
  methods: {
    /** 初期化処理 */
    async initialize() {
      /** View設定処理 */
      this.setView('ログイン画面', 'login')
      // Cookie有無
      if (this.$cookies.isKey('loginInfo')) {
        // Cookie取得
        const cookie = this.$cookies.get('loginInfo')
        // JSON形式に変換
        const loginJSON = JSON.parse(JSON.stringify(cookie))
        // パラメータに設定
        this.parameter.loginId = loginJSON.loginId
        this.parameter.password = loginJSON.password
        // チェックをつける
        $('#remember').prop('checked', true)
      }
    },
    /** ログイン処理 */
    async login() {
      // エラー情報初期化
      this.errors = { loginId: '', password: '' }
      // エラー有無
      let isError = false
      // 入力チェック
      if (!this.parameter.loginId) {
        this.errors.loginId = 'ログインIDを入力してください'
        isError = true
      }
      if (!this.parameter.password) {
        this.errors.password = 'パスワードを入力してください'
        isError = true
      }

      // チェック判定
      if (!isError) {
        // エラーなし
        // ログイン処理
        try {
          // ローディング表示
          this.showLoading()

          // APIサービス定義
          const api: ApiService = new ApiService('/auth')

          // パラメータ定義
          const request = new BaseApiRequest(this.parameter)

          // 認証処理
          const response: BaseApiResponse = await api.post(request)

          // 結果判定
          if (response.statusCode == Constants.Api.ResponseCode.OK) {
            // 認証OK
            // ログイン情報保存チェック
            const checkRemember = $('#remember').prop('checked')

            // ログイン情報保存判定
            if (checkRemember) {
              // Cookieに保存
              this.$cookies.set('loginInfo', this.parameter)
            } else {
              // Cookie削除
              this.$cookies.remove('loginInfo')
            }
            // ユーザーマスタ取得
            const userMaster = response.responseData.UserMaster

            // ログイン情報定義
            const loginState: LoginState = {
              userId: userMaster.id,
              loginId: userMaster.login_id,
              userName: userMaster.user_name,
              userType: userMaster.user_type,
              identityKey: userMaster.identify_key,
              expiredPassword: false,
              isEffective: userMaster.is_effective == 1,
              lastLoginAt: userMaster.last_login_at
            }
            // ログイン
            loginInfo.login(loginState)
            // トップページへ遷移
            this.$router.push('/home')
          } else {
            // 認証エラー
            this.showWarning(response.statusMessage, '認証エラー')
          }
        } finally {
          // ローディング非表示
          this.hideLoading()
        }
      }
    }
  }
})
</script>
<style lang="stylus">
body.login
  padding-top 40px
  padding-bottom 40px
  background-color #303641
  color #C1C3C6
</style>
