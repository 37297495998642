<template>
  <div class="table-responsive">
    <table class="table table-bordered task_table">
      <thead>
        <tr v-if="showTodayTitle">
          <td class="col-xs-12 text-left" colspan="4">本日の予定</td>
        </tr>
        <tr>
          <th class="col-xs-3 text-center">{{ workDateDisp }}</th>
          <th v-for="(time, index) in timeAreas" :key="index" class="col-xs-3 text-center">
            {{ time.value }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(work, workIndex) in workItems" :key="workIndex">
          <td class="text-left">{{ work.value }}</td>
          <template v-for="(time, timeIndex) in timeAreas" :key="timeIndex">
            <draggable
              v-if="isDraggable"
              v-model="works[work.code][time.code]"
              class="text-center"
              tag="td"
              group="workItems"
              itemKey="id"
              :component-data="{ workDate: workDate, timeAreaCode: time.code, workItemCode: work.code }"
              :move="onMoveStart"
              @end="onMoveItem"
            >
              <template v-slot:item="{ element }">
                <div
                  v-if="element.isDelete == false"
                  :class="['task_card', 'blk', String(work.attr_02)].join(' ')"
                  :style="getStyle(element)"
                  @dblclick="
                    onShowDetail({
                      workDate: workDate,
                      timeAreaCode: time.code,
                      workItemCode: work.code,
                      item: element
                    })
                  "
                  :elementKey="element.id"
                >
                  <h5>{{ element.magazineTitle }}</h5>
                  <p>{{ $filters.omit(element.remarks, 5) }}</p>
                </div>
              </template>
            </draggable>

            <td v-else class="text-center">
              <template v-for="(item, itemIndex) in works[work.code][time.code]" :key="itemIndex">
                <div
                  v-if="item.isDelete == false"
                  :class="['task_card', 'blk', String(work.attr_02)].join(' ')"
                  :style="getStyle(item)"
                >
                  <h5>{{ item.magazineTitle }}</h5>
                  <p>{{ $filters.omit(item.remarks, 5) }}</p>
                </div>
              </template>
            </td>
          </template>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { Constants } from '@/modules/constants'
import { WorkItem } from '@/modules/types'
import draggable from 'vuedraggable'
import { defineComponent, PropType } from 'vue'

/** 作業予定表 */
export default defineComponent({
  /** 名称 */
  name: 'work-table',
  /** コンポーネント追加 */
  components: { draggable },
  /** プロパティ */
  props: {
    /** ID */
    id: { type: String, default: '' },
    /** 作業日 */
    workDate: { type: String, default: '' },
    /** 時間帯 */
    timeAreas: { type: Object, default: [] },
    /** 作業項目 */
    workItems: { type: Object, default: [] },
    /** 作業予定 */
    works: { type: Object, default: {} },
    /** 本日表示 */
    showTodayTitle: { type: Boolean, default: false },
    /** ドラッグ可否 */
    isDraggable: { type: Boolean, default: false },
    /** 移動可否 */
    canMove: { type: Boolean, default: true }
  },
  /** セットアップスクリプト */
  setup(props, context) {},
  /** エミット定義 */
  emits: ['move', 'showDetail'],
  /** コンピュート定義 */
  computed: {
    /** 作業日(表示用) */
    workDateDisp() {
      const workDate = this.workDate.ToDate()
      if (workDate) {
        return workDate.ToString(Constants.Format.DateFormatType.WORK_LIST)
      } else {
        return ''
      }
    }
  },
  /** 関数定義 */
  methods: {
    /** 作業項目取得 */
    /*
    getWorkItems(timeAreaCode: string, workItemCode: string): WorkItem[] {
      return this.works.filter((x) => x.timeAreaCode == timeAreaCode && x.workCode == workItemCode)
    },
    */
    /** スタイル取得 */
    getStyle(workItem: WorkItem) {
      // 戻り値定義
      const style: any = {}
      // 背景色
      if (workItem.backgroundColor) {
        style['background'] = String(workItem.backgroundColor + ' !important')
      }

      // スタイルを返す
      return style
    },
    /** 移動開始イベント */
    onMoveStart(event: any) {
      // 移動可否判定
      if (!this.canMove) return false
    },
    /** 移動イベント */
    onMoveItem(event: any) {
      // 移動可否判定
      if (!this.canMove) return false
      // console.log(event)
      const fromElement = event.from as HTMLElement
      const toElement = event.to as HTMLElement
      const item = event.item as HTMLElement

      const fromParameter = {
        workDate: fromElement.attributes.getNamedItem('workdate')?.value,
        timeAreaCode: fromElement.attributes.getNamedItem('timeareacode')?.value,
        WorkItemCode: fromElement.attributes.getNamedItem('workitemcode')?.value
      }
      const toParameter = {
        workDate: toElement.attributes.getNamedItem('workdate')?.value,
        timeAreaCode: toElement.attributes.getNamedItem('timeareacode')?.value,
        WorkItemCode: toElement.attributes.getNamedItem('workitemcode')?.value
      }
      const elementKey = item.attributes.getNamedItem('elementkey')?.value

      // イベント発火
      this.$emit('move', { id: elementKey, from: fromParameter, to: toParameter })
    },
    /** 詳細表示 */
    onShowDetail(parameter: { workDate: string; timeAreaCode: string; workItemCode: string; item: WorkItem }) {
      // イベント発火
      this.$emit('showDetail', parameter)
    }
  }
})
</script>
