/**
 * パスワードチェック結果
 */
export enum PasswordResult {
  /** OK */
  OK = 0,
  /** 桁数チェックNG */
  NG_LENGTH,
  /** 入力文字NG */
  NG_ALLOW_INPUT,
  /** 大文字必須NG */
  NG_REQUIRED_UPPPER,
  /** 数字必須NG */
  NG_REQUIRED_NUMBER,
  /** 記号必須NG */
  NG_REQUIRED_SYMBOL
}

/** 正規表現 */
export const RegExpPattern = {
  /** メールアドレス */
  Email: '^[a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*.)+[a-zA-Z]{2,}$',
  /** URL */
  Url: "http(s)?://[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#\u3000-\u30FE\u4E00-\u9FA0\uFF01-\uFFE3]+",
  /** パスワードポシリー */
  PasswordPolicy: {
    /** 最低文字数 */
    MinLength: 8,
    /** 最大文字数 */
    MaxLength: 20,
    /** 入力可能文字 */
    AllowInput: '[a-zA-Z0-9]+$',
    /** 入力可能文字 */
    AllowInputSymbol: '[!@#$%^&*()_\\+\\-=\\[\\]{};:?,.]+',
    /** 大文字必須 */
    RequiredUpper: true,
    /** 数字必須 */
    RequiredNumber: true,
    /** 記号必須 */
    RequiredSymbol: true
  }
}

/**
 * 正規表現サービス
 */
export class RegexpService {
  /**
   * メールアドレスチェック
   * @param email メールアドレス
   * @returns true: OK false: NG
   */
  public static isEmail(email: string): boolean {
    // パターン取得
    const pattern = RegExpPattern.Email
    return this.isCheck(pattern, email)
  }
  /**
   * URLチェック
   * @param url URL
   * @returns true: OK false: NG
   */
  public static isUrl(url: string): boolean {
    // パターン取得
    const pattern = RegExpPattern.Url
    return this.isCheck(pattern, url)
  }
  /**
   * 数値チェック
   * @param url URL
   * @returns true: OK false: NG
   */
  public static isNumber(number: string): boolean {
    // チェック結果を返す
    return this.isCheck('^[+,-]?([0-9]\\d*|0)(\\.\\d+)?$', number)
  }
  /**
   * 半角チェック
   * @param half 半角文字
   * @returns true: OK false: NG
   */
  public static isHalf(half: string): boolean {
    // チェック結果を返す
    return this.isCheck('[ -~]', half)
  }
  /**
   * 全角チェック
   * @param full 全角文字
   * @returns true: OK false: NG
   */
  public static isFull(full: string): boolean {
    // チェック結果を返す
    return this.isCheck('^[^\x01-\x7E\xA1-\xDF]+$', full)
  }
  /**
   * 電話番号チェック
   * @param phone 電話番号
   * @returns true: OK false: NG
   */
  public static isTel(phone: string): boolean {
    // チェック結果を返す
    return this.isMobilePhone(phone) || this.isFixedPhone(phone)
  }
  /**
   * 時間チェック
   * @param time 時間
   * @returns true: OK false: NG
   */
  public static isTime(time: string): boolean {
    // 入力有無
    if (time) {
      // コロン有無
      const checkSome = time.match(/:/g)
      // コロン複数チェック
      if (checkSome !== null && checkSome.length > 1) return false
      // 時間取得
      let hour = 0
      let mins = 0
      // コロン有無
      const checkCoron = time.match(/:/g)

      if (checkCoron == null || (checkCoron !== null && checkCoron.length == 0)) {
        // コロン削除
        const value: string = time.padStart(4, '0')
        // 桁数チェック
        if (value.length > 4 || !this.isNumber(value)) return false
        // 時間取得
        hour = String(value).substr(0, 2).ToNumber() ? Number(String(value).substr(0, 2)) : 0
        mins = String(value).substr(-2).ToNumber() ? Number(String(value).substr(-2)) : 0
      } else {
        // コロン削除
        const value: string = time.replace(':', '').padStart(4, '0')
        // 桁数チェック
        if (value.length > 4 || !this.isNumber(value)) return false
        // 時間取得
        hour = time.split(':')[0].ToNumber() ? Number(time.split(':')[0]) : 0
        mins = time.split(':')[1].ToNumber() ? Number(time.split(':')[1]) : 0
      }
      // 範囲チェック
      if (hour < 0 || 23 < hour || mins < 0 || 59 < mins) return false
    }
    // OK
    return true
  }
  /**
   * 固定電話番号チェック
   * @param phone 電話番号
   * @returns true: OK false: NG
   */
  public static isFixedPhone(phone: string): boolean {
    // チェック結果を返す
    if (this.isCheck('^0[789]0+', phone)) {
      return false
    } else if (this.isCheck('^0\\d{1,4}-\\d{1,4}-\\d{3,4}$', phone)) {
      return true
    } else if (this.isCheck('^0\\d{9}$', phone)) {
      return true
    } else {
      return false
    }
  }
  /**
   * 携帯電話番号チェック
   * @param phone 電話番号
   * @returns true: OK false: NG
   */
  public static isMobilePhone(phone: string): boolean {
    // チェック結果を返す
    if (this.isCheck('^0[789]0-[0-9]{4}-[0-9]{4}$', phone)) {
      return true
    } else if (this.isCheck('^0[789]0\\d{8}$', phone)) {
      return true
    } else {
      return false
    }
  }
  /**
   * パスワードチェック
   * @param password パスワード
   * @returns true: OK false: NG
   */
  public static isPassword(password: string): PasswordResult {
    // 入力有無
    if (!password) return PasswordResult.OK
    const unallowInput = (RegExpPattern.PasswordPolicy.AllowInput + RegExpPattern.PasswordPolicy.AllowInputSymbol)
      .replace(']+$[', '')
      .replace(']+$^[', '')
    // 桁数チェック
    if (
      password.length < RegExpPattern.PasswordPolicy.MinLength ||
      RegExpPattern.PasswordPolicy.MaxLength < password.length
    ) {
      return PasswordResult.NG_LENGTH
    } else if (!this.isCheck(unallowInput, password)) {
      return PasswordResult.NG_ALLOW_INPUT
    } else if (RegExpPattern.PasswordPolicy.RequiredUpper && !this.isCheck('[A-Z]', password)) {
      return PasswordResult.NG_REQUIRED_UPPPER
    } else if (RegExpPattern.PasswordPolicy.RequiredNumber && !this.isCheck('[0-9]', password)) {
      return PasswordResult.NG_REQUIRED_NUMBER
    } else if (
      RegExpPattern.PasswordPolicy.RequiredSymbol &&
      !password.match(new RegExp(RegExpPattern.PasswordPolicy.AllowInputSymbol, 'g'))
    ) {
      return PasswordResult.NG_REQUIRED_SYMBOL
    }
    // パスワードOK
    return PasswordResult.OK
  }

  /**
   * チェック処理
   * @param pattern チェックパターン
   * @param value チェック値
   */
  public static isCheck(pattern: string, value: any): boolean {
    // NULLチェック
    if (value === undefined || value === null || value === '') return true
    // チェック結果を返す
    return new RegExp(pattern, 'g').test(value)
  }
}
