import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_dialog = _resolveComponent("base-dialog")!

  return (_openBlock(), _createBlock(_component_base_dialog, {
    id: _ctx.id,
    title: _ctx.title,
    headerBackgroundColor: "#19B5FE",
    headerColor: "#FFF",
    showClose: false,
    center: ""
  }, {
    footer: _withCtx(() => [
      _createElementVNode("button", {
        type: "button",
        class: "btn btn-primary",
        role: "button",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClickYes && _ctx.onClickYes(...args)))
      }, "はい"),
      _createElementVNode("button", {
        type: "button",
        class: "btn btn-secondary",
        role: "button",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onClickNo && _ctx.onClickNo(...args)))
      }, "いいえ"),
      (_ctx.showCancel)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            type: "button",
            class: "btn btn-default",
            role: "button",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onClickCancel && _ctx.onClickCancel(...args)))
          }, " キャンセル "))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: "message-body",
        innerHTML: _ctx.message
      }, null, 8, _hoisted_1)
    ]),
    _: 1
  }, 8, ["id", "title"]))
}