<template>
  <span :class="{ 'no-label': !label }">
    <input
      ref="checkboxControl"
      class="checkbox-control"
      type="checkbox"
      :id="name"
      :name="name"
      v-model="modelValue"
      :disabled="disabled"
    />
    <label :for="name" class="checkbox-control-label">{{ label }}</label>
  </span>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
/** チェックボックスコントロール */
export default defineComponent({
  /** 名称 */
  name: 'checkbox-control',
  /** プロパティ */
  props: {
    /** パラメータ */
    parameter: { default: undefined },
    /** ID */
    id: { type: String, default: '' },
    /** タグ名 */
    name: { type: String, default: '' },
    /** スタイル */
    style: { type: String, default: '' },
    /** ラベル */
    label: { type: String, default: '' },
    /** 活性制御 */
    disabled: { type: Boolean, default: false }
  },
  emits: ['input'],
  /** セットアップスクリプト */
  setup(props, context) {},
  /** コンピュート定義 */
  computed: {
    /** 設定値 */
    modelValue: {
      /** 値取得 */
      get(): boolean {
        if (this.parameter && this.id) {
          return this.parameter[this.id]
        }
        return false
      },
      /** 値設定 */
      set(setValue: boolean) {
        /** パラメータ有無 */
        if (this.parameter && this.id) {
          this.parameter[this.id] = setValue as never
          this.$emit('input', setValue)
        }
      }
    }
  }
})
</script>
<style lang="stylus">
borderColor = #aaa
baseColor = #4FC1E9
input[type=checkbox].checkbox-control
  display none
input[type=checkbox].checkbox-control + label
  box-sizing border-box
  cursor pointer
  padding 5px 20px 5px 35px
  position relative
  width auto
input[type=checkbox].checkbox-control + label::before
  position absolute
  top 50%
  left 5px
  display block
  margin-top -10px
  width 20px
  height 20px
  content ''
  background #fff
  border 1px solid borderColor
input[type=checkbox].checkbox-control + label::after
  border-right 3px solid baseColor
  border-bottom 3px solid baseColor
  content ''
  display none
  height 14px
  left 11px
  margin-top -9px
  position absolute
  top 50%
  transform rotate(45deg)
  width 8px
input[type=checkbox].checkbox-control:checked + label::before
  background baseColor
input[type=checkbox].checkbox-control:checked + label::after
  border-right 3px solid #fff
  border-bottom 3px solid #fff
  transform rotate(45deg)
  display block
.no-label
  input[type=checkbox].checkbox-control + label
    padding 0px !important
    width 20px !important
  input[type=checkbox].checkbox-control + label::before
    top 0px
    left 0px
    margin-top -14px
  input[type=checkbox].checkbox-control + label::after
    left 6px
    margin-top -13px
</style>
