<template>
  <base-dialog :id="id" :title="title" headerBackgroundColor="#F9690E" headerColor="#FFF" :showClose="false" center>
    <div class="message-body" v-html="message" />
    <template v-slot:footer>
      <button type="button" class="btn btn-secondary" role="button" @click="onClickClose">閉じる</button>
    </template>
  </base-dialog>
</template>

<script lang="ts">
import { Constants } from '@/modules/constants'
import { defineComponent, ref } from 'vue'
import { BaseDialog } from './'

/** 警告ダイアログ */
export default defineComponent({
  components: { BaseDialog },
  /** 名称 */
  name: 'warn-dialog',
  /** プロパティ */
  props: {
    /** ID */
    id: { type: String, default: 'warnDialog' }
  },
  /** セットアップ */
  setup(prop, context) {
    const title = ref('')
    const message = ref('')
    const callback = ref((response: Constants.Message.ResponseCode) => {})
    return { title, message, callback }
  },
  /** 関数定義 */
  methods: {
    /** 表示 */
    show(message: string, title: string, callback?: any) {
      // コンポーネント取得
      const dialog = $('#' + this.id) as any
      // ダイアログ有無
      if (dialog) {
        // パラメータセット
        this.title = title
        this.message = message
        if (callback) {
          this.callback = callback
        }
        // ダイアログ表示
        dialog.modal('show')
      }
    },
    /** CLOSEボタンクリック */
    onClickClose() {
      // コンポーネント取得
      const dialog = $('#' + this.id) as any
      // ダイアログ有無
      if (dialog) {
        // ダイアログ非表示
        dialog.modal('hide')
        // コールバック実施
        this.callback(Constants.Message.ResponseCode.CLOSE)
      }
    }
  }
})
</script>
