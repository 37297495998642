<template>
  <div class="col-xs-6 col-sm-3 sidebar-offcanvas ctm_font-color-1" role="navigation">
    <ul class="list-group panel cate1">
      <!-- <li class="list-group-item search_box"><input type="text" class="form-control search-query" placeholder="キーワード検索"></li> -->
      <li class="list-group-item ctm_bg-color-1"><b>管理者メニュー</b></li>
      <li :class="{ 'list-group-item btn_left': true, active: isActive(['top']) }">
        <router-link :to="{ name: 'top' }"> <i class="glyphicon glyphicon-home"></i>ホーム </router-link>
      </li>
      <li :class="{ 'list-group-item btn_left': true, active: isActive(['work-list']) }">
        <router-link :to="{ name: 'work-list' }"><i class="glyphicon glyphicon-list-alt"></i>作業予定表</router-link>
      </li>
      <li
        :class="{
          'list-group-item btn_left': true,
          active: isActive(['request-list', 'request-regist', 'request-edit'])
        }"
      >
        <router-link :to="{ name: 'request-list' }"> <i class="glyphicon glyphicon-flag"></i>依頼一覧 </router-link>
      </li>
      <li :class="{ 'list-group-item btn_left': true, active: isActive(['delivery-list']) }">
        <router-link :to="{ name: 'delivery-list' }"><i class="glyphicon glyphicon-flag"></i>納品予定表</router-link>
      </li>
      <li :class="{ 'list-group-item btn_left': true, active: isActive(['task-list']) }">
        <router-link :to="{ name: 'task-list' }"><i class="glyphicon glyphicon-flag"></i>タスク管理</router-link>
      </li>
      <li :class="{ 'list-group-item btn_left': true, active: isActive(['printing-list']) }">
        <router-link :to="{ name: 'printing-list' }"><i class="glyphicon glyphicon-flag"></i>印刷工程用</router-link>
      </li>
      <li :class="{ 'list-group-item btn_left': true, active: false }">
        <a href="" @click="logout()"><i class="glyphicon glyphicon-log-out"></i>ログアウト</a>
      </li>
    </ul>
    <ul v-if="isAdmin()" class="list-group panel cate4">
      <li class="list-group-item ctm_bg-color-1"><b>アカウント管理</b></li>
      <li
        v-if="isSystemAdmin()"
        :class="{ 'list-group-item btn_left': true, active: isActive(['admin-list', 'admin-regist']) }"
      >
        <router-link :to="{ name: 'admin-list' }"> <i class="glyphicon glyphicon-user"></i>システム管理者 </router-link>
      </li>
      <li :class="{ 'list-group-item btn_left': true, active: isActive(['user-list', 'user-edit']) }">
        <router-link :to="{ name: 'user-list' }"><i class="fa fa-users"></i>ユーザー｜一覧</router-link>
      </li>
      <li :class="{ 'list-group-item btn_left': true, active: isActive(['user-regist']) }">
        <router-link :to="{ name: 'user-regist' }"><i class="fa fa-users"></i>ユーザー｜新規登録</router-link>
      </li>
    </ul>
    <ul class="list-group panel cate5">
      <li class="list-group-item ctm_bg-color-1"><b>アカウント設定</b></li>
      <li :class="{ 'list-group-item btn_left': true, active: isActive(['update-user']) }">
        <router-link :to="{ name: 'update-user' }">
          <i class="glyphicon glyphicon-cog"></i>アカウント情報変更
        </router-link>
      </li>
      <li :class="{ 'list-group-item btn_left': true, active: isActive(['change-password']) }">
        <router-link :to="{ name: 'change-password' }">
          <i class="glyphicon glyphicon-cog"></i>パスワード変更
        </router-link>
      </li>
    </ul>
  </div>
</template>
<script lang="ts">
import { loginInfo } from '@/store/LoginInfo'
import { defineComponent } from 'vue'

/** メニューパーツ */
export default defineComponent({
  /** 名称 */
  name: 'menu-parts',
  /** セットアップスクリプト */
  setup(props) {},
  /** 関数定義 */
  methods: {
    /** アクティブ判定 */
    isActive(name: string[]): boolean {
      // ルーティング判定
      return name.indexOf(String(this.$route.name)) >= 0
    },
    /** システム管理者判定 */
    isSystemAdmin(): boolean {
      return loginInfo.userType == '99'
    },
    isAdmin(): boolean {
      return ['09', '99'].indexOf(loginInfo.userType) >= 0
    },
    /** ログアウト処理 */
    logout() {
      // ログアウト
      loginInfo.logout()
      // ログイン画面に遷移
      this.$router.push('/')
    }
  }
})
</script>
