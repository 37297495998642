import { BaseDataTransferObject } from './BaseDataTransferObject'

/**
 * 基底APIリクエストクラス
 */
export class BaseApiRequest extends BaseDataTransferObject {
  /** コンストラクタ */
  constructor(origin?: any) {
    super(origin)
    // 初期値を設定
    this.loginUserInfo = {
      userId: '',
      userName: '',
      userType: '',
      customerId: ''
    }
    this.operationInfo = { programId: '', url: '' }
    this.files = {}
  }
  /** ログインユーザ情報 */
  loginUserInfo: {
    /** ユーザID */
    userId: string
    /** ユーザ名 */
    userName: string
    /** ユーザー種別 */
    userType: string
    /** 顧客ID */
    customerId: string
  }
  /** 操作情報 */
  operationInfo: {
    /** プログラムID */
    programId: string
    /** URL */
    url: string
  }
  /** アップロードファイル */
  files?: { [key: string]: any[] }
}
