import { Mutation, Action, VuexModule, getModule, Module } from 'vuex-module-decorators'
import store from '@/store'

/** ログイン情報インターフェース */
export interface LoginState {
  /** ユーザーID */
  userId?: string
  /** ログインID */
  loginId?: string
  /** ユーザー名 */
  userName?: string
  /** ユーザー種別 */
  userType?: string
  /** 識別キー */
  identityKey?: string
  /** パスワード期限切れ */
  expiredPassword?: boolean
  /** 有効フラグ */
  isEffective?: boolean
  /** 最終ログイン日時 */
  lastLoginAt?: string
}

/** ログイン情報 */
@Module({ dynamic: true, store, name: 'login', namespaced: true, preserveState: true })
class LoginInfo extends VuexModule implements LoginState {
  // デフォルト値定義
  private readonly defaultUserId: string = ''
  private readonly defaultLoginId: string = ''
  private readonly defaultUserName: string = ''
  private readonly defaultUserType: string = ''
  private readonly defaultIdentityKey: string = ''
  private readonly defaultExpiredPassword: boolean = false
  private readonly defaultIsEffective: boolean = false
  private readonly defaultLastLoginAt: string = ''

  /** ユーザーID */
  public userId: string = this.defaultUserId
  /** ログインID */
  public loginId: string = this.defaultLoginId
  /** 名前 */
  public userName: string = this.defaultUserName
  /** ユーザー種別 */
  public userType: string = this.defaultUserType
  /** 識別キー */
  public identityKey: string = this.defaultIdentityKey
  /** パスワード期限切れ */
  public expiredPassword: boolean = this.defaultExpiredPassword
  /** 有効フラグ */
  public isEffective: boolean = this.defaultIsEffective
  /** 最終ログイン日時 */
  public lastLoginAt: string = this.defaultLastLoginAt

  /**
   * ログイン情報設定
   * @param param ログイン情報
   */
  @Mutation
  public set(param: LoginState | any): void {
    // 各値を設定
    this.userId = param.userId
    this.loginId = param.loginId
    this.userName = param.userName
    this.userType = param.userType
    this.identityKey = param.identityKey
    this.expiredPassword = param.expiredPassword
    this.isEffective = param.isEffective
    this.lastLoginAt = param.lastLoginAt
  }

  /** ログイン情報クリア */
  @Mutation
  public clear(): void {
    this.userId = this.defaultUserId
    this.loginId = this.defaultLoginId
    this.userName = this.defaultUserName
    this.userType = this.defaultUserType
    this.identityKey = this.defaultIdentityKey
    this.expiredPassword = this.defaultExpiredPassword
    this.isEffective = this.defaultIsEffective
    this.lastLoginAt = this.defaultLastLoginAt
  }

  /**
   * ログイン
   * @param param ユーザー情報
   */
  @Action({})
  public login(param: LoginState | any): void {
    // ログイン情報作成
    const loginState: LoginState = {
      userId: param.userId ? param.userId : param.id,
      loginId: param.loginId,
      userName: param.userName,
      userType: param.userType,
      identityKey: param.identityKey,
      expiredPassword: param.expiredPassword,
      isEffective: param.isEffective,
      lastLoginAt: param.lastLoginAt
    }
    // テストデータ設定
    this.set(loginState)
  }

  /** ログアウト */
  @Action({})
  public logout(): void {
    this.clear()
  }
}

export const loginInfo = getModule(LoginInfo)

// ローカルストレージに情報がある場合読み込む
if (sessionStorage.getItem('app')) {
  const json: string = sessionStorage.getItem('app') as string
  const strageData = JSON.parse(json)
  if (strageData.login) {
    loginInfo.set(strageData.login)
  }
}
