<template>
  <router-view />
  <loading />
  <information-dialog ref="informationDialog" />
  <confirm-dialog ref="confirmDialog" />
  <warn-dialog ref="warnDialog" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { InformationDialog, ConfirmDialog, WarnDialog } from '@/components/dialog'
import Loading from '@/components/layout/parts/Loading.vue'

/** メインアプリケーション */
export default defineComponent({
  /** 名称 */
  name: 'App',
  /** コンポーネント定義 */
  components: { InformationDialog, ConfirmDialog, WarnDialog, Loading },
  /** セットアップスクリプト */
  setup(props) {},
  /** コンピュート定義 */
  computed: {},
  /** データ定義 */
  data() {
    return {}
  }
})
</script>
