import { Constants } from '../constants'

/**
 * 拡張メソッド定義
 */
declare global {
  /**
   * 数値型の拡張メソッド
   */
  interface Number {
    /**
     * カンマ形式に変換
     */
    ToComma(): string
    /**
     * パディング処理
     * @param length 桁数
     * @param char 文字列
     */
    padStart(length: number, char?: string): string
    /**
     * パディング処理
     * @param length 桁数
     * @param char 文字列
     */
    padEnd(length: number, char?: string): string
  }
  /**
   * 文字列型の拡張メソッド
   */
  interface String {
    /**
     * 非可逆暗号化処理
     */
    unReversedEncrypt(): string

    /**
     * 文字列追加
     * @param value 追加文字列
     */
    append(value: string): string

    /**
     * 文字列追加(改行付き)
     * @param value 追加文字列
     */
    appendLine(value: string): string
    /**
     * 日時変換処理
     */
    ToDate(): Date | undefined
    /**
     * 数値変換
     */
    ToNumber(): number | undefined
    /**
     * 英数字全角変換
     */
    ToFullText(): string
    /**
     * 英数字半角変換
     */
    ToHalfText(): string
    /**
     * キャメルケース変換
     */
    ToCamelCase(): string
    /**
     * スネークケース変換
     */
    ToSnakeCase(): string
    /**
     * ケバブケース変換
     */
    ToKebabCase(): string
    /**
     * シャッフル
     */
    shuffle(): string
    /**
     * 書式変換
     * @param parameter パラメータ
     */
    format(...parameter: any[]): string
  }
  /** 配列型の拡張メソッド */
  interface Array<T> {
    /** テキスト取得 */
    getText(key: any): string
  }
  /**
   * 日付型の拡張メソッド
   */
  interface Date {
    /**
     * 文字列変換処理
     * @param format 書式
     */
    ToString(format?: Constants.Format.DateFormatType): string
    /**
     * 日付の複写
     * @param from 複写元日付
     */
    copyFrom(from: Date): void
    /**
     * 時間の加算
     * @param hours 加算時間
     */
    addHour(hours: number): Date
    /**
     * 日付の加算
     * @param days 加算日
     */
    addDate(days: number): Date
    /**
     * 月の加算
     * @param months 加算月
     */
    addMonth(months: number): Date
    /**
     * 年の加算
     * @param years 加算年
     */
    addYear(years: number): Date
    /**
     * 差分日取得
     * @param date 日付
     */
    diffDays(date: Date): number
    /**
     * 年内の日数
     * @param date 日付
     */
    daysOfYear(): number
  }
}

/**
 * 拡張メソッド実装部分
 */
/**
 * カンマ形式に変換
 */
Number.prototype.ToComma = function () {
  return String(this).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
}
/**
 * パディング処理
 */
Number.prototype.padStart = function (length: number, char?: string) {
  return String(this).padStart(length, char)
}
/**
 * パディング処理
 */
Number.prototype.padEnd = function (length: number, char?: string) {
  return String(this).padEnd(length, char)
}

/**
 * 非可逆暗号化処理
 */
String.prototype.unReversedEncrypt = function () {
  // 変換する値を取得
  const value = String(this)

  /** 
  // SHA-256暗号化用ハッシュ
  const hash = createHash('sha256')
  // ハッシュ値に設定
  hash.update(value)
  */

  // SHA-256ハッシュを定義
  const SHA256 = require('crypto-js/sha256')

  // Base64に変換して返す
  return SHA256(value).toString()
}

/**
 * 文字列追加
 */
String.prototype.append = function (value: string) {
  const origin = String(this)
  // 文字列追加
  return origin + value
}

/**
 * 文字列追加(改行付き)
 */
String.prototype.appendLine = function (value: string) {
  const origin = String(this)

  // 自身の空白チェック
  if (!origin) return value

  // 文字列＋改行追加
  return origin + '\r\n' + value
}

/**
 * 日時型変換
 */
String.prototype.ToDate = function () {
  // NULL判定
  if (this === null || this === undefined) return undefined

  try {
    const date = new Date(String(this))
    return isNaN(date.getDate()) ? undefined : new Date(String(this))
  } catch {
    // 上記以外
    // 日付型に変換して返す
    return new Date(String(this).replace(/T/g, ' ').replace(/Z/g, ''))
  }
}

/**
 * 数値型変換
 */
String.prototype.ToNumber = function () {
  // NULL判定
  if (this === null || this === undefined || this.trim() === '') return undefined

  // 正規表現
  const check = new RegExp('^[+,-]?([0-9]\\d*|0)(\\.\\d+)?$', 'g')

  // 数字判定
  if (check.test(this.toString().replace(',', ''))) {
    return Number(this.toString().replace(',', ''))
  } else {
    return undefined
  }
}

/**
 * 英数字全角変換
 */
String.prototype.ToFullText = function () {
  // NULL判定
  if (this === null || this === undefined) return ''

  return this.replace(/[A-Za-z0-9]/g, function (s) {
    return String.fromCharCode(s.charCodeAt(0) + 0xfee0)
  })
}

/**
 * 英数字半角変換
 */
String.prototype.ToHalfText = function () {
  // NULL判定
  if (this === null || this === undefined) return ''

  return this.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
    return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
  })
}

/**
 * キャメルケース変換
 */
String.prototype.ToCamelCase = function () {
  // NULL判定
  if (this === null || this === undefined) return ''

  return this.replace(/[-_](.)/g, (match: string, str: string): string => {
    return str.toUpperCase()
  })
    .replace(/[-_]/g, '')
    .replace(/[A-Z]/g, (str: string, index: number) => {
      if (index === 0) {
        return str.charAt(0).toLowerCase()
      } else {
        return str
      }
    })
}

/**
 * スネークケース変換
 */
String.prototype.ToSnakeCase = function () {
  // NULL判定
  if (this === null || this === undefined) return ''

  return this.ToCamelCase().replace(/[A-Z]/g, (str: string, index: number): string => {
    if (index === 0) {
      return str.charAt(0).toLowerCase()
    } else {
      return '_' + str.charAt(0).toLowerCase()
    }
  })
}

/**
 * ケバブケース変換
 */
String.prototype.ToKebabCase = function () {
  // NULL判定
  if (this === null || this === undefined) return ''

  return this.ToCamelCase().replace(/[A-Z]/g, (str: string, index: number): string => {
    if (index === 0) {
      return str.charAt(0).toLowerCase()
    } else {
      return '-' + str.charAt(0).toLowerCase()
    }
  })
}
/**
 * シャッフル
 */
String.prototype.shuffle = function (): string {
  // 文字列を配列に格納
  const input: string[] = this.split('')
  for (let i = input.length - 1; i >= 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    // 要素の並び替えを実行
    const tmp = input[i]
    input[i] = input[j]
    input[j] = tmp
  }

  return input.join('')
}

/**
 * 書式変換
 */
String.prototype.format = function (...parameter: any[]): string {
  const value = String(this)

  // 値有無
  if (value) {
    // 型判定
    if (
      parameter &&
      parameter.length == 1 &&
      parameter[0] !== null &&
      typeof parameter[0] == 'object' &&
      Object.keys(parameter[0]).length >= 1
    ) {
      const keys: string[] = Object.keys(parameter[0])
      // 置換処理
      const result: string = value.replace(/\{(.+?)\}/g, function (text: string, key: string): string {
        // キー存在チェック
        if (keys.indexOf(key) >= 0) {
          // キーあり
          return String(parameter[0][key])
        } else {
          // キーなし
          return text
        }
      })

      // 結果を返す
      return result
    } else {
      // 置換処理
      const result: string = value.replace(/\{(.+?)\}/g, function (text: string, key: string): string {
        // 数字判定
        if (!isNaN(Number(key))) {
          const index = Number(key)
          // 範囲チェック
          if (parameter && index < parameter.length) {
            return String(parameter[index])
          } else {
            return text
          }
        } else {
          return text
        }
      })

      // 結果を返す
      return result
    }
  } else {
    return value
  }
}
/** テキスト取得 */
Array.prototype.getText = function (key: any) {
  const list: any[] = this.filter((x) => x.value == String(key))
  if (list && list.length > 0) return list[0].text
  return ''
}
/**
 * 文字列変換
 */
Date.prototype.ToString = function (format?: Constants.Format.DateFormatType) {
  // システム日付を取得
  const year: number = this.getFullYear()
  const yearShort = Number(String(year).substring(2))
  const month: number = this.getMonth() + 1
  const day: number = this.getDate()
  const week: number = this.getDay()
  const hour: number = this.getHours()
  const mins: number = this.getMinutes()
  const sec: number = this.getSeconds()
  const msec: number = this.getMilliseconds()

  const _format: Constants.Format.DateFormatType = format === undefined ? Constants.Format.DateFormatType.NONE : format

  // 書式を判定
  switch (_format) {
    case Constants.Format.DateFormatType.YMD:
      // 標準形式
      return year.padStart(4, '0') + '/' + month.padStart(2, '0') + '/' + day.padStart(2, '0') + ''
    case Constants.Format.DateFormatType.YMD_JP:
      // 標準形式
      return year.padStart(4, '0') + '年' + month.padStart(2, '0') + '月' + day.padStart(2, '0') + '日'
    case Constants.Format.DateFormatType.SHORT:
      // 短い形式
      return yearShort.padStart(2, '0') + '/' + month.padStart(2, '0') + '/' + day.padStart(2, '0') + ''
    case Constants.Format.DateFormatType.SHORT_JP:
      // 短い形式
      return yearShort.padStart(2, '0') + '年' + month.padStart(2, '0') + '月' + day.padStart(2, '0') + '日'
    case Constants.Format.DateFormatType.WITH_WEEK:
      // 長い形式
      return (
        year.padStart(4, '0') +
        '/' +
        month.padStart(2, '0') +
        '/' +
        day.padStart(2, '0') +
        '' +
        ' (' +
        Constants.Date.WEEK[week] +
        ')'
      )
    case Constants.Format.DateFormatType.WITH_WEEK_JP:
      // 長い形式
      return (
        year.padStart(4, '0') +
        '年' +
        month.padStart(2, '0') +
        '月' +
        day.padStart(2, '0') +
        '日' +
        ' (' +
        Constants.Date.WEEK[week] +
        ')'
      )
    case Constants.Format.DateFormatType.YEAR:
      // 年
      return year.padStart(4, '0') + ''
    case Constants.Format.DateFormatType.YEAR_MONTH:
      // 年月
      return year.padStart(4, '0') + '/' + month.padStart(2, '0') + ''
    case Constants.Format.DateFormatType.TIME:
      // 時間
      return hour.padStart(2, '0') + ':' + mins.padStart(2, '0') + ':' + sec.padStart(2, '0')
    case Constants.Format.DateFormatType.TIME_JP:
      // 時間
      return hour.padStart(2, '0') + '時' + mins.padStart(2, '0') + '分' + sec.padStart(2, '0') + '秒'
    case Constants.Format.DateFormatType.HOUR_MIN:
      // 時分
      return hour.padStart(2, '0') + ':' + mins.padStart(2, '0')
    case Constants.Format.DateFormatType.HOUR_MIN_JP:
      // 時分
      return hour.padStart(2, '0') + '時' + mins.padStart(2, '0') + '分'
    case Constants.Format.DateFormatType.DATE_TIME:
      // 日時
      return (
        year.padStart(4, '0') +
        '/' +
        month.padStart(2, '0') +
        '/' +
        day.padStart(2, '0') +
        ' ' +
        hour.padStart(2, '0') +
        ':' +
        mins.padStart(2, '0') +
        ':' +
        sec.padStart(2, '0')
      )
    case Constants.Format.DateFormatType.DATE_TIME_JP:
      // 日時(ローカライズ)
      return (
        year.padStart(4, '0') +
        '年' +
        month.padStart(2, '0') +
        '月' +
        day.padStart(2, '0') +
        '日' +
        ' ' +
        hour.padStart(2, '0') +
        '時' +
        mins.padStart(2, '0') +
        '分' +
        sec.padStart(2, '0') +
        '秒'
      )
    case Constants.Format.DateFormatType.POSTED_DATETIME:
      // 日時(ローカライズ)
      return (
        year.padStart(4, '0') +
        '-' +
        month.padStart(2, '0') +
        '-' +
        day.padStart(2, '0') +
        '' +
        ' ' +
        hour.padStart(2, '0') +
        ':' +
        mins.padStart(2, '0') +
        ':' +
        sec.padStart(2, '0')
      )
    case Constants.Format.DateFormatType.TIMESTAMP:
      // タイムスタンプ
      return (
        year.padStart(4, '0') +
        month.padStart(2, '0') +
        day.padStart(2, '0') +
        hour.padStart(2, '0') +
        mins.padStart(2, '0') +
        sec.padStart(2, '0') +
        msec.toString()
      )
    case Constants.Format.DateFormatType.TIMESTAMP_DATE:
      // タイムスタンプ
      return year.padStart(4, '0') + month.padStart(2, '0') + day.padStart(2, '0')
    case Constants.Format.DateFormatType.TIMESTAMP_DATE_TIME:
      // タイムスタンプ
      return (
        year.padStart(4, '0') +
        month.padStart(2, '0') +
        day.padStart(2, '0') +
        hour.padStart(2, '0') +
        mins.padStart(2, '0') +
        sec.padStart(2, '0')
      )
    case Constants.Format.DateFormatType.TIMESTAMP_TIME:
      // タイムスタンプ
      return hour.padStart(2, '0') + mins.padStart(2, '0') + sec.padStart(2, '0')
    case Constants.Format.DateFormatType.JST:
      // 日時
      return (
        year.padStart(4, '0') +
        '-' +
        month.padStart(2, '0') +
        '-' +
        day.padStart(2, '0') +
        'T' +
        hour.padStart(2, '0') +
        ':' +
        mins.padStart(2, '0') +
        ':' +
        sec.padStart(2, '0') +
        '+0900'
      )
    case Constants.Format.DateFormatType.WORK_LIST:
      // 作業予定表形式
      return '{0}月{1}日（{2}）'.format(month, day, Constants.Date.WEEK[week])
    case Constants.Format.DateFormatType.MONTH_DAY:
      // 月日形式
      return '{0}月{1}日'.format(month, day)
    case Constants.Format.DateFormatType.MONTH_DAY_SHORT:
      // 月日形式
      return '{0}/{1}'.format(month, day)
    default:
      // その他、指定なし
      return year.padStart(4, '0') + '-' + month.padStart(2, '0') + '-' + day.padStart(2, '0')
  }
}

/**
 * 日付の複写
 */
Date.prototype.copyFrom = function (from: Date) {
  // 各項目を複写
  this.setFullYear(from.getFullYear(), from.getMonth(), from.getDate())
  this.setHours(from.getHours(), from.getMinutes(), from.getSeconds(), from.getMilliseconds())
}

/**
 * 時間の加算
 */
Date.prototype.addHour = function (hours: number) {
  // 戻り値を定義
  const result: Date = new Date()
  // 自身をコピー
  result.copyFrom(this)

  // 時間を加算
  result.setHours(result.getHours() + hours)

  // 算出した日付を返す
  return result
}

/**
 * 日付の加算
 */
Date.prototype.addDate = function (days: number) {
  // 戻り値を定義
  const result: Date = new Date()
  // 自身をコピー
  result.copyFrom(this)

  // 日付を加算
  result.setDate(result.getDate() + days)

  // 算出した日付を返す
  return result
}

/**
 * 月の加算
 */
Date.prototype.addMonth = function (months: number) {
  // 戻り値を定義
  const result: Date = new Date()
  // 自身をコピー
  result.copyFrom(this)

  // 月を加算
  result.setMonth(result.getMonth() + months)

  // 算出した日付を返す
  return result
}

/**
 * 年の加算
 */
Date.prototype.addYear = function (years: number) {
  // 戻り値を定義
  const result: Date = new Date()
  // 自身をコピー
  result.copyFrom(this)

  // 年を加算
  result.setFullYear(result.getFullYear() + years)

  // 算出した日付を返す
  return result
}

/**
 * 差分日付取得
 */
Date.prototype.diffDays = function (date: Date) {
  const thisYear: number = this.getFullYear()
  const thisDays: number = this.daysOfYear()
  const Year: number = date.getFullYear()
  const days: number = date.daysOfYear()
  // 日付の比較
  if (this < date) {
    return Year * 365 + days - (thisYear * 365 + thisDays)
  } else {
    return thisYear * 365 + thisDays - (Year * 365 + days)
  }
}

/**
 * 年内の日数
 */
Date.prototype.daysOfYear = function () {
  const year: number = this.getFullYear()
  const dayOfMonthUruu: number[] = [0, 31, 60, 91, 121, 152, 182, 213, 244, 274, 305, 335]
  const dayOfMonth: number[] = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334]
  // 閏年判定
  if ((year % 4 == 0 && year % 100 != 0) || year % 400 == 0) {
    // 閏年
    return dayOfMonthUruu[this.getMonth()] + this.getDate()
  } else {
    // 通常年
    return dayOfMonth[this.getMonth()] + this.getDate()
  }
}
