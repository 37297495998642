import { Constants } from '../constants'

/**
 * 基底APIレスポンスクラス
 */
export class BaseApiResponse {
  /** ステータスコード */
  statusCode: Constants.Api.ResponseCode = Constants.Api.ResponseCode.OK
  /** ステータスメッセージ */
  statusMessage = ''
  /** 読み込み日時 */
  loadAt?: Date
  /** レスポンスデータ */
  responseData: any
  /**
   * 結果取り込み
   * @param data API取得結果
   */
  public async import(data: any): Promise<void> {
    // レスポンス取得
    const response: any = data.Response
    // 結果コード取得
    const responseCode: number = response.code as number
    // 結果判定
    if (responseCode >= Constants.Api.ResponseCode.INTERNAL_ERROR) {
      // サーバーエラーを設定
      this.statusCode = Constants.Api.ResponseCode.INTERNAL_ERROR
    } else {
      // 結果コードを設定
      this.statusCode = responseCode as Constants.Api.ResponseCode
    }
    // 結果メッセージを設定
    this.statusMessage = String(response.message)
    // レスポンセデータ
    this.responseData = response.data
    // 読み込み日時
    this.loadAt = new Date()
  }
}
