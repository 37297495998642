/**
 * 基底DTOクラス
 */
export abstract class BaseDataTransferObject extends FormData {
  /**
   * コンストラクタ
   * @param origin コピー元データ
   */
  constructor(origin?: any) {
    // 基底クラスのコンストラクタ
    super()

    // パラメータ有無
    if (origin) {
      // キー取得
      const keys = Object.keys(origin)
      // 値を追加
      for (let i = 0; i < keys.length; i++) {
        this.append(keys[i], origin[keys[i]])
      }
    }
  }

  /**
   * フォームデータ取り込み
   * @param form フォーデータ
   */
  public async import(form: any) {
    // パラメータ有無
    if (form) {
      // キー取得
      const keys = Object.keys(form)
      // 値を追加
      for (let i = 0; i < keys.length; i++) {
        this.append(keys[i], form[keys[i]])
      }
    }
  }
}
