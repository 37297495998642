<template></template>
<script lang="ts">
import { defineComponent } from 'vue'

/** フッターパーツ */
export default defineComponent({
  /** 名称 */
  name: 'footer-parts',
  /** プロパティ */
  props: {
    /** 背景色 */
    color: { type: String, default: 'footerBackground' },
    /** 文字色 */
    textColor: { type: String, default: 'footerColor' },
    /** 高さ */
    height: { type: String, default: '36px' }
  },
  /** セットアップスクリプト */
  setup(props, context) {}
})
</script>
