<template>
  <header-parts topPage="/home" />
  <div class="container-fluid">
    <!--documents-->
    <div class="row row-offcanvas row-offcanvas-left">
      <menu-parts />
      <div class="col-xs-12 col-sm-9 content">
        <div class="panel-heading">
          <h3 class="panel-title">
            <a href="javascript:void(0);" class="toggle-sidebar">
              <span class="fa fa-angle-double-left" data-toggle="offcanvas" title="Maximize Panel"></span>
            </a>
          </h3>
        </div>
        <div class="panel panel-default">
          <div class="panel-body">
            <div class="content-row">
              <slot />
            </div>
          </div>
          <!-- panel body -->
        </div>
      </div>
      <!-- content -->
    </div>
  </div>
  <footer-parts />
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import HeaderParts from './parts/HeaderParts.vue'
import FooterParts from './parts/FooterParts.vue'
import MenuParts from './parts/MenuParts.vue'

/** デフォルトレイアウト */
export default defineComponent({
  /** 名称 */
  name: 'default-layout',
  /** コンポーネント */
  components: { HeaderParts, FooterParts, MenuParts },
  /** プロパティ */
  props: {},
  /** セットアップスクリプト */
  setup(props, context) {}
})
</script>
