<template>
  <div class="modal fade" :id="id" tabindex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true">
    <div :class="{ 'modal-dialog': true, center: center }" :style="getStyle()">
      <div class="modal-content">
        <div
          :class="{ 'modal-header': true, compact: !showClose }"
          :style="{ background: headerBackgroundColor, color: headerColor }"
        >
          <slot name="header">
            <slot name="header-title">
              <h6 class="modal-title">{{ title }}&nbsp;</h6>
            </slot>
            <button v-if="showClose" type="button" class="btn-close" data-dismiss="modal" aria-label="Close">
              <i class="glyphicon glyphicon-remove"></i>
            </button>
          </slot>
        </div>
        <slot name="body">
          <div class="modal-body">
            <div class="wrap">
              <slot />
            </div>
          </div>
        </slot>
        <div class="modal-footer">
          <slot name="footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" role="button">閉じる</button>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

/** 基底ダイアログ */
export default defineComponent({
  /** 名称 */
  name: 'base-dialog',
  /** プロパティ */
  props: {
    /** ID */
    id: { type: String, default: 'baseDialog', required: true },
    /** タイトル */
    title: { type: String, default: '' },
    /** 閉じるボタン表示 */
    showClose: { type: Boolean, default: true },
    /** 中央表示 */
    center: { type: Boolean, default: false },
    /** ヘッダー背景色 */
    headerBackgroundColor: { type: String, default: undefined },
    /** ヘッダー文字色 */
    headerColor: { type: String, default: undefined },
    /** 幅 */
    width: { type: String, default: undefined }
  },
  /** セットアップ */
  setup(prop, context) {},
  /** マウントイベント */
  mounted() {
    $('#' + this.id).on('hide.bs.modal', function (event) {})
  },
  /** 関数定義 */
  methods: {
    /** スタイル取得 */
    getStyle() {
      // スタイル定義
      const style: any = {}
      // 幅指定有無
      if (this.width) {
        // 幅追加
        style['width'] = this.width
      }
      return style
    }
  }
})
</script>
<style lang="stylus" scoped>
.modal-dialog.center
  margin-top 20%
.modal-body
  padding 15px 0px
.modal-header
  border-radius 4px 4px 0px 0px
.modal-header.compact
  padding 8px 15px
</style>
