<template>
  <slot />
</template>
<script lang="ts">
import { defineComponent } from 'vue'

/** 公開ページレイアウト */
export default defineComponent({
  /** 名称 */
  name: 'public-layout',
  /** コンポーネント */
  components: {},
  /** セットアップスクリプト */
  setup(props, context) {}
})
</script>
