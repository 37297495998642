<template>
  <default-layout>
    <form role="form" class="form-horizontal" @submit.prevent>
      <div class="row ctm_margin-b-no ctm_margin-t-10">
        <div class="col-md-12">
          <ol class="breadcrumb ctm_margin-b-no">
            <li><router-link :to="{ name: 'top' }">ホーム</router-link></li>
            <li class="active">アカウント情報変更</li>
          </ol>
        </div>
      </div>
      <div class="page-header">
        <h1 class="h3"><i class="glyphicon glyphicon-cog"></i> アカウント情報変更</h1>
      </div>
      <h2 class="h4 content-row-title">アカウント情報</h2>
      <div class="">
        <div class="form-group">
          <div class="clearfix">
            <label for="adminPass" class="control-label col-md-2">
              <strong class="h6">ログインID</strong> <span class="label label-danger">必須</span>
            </label>
            <div class="col-md-5">
              <div class="ctm_margin-b-10">
                <input type="text" class="form-control" id="loginId" :placeholder="parameter.loginId" disabled />
              </div>
              <div class="d-block">
                <span>※ログインIDの変更はできません。</span>
              </div>
            </div>
          </div>
        </div>
        <!-- form-group -->
        <div class="form-group">
          <div class="clearfix">
            <label for="adminPass" class="control-label col-md-2">
              <strong class="h6">パスワード</strong>
            </label>
            <div class="col-md-5">
              <div class="ctm_margin-b-10">
                <input
                  type="text"
                  class="form-control"
                  id="password"
                  placeholder="この画面からは変更できません"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
        <!-- form-group -->
        <div class="form-group">
          <div class="clearfix">
            <div class="ctm_block">
              <label for="adminPass" class="control-label col-md-2">
                <strong class="h6">ユーザー名</strong> <span class="label label-danger">必須</span>
              </label>
              <div class="col-md-5">
                <input type="text" class="form-control" id="userName" v-model="parameter.userName" />
                <p v-if="errors.userName" class="input-group valid-error" v-html="errors.userName" />
              </div>
            </div>
          </div>
        </div>
        <!-- form-group -->
        <div class="">
          <div class="form-group">
            <div class="clearfix">
              <div class="col-md-7">
                <button type="submit" class="btn btn-success btn-block center-block" @click="onUpdate">
                  <i class="glyphicon glyphicon-edit"></i> 変更する
                </button>
              </div>
            </div>
          </div>
          <!-- form-group -->
        </div>
      </div>
    </form>
  </default-layout>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { BaseView } from '@/components/view'
import { DefaultLayout } from '../../components/layout'
import { ApiService } from '@/modules/services/ApiService'
import { BaseApiRequest } from '@/modules/types'
import { Constants } from '@/modules/constants'
import { loginInfo, LoginState } from '@/store/LoginInfo'

/** アカウント情報変更画面 */
export default defineComponent({
  /** 名称 */
  name: 'update-user',
  /** 継承元指定 */
  extends: BaseView,
  /** コンポーネント */
  components: {
    DefaultLayout
  },
  /** データ定義 */
  data: () => ({
    /** ユーザーID */
    userId: '',
    /** パラメータ */
    parameter: {
      /** ユーザー種別 */
      userType: '',
      /** ユーザー名 */
      userName: '',
      /** ログインID */
      loginId: '',
      /** パスワード */
      password: '',
      /** 活性フラグ */
      isActive: '1'
    },
    errors: {
      /** ユーザー名 */
      userName: ''
    }
  }),
  /** セットアップスクリプト */
  setup(props, context) {},
  /** 関数定義 */
  methods: {
    /** 初期化処理 */
    async initialize() {
      // ユーザーID取得
      this.userId = loginInfo.userId

      this.setView('アカウント情報変更', 'update-user')
      // 初期検索
      await this.loadData()
    },
    /** データ読み込み */
    async loadData() {
      try {
        // ローディング開始
        this.showLoading()

        // API定義
        const api: ApiService = new ApiService('/user/view')
        // POSTデータ定義
        const postData: BaseApiRequest = new BaseApiRequest({ userId: this.userId })
        // データ取得処理
        const response = await api.post(postData)

        // 処理結果判定
        if (response.statusCode == Constants.Api.ResponseCode.OK) {
          // 処理成功
          // ユーザーデータ取得
          const userData = response.responseData
          // パラメータに設定
          this.parameter.userType = userData.user_type
          this.parameter.userName = userData.user_name
          this.parameter.loginId = userData.login_id
          this.parameter.password = userData.password
          this.parameter.isActive = String(userData.is_effective)
        } else {
          // 取得失敗
          this.showWarning('データの読み込みに失敗しました', '初期化失敗')
        }
      } finally {
        // ローディング解除
        this.hideLoading()
      }
    },
    /** 更新処理 */
    async onUpdate() {
      // 入力チェック
      const checked = await this.inputCheck()
      // チェック結果判定
      if (checked) {
        // 登録確認
        this.showConfirm(
          '更新します<br />よろしいですか？',
          '更新確認',
          async (response: Constants.Message.ResponseCode) => {
            // 応答判定
            if (response == Constants.Message.ResponseCode.YES) {
              // 登録処理
              try {
                // ローディング開始
                this.showLoading()
                // API定義
                const api: ApiService = new ApiService('/user/regist')
                // POSTデータ定義
                const postData: BaseApiRequest = new BaseApiRequest({
                  id: this.userId,
                  userType: this.parameter.userType,
                  userName: this.parameter.userName,
                  loginId: this.parameter.loginId,
                  password: this.parameter.password,
                  isEffective: this.parameter.isActive
                })
                // 登録処理
                const response = await api.post(postData)
                // 結果判定
                if (response.statusCode == Constants.Api.ResponseCode.OK) {
                  // 正常終了
                  this.showInformation('更新しました', '更新完了', async () => {
                    // ログイン情報再設定
                    const loginState: LoginState = {
                      userId: loginInfo.userId,
                      loginId: loginInfo.loginId,
                      userName: this.parameter.userName,
                      userType: loginInfo.userType,
                      identityKey: loginInfo.identityKey,
                      expiredPassword: loginInfo.expiredPassword,
                      isEffective: loginInfo.isEffective,
                      lastLoginAt: loginInfo.lastLoginAt
                    }
                    // ログイン情報更新
                    loginInfo.login(loginState)
                  })
                } else {
                  // エラーメッセージ表示
                  this.showWarning(response.statusMessage, '更新失敗')
                }
              } finally {
                // ローディング解除
                this.hideLoading()
              }
            }
          }
        )
      }
    },
    /** 入力チェック */
    async inputCheck() {
      let check = true
      const userNameErrors: string[] = []
      if (!this.parameter.userName) {
        userNameErrors.push('ユーザー名を入力してください')
        check = false
      }
      this.errors.userName = userNameErrors.length > 0 ? userNameErrors.join('<br />') : ''
      return check
    }
  }
})
</script>
<style lang="stylus" scoped>
.valid-error
  margin-bottom 0px !important
</style>
