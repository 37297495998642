import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  RouteLocationNormalized,
  NavigationGuardNext
} from 'vue-router'
import { loginInfo } from '@/store/LoginInfo'
import { pageInfo } from '@/store/PageInfo'

import Login from '../views/public/Login.vue'
import Top from '../views/Top/Top.vue'
import WorkList from '../views/works/WorkList.vue'
import RequestList from '../views/request/RequestList.vue'
import RequestRegist from '../views/request/RequestRegist.vue'
import DeliveryList from '../views/delivery/DeliveryList.vue'
import TaskList from '../views/task/TaskList.vue'
import PrintingList from '../views/printing/PrintingList.vue'
import AdminList from '../views/account/AdminList.vue'
import AdminRegist from '../views/account/AdminRegist.vue'
import UserList from '../views/account/UserList.vue'
import UserRegist from '../views/account/UserRegist.vue'
import UpdateUser from '../views/settings/UpdateUser.vue'
import ChangePassword from '../views/settings/ChangePassword.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'login',
    component: Login
  },
  {
    path: '/home',
    name: 'top',
    component: Top
  },
  {
    path: '/work/:workDate?',
    name: 'work-list',
    component: WorkList
  },
  {
    path: '/request',
    name: 'request-list',
    component: RequestList
  },
  {
    path: '/request/add',
    name: 'request-regist',
    meta: { mode: 'add' },
    component: RequestRegist
  },
  {
    path: '/request/edit',
    name: 'request-edit',
    meta: { mode: 'edit' },
    component: RequestRegist
  },
  {
    path: '/delivery',
    name: 'delivery-list',
    component: DeliveryList
  },
  {
    path: '/task',
    name: 'task-list',
    component: TaskList
  },
  {
    path: '/printing',
    name: 'printing-list',
    component: PrintingList
  },
  {
    path: '/admin',
    name: 'admin-list',
    component: AdminList
  },
  {
    path: '/admin/add',
    name: 'admin-regist',
    meta: { mode: 'add' },
    component: AdminRegist
  },
  {
    path: '/admin/edit',
    name: 'admin-edit',
    meta: { mode: 'edit' },
    component: AdminRegist
  },
  {
    path: '/user',
    name: 'user-list',
    component: UserList
  },
  {
    path: '/user/add',
    name: 'user-regist',
    meta: { mode: 'add' },
    component: UserRegist
  },
  {
    path: '/user/edit',
    name: 'user-edit',
    meta: { mode: 'edit' },
    component: UserRegist
  },
  {
    path: '/settings/account',
    name: 'update-user',
    component: UpdateUser
  },
  {
    path: '/settings/password',
    name: 'change-password',
    component: ChangePassword
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

/** ログインなし接続可能画面 */
const allowNoLogin = ['login']

/** ページ遷移前イベント */
router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  // メニューを閉じる
  pageInfo.toggleMenu(false)
  // 404判定
  if (!to.name) {
    // ログアウト
    loginInfo.logout()
    // トップページへリダイレクト
    next({ path: '/' })
  }
  // ログイン有無
  if (!loginInfo.userId && allowNoLogin.indexOf(String(to.name)) < 0) {
    // トップページへリダイレクト
    next({ path: '/' })
  }
  window.scrollTo({ top: 0 })
  // ページ遷移
  next()
  const fromDefault =
    from && from.matched && from.matched.length > 0 && from.matched[0].components
      ? from.matched[0].components.default
      : undefined
  const toDefault =
    to && to.matched && to.matched.length > 0 && to.matched[0].components ? to.matched[0].components.default : undefined
  // 遷移元・遷移先有無
  if (fromDefault && toDefault && fromDefault.name == toDefault.name) {
    // リロード
    router.go(0)
  }
})

export default router
