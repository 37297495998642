<template>
  <default-layout>
    <form role="form" class="form-horizontal" @submit.prevent>
      <div class="row ctm_margin-b-no ctm_margin-t-10">
        <div class="col-md-12">
          <ol class="breadcrumb ctm_margin-b-no">
            <li><router-link :to="{ name: 'top' }">ホーム</router-link></li>
            <li><router-link :to="{ name: 'admin-list' }">システム管理者</router-link></li>
            <li class="active">システム管理者｜{{ mode == 'edit' ? '編集' : '新規登録' }}</li>
          </ol>
        </div>
      </div>
      <div class="page-header">
        <h1 class="h3">
          <i class="glyphicon glyphicon-user"></i> システム管理者｜{{ mode == 'edit' ? '編集' : '新規登録' }}
        </h1>
      </div>
      <h2 class="h4 content-row-title">アカウント情報</h2>
      <div class="">
        <div class="form-group">
          <div class="clearfix">
            <div class="ctm_block">
              <label for="adminPass" class="control-label col-md-2">
                <strong class="h6">ユーザー名</strong> <span class="label label-danger">必須</span>
              </label>
              <div class="col-md-5">
                <input type="text" class="form-control" id="userName" v-model="parameter.userName" />
                <p v-if="errors.userName" class="input-group valid-error" v-html="errors.userName" />
              </div>
            </div>
          </div>
        </div>
        <!-- form-group -->
        <div class="form-group">
          <div class="clearfix">
            <label for="adminPass" class="control-label col-md-2">
              <strong class="h6">ログインID</strong> <span class="label label-danger">ユニーク</span>
            </label>
            <div class="col-md-5">
              <div class="ctm_margin-b-10">
                <input type="text" class="form-control" id="loginId" v-model="parameter.loginId" />
              </div>
              <span class="">※半角英数の小文字で入力してください。</span>
              <p v-if="errors.loginId" class="input-group valid-error" v-html="errors.loginId" />
            </div>
          </div>
        </div>
        <!-- form-group -->
        <div class="form-group">
          <div class="clearfix">
            <label for="adminPass" class="control-label col-md-2">
              <strong class="h6">パスワード</strong> <span class="label label-danger">必須</span>
            </label>
            <div class="col-md-5">
              <div class="ctm_margin-b-10">
                <input type="password" class="form-control" id="password" v-model="parameter.password" />
              </div>
              <span class="">※半角英数を6文字以上入力してください。</span>
              <p v-if="errors.password" class="input-group valid-error" v-html="errors.password" />
            </div>
          </div>
        </div>
        <!-- form-group -->
        <div class="form-group">
          <div class="">
            <label for="url1" class="control-label col-md-2">
              <strong class="h6">ログイン操作</strong> <span class="label label-danger">必須</span>
            </label>
            <div class="col-md-5">
              <div class="radio">
                <label for="display_login_1" class="">
                  <input type="radio" id="display_login_1" name="isActive" value="1" v-model="parameter.isActive" />
                  可能
                </label>
              </div>
              <div class="radio">
                <label for="display_login_2" class="">
                  <input type="radio" id="display_login_2" name="isActive" value="0" v-model="parameter.isActive" />
                  一時停止
                </label>
              </div>
            </div>
          </div>
        </div>
        <!-- form-group -->
        <div class="form-group">
          <div class="col-md-2"></div>
          <div class="col-md-10">
            <button v-if="mode == 'add'" type="submit" class="btn btn-success" @click="onSave">
              <i class="glyphicon glyphicon-pencil"></i> 新規登録する
            </button>
            <button type="submit" class="btn" @click="onCancel">
              <i class="glyphicon glyphicon-remove"></i> キャンセル
            </button>
            <button v-if="mode == 'edit'" type="submit" class="btn btn-success" @click="onUpdate">
              <i class="glyphicon glyphicon-edit"></i> 更新する
            </button>
          </div>
        </div>
        <!-- form-group -->
      </div>
    </form>
  </default-layout>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { BaseView } from '@/components/view'
import { DefaultLayout } from '../../components/layout'
import { pageInfo } from '@/store/PageInfo'
import { ApiService } from '@/modules/services/ApiService'
import { BaseApiRequest } from '@/modules/types'
import { RegexpService } from '@/modules/services'
import { Constants } from '@/modules/constants'

/** システム管理者登録画面 */
export default defineComponent({
  /** 名称 */
  name: 'admin-regist',
  /** 継承元指定 */
  extends: BaseView,
  /** コンポーネント */
  components: {
    DefaultLayout
  },
  /** データ定義 */
  data: () => ({
    /** モード */
    mode: 'add',
    /** ユーザーID */
    userId: '',
    /** パラメータ */
    parameter: {
      /** ユーザー種別 */
      userType: '99',
      /** ユーザー名 */
      userName: '',
      /** ログインID */
      loginId: '',
      /** パスワード */
      password: '',
      /** 活性フラグ */
      isActive: '1'
    },
    errors: {
      /** ユーザー名 */
      userName: '',
      /** ログインID */
      loginId: '',
      /** パスワード */
      password: ''
    }
  }),
  /** セットアップスクリプト */
  setup(props, context) {},
  /** 関数定義 */
  methods: {
    /** 初期化処理 */
    async initialize() {
      /** モード取得 */
      this.mode = this.$route.meta.mode ? String(this.$route.meta.mode) : 'add'
      // ユーザーID取得
      this.userId = pageInfo.parameter ? pageInfo.parameter.userId : ''

      // View定義
      if (this.mode == 'edit') {
        this.setView('システム管理者｜編集', 'admin-regist')
        // データ読み込み
        await this.loadData()
      } else {
        this.setView('システム管理者｜新規登録', 'admin-regist')
      }
    },
    /** データ読み込み */
    async loadData() {
      try {
        // ローディング開始
        this.showLoading()

        // API定義
        const api: ApiService = new ApiService('/user/view')
        // POSTデータ定義
        const postData: BaseApiRequest = new BaseApiRequest({ userId: this.userId })
        // データ取得処理
        const response = await api.post(postData)

        // 処理結果判定
        if (response.statusCode == Constants.Api.ResponseCode.OK) {
          // 処理成功
          // ユーザーデータ取得
          const userData = response.responseData
          // パラメータに設定
          this.parameter.userName = userData.user_name
          this.parameter.loginId = userData.login_id
          this.parameter.password = userData.password
          this.parameter.isActive = String(userData.is_effective)
        } else {
          // 取得失敗
          this.showWarning('データの読み込みに失敗しました', '初期化失敗')
        }
      } finally {
        // ローディング解除
        this.hideLoading()
      }
    },
    /** 登録処理 */
    async onSave() {
      // 入力チェック
      const checked = await this.inputCheck()
      // チェック結果判定
      if (checked) {
        // 登録確認
        this.showConfirm(
          '登録します<br />よろしいですか？',
          '登録確認',
          async (response: Constants.Message.ResponseCode) => {
            // 応答判定
            if (response == Constants.Message.ResponseCode.YES) {
              // 登録処理
              try {
                // ローディング開始
                this.showLoading()
                // 活性状態取得
                const isAdctive = $('#display_login_1').prop('checked') ? 1 : 0
                // API定義
                const api: ApiService = new ApiService('/user/regist')
                // POSTデータ定義
                const postData: BaseApiRequest = new BaseApiRequest({
                  id: '',
                  userType: this.parameter.userType,
                  userName: this.parameter.userName,
                  loginId: this.parameter.loginId,
                  password: this.parameter.password,
                  isEffective: isAdctive
                })
                // 登録処理
                const response = await api.post(postData)
                // 結果判定
                if (response.statusCode == Constants.Api.ResponseCode.OK) {
                  // 正常終了
                  this.showInformation('登録しました', '登録完了', async () => {
                    // 一覧へ遷移
                    this.goto('admin-list')
                  })
                } else {
                  // エラーメッセージ表示
                  this.showWarning(response.statusMessage, '登録失敗')
                }
              } finally {
                // ローディング解除
                this.hideLoading()
              }
            }
          }
        )
      }
    },
    /** 更新処理 */
    async onUpdate() {
      // 入力チェック
      const checked = await this.inputCheck()
      // チェック結果判定
      if (checked) {
        // 登録確認
        this.showConfirm(
          '更新します<br />よろしいですか？',
          '更新確認',
          async (response: Constants.Message.ResponseCode) => {
            // 応答判定
            if (response == Constants.Message.ResponseCode.YES) {
              // 登録処理
              try {
                // ローディング開始
                this.showLoading()
                // 活性状態取得
                const isAdctive = $('#display_login_1').prop('checked') ? 1 : 0
                // API定義
                const api: ApiService = new ApiService('/user/regist')
                // POSTデータ定義
                const postData: BaseApiRequest = new BaseApiRequest({
                  id: this.userId,
                  userType: this.parameter.userType,
                  userName: this.parameter.userName,
                  loginId: this.parameter.loginId,
                  password: this.parameter.password,
                  isEffective: isAdctive
                })
                // 登録処理
                const response = await api.post(postData)
                // 結果判定
                if (response.statusCode == Constants.Api.ResponseCode.OK) {
                  // 正常終了
                  this.showInformation('更新しました', '更新完了', async () => {
                    // 一覧へ遷移
                    this.goto('admin-list')
                  })
                } else {
                  // エラーメッセージ表示
                  this.showWarning(response.statusMessage, '更新失敗')
                }
              } finally {
                // ローディング解除
                this.hideLoading()
              }
            }
          }
        )
      }
    },
    /** 入力チェック */
    async inputCheck() {
      let check = true
      const userNameErrors: string[] = []
      const loginIdErrors: string[] = []
      const passwordErrors: string[] = []
      if (!this.parameter.userName) {
        userNameErrors.push('ユーザー名を入力してください')
        check = false
      }
      if (!this.parameter.loginId) {
        loginIdErrors.push('ログインIDを入力してください')
        check = false
      } else if (!RegexpService.isCheck('[a-z0-9]+$', this.parameter.loginId)) {
        loginIdErrors.push('ログインIDは半角英数小文字で入力してください')
        check = false
      }
      if (!this.parameter.password) {
        passwordErrors.push('パスワードを入力してください')
        check = false
      } else if (!RegexpService.isCheck('[a-z0-9]+$', this.parameter.password)) {
        passwordErrors.push('パスワードは半角英数小文字で入力してください')
        check = false
      } else if (this.parameter.password.length < 6) {
        passwordErrors.push('パスワードは6文字以上で入力してください')
        check = false
      }
      this.errors.userName = userNameErrors.length > 0 ? userNameErrors.join('<br />') : ''
      this.errors.loginId = loginIdErrors.length > 0 ? loginIdErrors.join('<br />') : ''
      this.errors.password = passwordErrors.length > 0 ? passwordErrors.join('<br />') : ''
      return check
    },
    /** キャンセル処理 */
    async onCancel() {
      // 一覧へ遷移
      this.goto('admin-list')
    }
  }
})
</script>
<style lang="stylus" scoped>
.valid-error
  margin-bottom 0px !important
</style>
