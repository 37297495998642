<template>
  <default-layout>
    <div class="row ctm_margin-b-no ctm_margin-t-10">
      <div class="col-md-12">
        <ol class="breadcrumb ctm_margin-b-no">
          <li><router-link :to="{ name: 'top' }">ホーム</router-link></li>
          <li class="active">納品予定表</li>
        </ol>
      </div>
    </div>
    <h1 class="page-header h3"><i class="glyphicon glyphicon-flag"></i> 納品予定表</h1>
    <div class="page-header">
      <div class="row">
        <div class="col-md-4">
          <router-link :to="{ name: 'request-regist' }" class="btn btn-success btn-block" role="button">
            <i class="glyphicon glyphicon-pencil"></i> 新規作成 or インポート
          </router-link>
        </div>
      </div>
    </div>
    <div class="page-header">
      <div class="row">
        <div class="col-md-12">
          <div v-for="(schedule, dateIndex) in lists" :key="dateIndex" class="table-responsive">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th class="col-xs-2 text-center">{{ $filters.toMonthDate(schedule.date) }}</th>
                  <th class="col-xs-1 text-center">かず</th>
                  <th
                    v-for="(task, index) in taskItems"
                    :key="index"
                    class="col-xs-1 text-center"
                    v-html="task.value"
                  />
                </tr>
              </thead>
              <tbody>
                <tr v-for="(deliverInfo, row) in schedule.deliverInfos" :key="row">
                  <td class="text-center">
                    <a
                      href=""
                      @click="
                        (e) => {
                          e.preventDefault()
                          onEdit(deliverInfo.id)
                          return
                        }
                      "
                    >
                      {{ deliverInfo.magazine_name }}<i class="glyphicon glyphicon-pencil"></i>
                    </a>
                  </td>
                  <td class="text-center">{{ $filters.toNumberFormat(deliverInfo.amount) }}</td>
                  <td v-for="(task, index) in taskItems" :key="index" class="text-center">
                    {{
                      deliverInfo.workInfos[task.code]
                        ? $filters.toMonthDateShort(deliverInfo.workInfos[task.code].work_date)
                        : ''
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </default-layout>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { BaseView } from '@/components/view'
import { DefaultLayout } from '../../components/layout'
import { ApiService } from '@/modules/services/ApiService'
import { BaseApiRequest } from '@/modules/types'
import { Constants } from '@/modules/constants'

/** 納品予定表画面 */
export default defineComponent({
  /** 名称 */
  name: 'delivery-list',
  /** 継承元指定 */
  extends: BaseView,
  /** コンポーネント */
  components: {
    DefaultLayout
  },
  /** データ定義 */
  data: () => ({
    /** 一覧データ */
    lists: Array<any>(),
    /** タスク項目 */
    taskItems: Array<any>()
  }),
  /** セットアップスクリプト */
  setup(props, context) {},
  /** 関数定義 */
  methods: {
    /** 初期化処理 */
    async initialize() {
      // View定義
      this.setView('納品予定表', 'delivery-list')
      // 検索処理
      await this.loadData(false)
    },
    /** 検索処理 */
    async loadData(showAlert: boolean): Promise<void> {
      try {
        // ローディング開始
        this.showLoading()

        // リスト初期化
        this.lists = []
        this.taskItems = []

        // API定義
        const api: ApiService = new ApiService('/deliver/schedule')

        // POSTデータ定義
        const postData: BaseApiRequest = new BaseApiRequest({ deliverDateFrom: new Date().ToString() })

        // 作業予定情報取得
        const response = await api.post(postData)

        // 結果判定
        if (response.statusCode == Constants.Api.ResponseCode.OK) {
          console.log(response.responseData)
          // 作業予定情報定義
          const deliverSchedules = response.responseData.deliverInfos
          const dateKeys: string[] = Object.keys(deliverSchedules)
          const taskItems = response.responseData.taskItems
          // タスク項目追加
          for (let i = 0; i < taskItems.length; i++) {
            const attr = JSON.parse(String(taskItems[i].attr_03))
            this.taskItems.push({ code: attr.GRP, value: attr.GRP_TITLE })
          }
          // リストに追加
          for (let i = 0; i < dateKeys.length; i++) {
            this.lists.push({ date: dateKeys[i], deliverInfos: deliverSchedules[dateKeys[i]] })
          }
        }

        // メッセージ表示有無
        if (showAlert && this.lists.length == 0) {
          // 対象データなし
          this.showInformation('対象データがありません', '検索結果')
        }
      } finally {
        // ローディング終了
        this.hideLoading()
      }
    },
    /** 編集処理 */
    onEdit(id: string) {
      // 編集ページへ遷移
      this.goto('request-edit', { requestId: id })
    }
  }
})
</script>
