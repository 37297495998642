import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

export default createStore({
  state: { login: {}, page: {}, message: {} },
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
  plugins: [
    createPersistedState({
      key: 'app',
      storage: window.sessionStorage,
      overwrite: true
    })
  ]
})
