<template>
  <default-layout>
    <div class="row ctm_margin-b-no ctm_margin-t-10">
      <div class="col-md-12">
        <ol class="breadcrumb ctm_margin-b-no">
          <li><router-link :to="{ name: 'top' }">ホーム</router-link></li>
          <li class="active">印刷工程用</li>
        </ol>
      </div>
    </div>
    <h1 class="page-header h3"><i class="glyphicon glyphicon-flag"></i> 印刷工程用</h1>
    <div class="page-header">
      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th class="col-xs-2 text-center">印刷締切</th>
                  <th class="col-xs-2 text-center">印刷完了日</th>
                  <th class="col-xs-4 text-center">誌名</th>
                  <th class="col-xs-2 text-center">完了日変更</th>
                  <th class="col-xs-2 text-center">印刷完了</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(task, index) in lists" :key="index">
                  <td class="text-center">{{ $filters.toMonthDate(task.deliverDate) }}</td>
                  <td v-if="!task.isComplete" class="text-center date_input">
                    <date-control v-model="task.completeDate" />
                  </td>
                  <td v-else class="text-center">
                    {{ $filters.toMonthDate(task.completeDate) }}
                  </td>
                  <td class="text-left">
                    <a href="" @click="onEdit(task.deliverInfoId)">{{ task.magazineName }}</a>
                    <i class="glyphicon glyphicon-pencil"></i>
                  </td>
                  <td class="text-center">
                    <button
                      type="button"
                      class="btn btn-primary"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="完了日変更"
                      :disabled="task.isComplete"
                      @click="onUpdateCompleteDate(task.deliverInfoId, task.printTaskInfoId, task.completeDate)"
                    >
                      <i class="glyphicon glyphicon-edit"></i> 完了日変更
                    </button>
                  </td>
                  <td class="text-center">
                    <button
                      type="button"
                      class="btn btn-success"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="印刷完了"
                      :disabled="task.isComplete"
                      @click="onUpdateComplete(task.printTaskInfoId, task.completeDate)"
                    >
                      <i class="glyphicon glyphicon-flag"></i> 印刷完了
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </default-layout>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { BaseView } from '@/components/view'
import { DefaultLayout } from '../../components/layout'
import { ApiService } from '@/modules/services/ApiService'
import { BaseApiRequest } from '@/modules/types'
import { Constants } from '@/modules/constants'
import { DateControl } from '@/components/controls'

/** 印刷工程用画面 */
export default defineComponent({
  /** 名称 */
  name: 'printing-list',
  /** 継承元指定 */
  extends: BaseView,
  /** コンポーネント */
  components: {
    DefaultLayout,
    DateControl
  },
  /** データ定義 */
  data: () => ({
    /** 一覧データ */
    lists: Array<{
      /** 納品情報ID */
      deliverInfoId: string
      /** 印刷タスク情報ID */
      printTaskInfoId: string
      /** 納品日 */
      deliverDate: string
      /** 完了日 */
      completeDate: string
      /** 誌名 */
      magazineName: string
      /** 完了フラグ */
      isComplete: boolean
    }>()
  }),
  /** セットアップスクリプト */
  setup(props, context) {},
  /** 関数定義 */
  methods: {
    /** 初期化処理 */
    async initialize() {
      // View定義
      this.setView('印刷工程用', 'printing-list')
      // 検索処理
      await this.loadData(false)
    },
    /** 検索処理 */
    async loadData(showAlert: boolean): Promise<void> {
      try {
        // ローディング開始
        this.showLoading()

        // リスト初期化
        this.lists = []

        // API定義
        const api: ApiService = new ApiService('/print-task/list')

        // POSTデータ定義
        const postData: BaseApiRequest = new BaseApiRequest({})

        // POST処理
        const response = await api.post(postData)

        // 検査結果判定
        if (response.statusCode == Constants.Api.ResponseCode.OK) {
          // 検索OK
          // 納品情報取得
          const deliverInfos: any[] = response.responseData.deliverInfos
          // 納品情報を処理する
          for (let i = 0; i < deliverInfos.length; i++) {
            const printTaskInfo = deliverInfos[i].PrintTaskInfos ? deliverInfos[i].PrintTaskInfos : {}

            // リストに追加
            this.lists.push({
              deliverInfoId: deliverInfos[i].id,
              printTaskInfoId: printTaskInfo.id,
              deliverDate: deliverInfos[i].deliver_date,
              completeDate: printTaskInfo.complete_date,
              magazineName: deliverInfos[i].magazine_name,
              isComplete: printTaskInfo.is_complete
            })
          }
        }

        // メッセージ表示有無
        if (showAlert && this.lists.length == 0) {
          // 対象データなし
          this.showInformation('対象データがありません', '検索結果')
        }
      } finally {
        // ローディング終了
        this.hideLoading()
      }
    },
    /** 編集処理 */
    onEdit(id: string) {
      // 編集ページへ遷移
      this.goto('request-edit', { requestId: id })
    },
    /** 完了日更新 */
    onUpdateCompleteDate(deliverInfoId: string, printTaskId: string, completeDate: string) {
      // 完了日入力有無
      if (!completeDate) {
        // 完了日未入力
        this.showWarning('印刷完了日が入力されていません', '完了日未入力')
        return
      }
      // 更新確認
      this.showConfirm(
        '印刷完了日を更新します<br />よろしいですか？',
        '更新確認',
        async (response: Constants.Message.ResponseCode) => {
          // 応答結果判定
          if (response == Constants.Message.ResponseCode.YES) {
            try {
              // ローディング開始
              this.showLoading()
              // API定義
              const api: ApiService = new ApiService('/print-task/change/complete-date')
              // POSTデータ
              const postData: BaseApiRequest = new BaseApiRequest({
                id: printTaskId,
                deliverInfoId: deliverInfoId,
                completeDate: completeDate
              })
              // POST処理
              const response = await api.post(postData)
              // 処理結果判定
              if (response.statusCode == Constants.Api.ResponseCode.OK) {
                // 更新完了
                this.showInformation('印刷完了日を更新しました', '更新完了', () => {
                  // 再読み込み
                  this.loadData(true)
                })
              } else {
                // 更新失敗
                this.showWarning('印刷完了日の更新に失敗しました', '更新失敗')
              }
            } finally {
              // ローディング解除
              this.hideLoading()
            }
          }
        }
      )
    },
    /** 印刷完了更新 */
    onUpdateComplete(printTaskId: string, completeDate: string) {
      // 完了日入力有無
      if (!completeDate) {
        // 完了日未入力
        this.showWarning('印刷完了日が入力されていません', '完了日未入力')
        return
      }
      // 更新確認
      this.showConfirm(
        '印刷完了にします<br />よろしいですか？',
        '更新確認',
        async (response: Constants.Message.ResponseCode) => {
          // 応答結果判定
          if (response == Constants.Message.ResponseCode.YES) {
            try {
              // ローディング開始
              this.showLoading()
              // API定義
              const api: ApiService = new ApiService('/print-task/change/complete')
              // POSTデータ
              const postData: BaseApiRequest = new BaseApiRequest({ id: printTaskId, isComplete: true })
              // POST処理
              const response = await api.post(postData)
              // 処理結果判定
              if (response.statusCode == Constants.Api.ResponseCode.OK) {
                // 更新完了
                this.showInformation('印刷完了しました', '更新完了', () => {
                  // 再読み込み
                  this.loadData(true)
                })
              } else {
                // 更新失敗
                this.showWarning('印刷完了に失敗しました', '更新失敗')
              }
            } finally {
              // ローディング解除
              this.hideLoading()
            }
          }
        }
      )
    }
  }
})
</script>
<style lang="stylus" scoped>
.date_input
  padding 0px
</style>
<style lang="stylus">
.printing-list
  .date_input
    .dp__input
      border none !important
      background transparent !important
</style>
