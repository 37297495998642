import { ComponentPublicInstance } from 'vue'
import { Constants } from '@/modules/constants'

/**
 * Viewサービスクラス
 */
export class ViewService {
  /** Vueクラス */
  protected vue?: ComponentPublicInstance
  /** コンストラクタ */
  constructor(vue?: ComponentPublicInstance) {
    this.vue = vue
  }
  /** ローディング表示 */
  public showLoading() {
    // ローディング表示
    $('#overlay').fadeIn(300)
  }
  /** ローディング非表示 */
  public hideLoading() {
    // ローディング非表示
    $('#overlay').fadeOut(300)
  }
  /**
   * 情報メッセージ表示
   * @param message メッセージ
   * @param title タイトル
   * @param callback コールバック関数
   */
  public showInformation(message: string, title?: string, callback?: () => void) {
    // メッセージダイアログ取得
    const dialog: any =
      this.vue && this.vue.$root && this.vue.$root.$refs ? this.vue.$root.$refs.informationDialog : undefined

    if (dialog) {
      // メッセージ表示
      dialog.show(message, title, callback ? callback : () => {})
    }
  }
  /**
   * 警告メッセージ表示
   * @param message メッセージ
   * @param title タイトル
   * @param callback コールバック関数
   */
  public showWarning(message: string, title?: string, callback?: () => void) {
    // メッセージダイアログ取得
    const dialog: any = this.vue && this.vue.$root && this.vue.$root.$refs ? this.vue.$root.$refs.warnDialog : undefined
    if (dialog) {
      // メッセージ表示
      dialog.show(message, title, callback ? callback : () => {})
    }
  }
  /**
   * 確認メッセージ表示
   * @param message メッセージ
   * @param title タイトル
   * @param response 応答処理
   * @param isAlert アラート表示
   */
  public showConfirm(
    message: string,
    title?: string,
    response?: (responseCode: Constants.Message.ResponseCode) => void
  ) {
    // メッセージダイアログ取得
    const dialog: any =
      this.vue && this.vue.$root && this.vue.$root.$refs ? this.vue.$root.$refs.confirmDialog : undefined
    if (dialog) {
      // メッセージ表示
      dialog.show(message, title, response)
    }
  }
  /**
   * 質問メッセージ表示
   * @param message メッセージ
   * @param title タイトル
   * @param response 応答処理
   * @param isAlert アラート表示
   */
  public showQuestion(
    message: string,
    title?: string,
    response?: (responseCode: Constants.Message.ResponseCode) => void
  ) {
    // メッセージダイアログ取得
    const dialog: any =
      this.vue && this.vue.$root && this.vue.$root.$refs ? this.vue.$root.$refs.confirmDialog : undefined
    if (dialog) {
      // メッセージ表示
      dialog.showQuestion(message, title, response)
    }
  }
}
