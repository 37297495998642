import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_loading = _resolveComponent("loading")!
  const _component_information_dialog = _resolveComponent("information-dialog")!
  const _component_confirm_dialog = _resolveComponent("confirm-dialog")!
  const _component_warn_dialog = _resolveComponent("warn-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_view),
    _createVNode(_component_loading),
    _createVNode(_component_information_dialog, { ref: "informationDialog" }, null, 512),
    _createVNode(_component_confirm_dialog, { ref: "confirmDialog" }, null, 512),
    _createVNode(_component_warn_dialog, { ref: "warnDialog" }, null, 512)
  ], 64))
}