<template>
  <base-dialog :id="id" :title="title" headerBackgroundColor="#19B5FE" headerColor="#FFF" :showClose="false" center>
    <div class="message-body" v-html="message" />
    <template v-slot:footer>
      <button type="button" class="btn btn-primary" role="button" @click="onClickYes">はい</button>
      <button type="button" class="btn btn-secondary" role="button" @click="onClickNo">いいえ</button>
      <button v-if="showCancel" type="button" class="btn btn-default" role="button" @click="onClickCancel">
        キャンセル
      </button>
    </template>
  </base-dialog>
</template>

<script lang="ts">
import { Constants } from '@/modules/constants'
import { defineComponent, ref } from 'vue'
import { BaseDialog } from './'

/** 確認ダイアログ */
export default defineComponent({
  components: { BaseDialog },
  /** 名称 */
  name: 'confirm-dialog',
  /** プロパティ */
  props: {
    /** ID */
    id: { type: String, default: 'confirmDialog' }
  },
  /** セットアップ */
  setup(prop, context) {
    const title = ref('')
    const message = ref('')
    const callback = ref((response: Constants.Message.ResponseCode) => {})
    const showCancel = ref(false)
    return { title, message, callback, showCancel }
  },
  /** 関数定義 */
  methods: {
    /** 表示 */
    show(message: string, title: string, callback?: any) {
      // コンポーネント取得
      const dialog = $('#' + this.id) as any
      // ダイアログ有無
      if (dialog) {
        // パラメータセット
        this.title = title
        this.message = message
        if (callback) {
          this.callback = callback
        }
        this.showCancel = false
        // ダイアログ表示
        dialog.modal('show')
      }
    },
    /** 表示 */
    showQuestion(message: string, title: string, callback?: any) {
      // コンポーネント取得
      const dialog = $('#' + this.id) as any
      // ダイアログ有無
      if (dialog) {
        // パラメータセット
        this.title = title
        this.message = message
        if (callback) {
          this.callback = callback
        }
        this.showCancel = true
        // ダイアログ表示
        dialog.modal('show')
      }
    },
    /** YESボタンクリック */
    onClickYes() {
      // コンポーネント取得
      const dialog = $('#' + this.id) as any
      // ダイアログ有無
      if (dialog) {
        // ダイアログ非表示
        dialog.modal('hide')
        // コールバック実施
        this.callback(Constants.Message.ResponseCode.YES)
      }
    },
    /** NOボタンクリック */
    onClickNo() {
      // コンポーネント取得
      const dialog = $('#' + this.id) as any
      // ダイアログ有無
      if (dialog) {
        // ダイアログ非表示
        dialog.modal('hide')
        // コールバック実施
        this.callback(Constants.Message.ResponseCode.NO)
      }
    },
    /** CANCELボタンクリック */
    onClickCancel() {
      // コンポーネント取得
      const dialog = $('#' + this.id) as any
      // ダイアログ有無
      if (dialog) {
        // ダイアログ非表示
        dialog.modal('hide')
        // コールバック実施
        this.callback(Constants.Message.ResponseCode.CANCEL)
      }
    }
  }
})
</script>
