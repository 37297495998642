import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueCookies from 'vue-cookies'
import VueDatePicker from '@vuepic/vue-datepicker'

import { loadFonts } from './plugins/webfontloader'
import { Constants } from './modules/constants'

import 'jquery'

import '@/styl/init.styl'
import '@/modules/extentions'
import '@vuepic/vue-datepicker/dist/main.css'

loadFonts()

const app = createApp(App)
/** フィルター定義 */
app.config.globalProperties.$filters = {
  /** トリミング */
  trim(value: any): string {
    return String(value).trim()
  },
  /** 空白を改行に変換1 */
  whiteSpaceNreLine(value: any): string {
    // 入力チェック
    if (value !== undefined && value !== null && String(value).length > 0) {
      return String(value).replace(/\s+/g, '\r\n')
    }
    return String(value)
  },
  /** 改行コードを削除 */
  trimNewLine(value: any): string {
    // 入力チェック
    if (value !== undefined && value !== null && String(value).length > 0) {
      return String(value).replace(/\r?\n/g, '')
    }
    return value
  },
  /** 改行コードをBRタグに変換 */
  newLineToBr(value: any): string {
    // 入力チェック
    if (value !== undefined && value !== null && String(value).length > 0) {
      return String(value).replace(/\r?\n/g, '<br />')
    }
    return value
  },
  /** 日付変換 YYYY/MM/DD */
  toDate(value: any): string {
    // 入力有無
    if (value !== undefined && value !== null && String(value).length > 0) {
      // 日付の書式に変換
      const date = String(value).ToDate()
      return date ? date.ToString(Constants.Format.DateFormatType.YMD) : value
    }
    return value
  },
  /** 日時変換 YYYY/MM/DD hh:mm:ss */
  toDateTime(value: any): string {
    // 入力有無
    if (value !== undefined && value !== null && String(value).length > 0) {
      // 日付の書式に変換
      const date = String(value).ToDate()
      return date ? date.ToString(Constants.Format.DateFormatType.DATE_TIME) : value
    }
    return value
  },
  /** 日付変換 YYYY年MM月DD日 */
  toDateJP(value: any): string {
    // 入力有無
    if (value !== undefined && value !== null && String(value).length > 0) {
      // 日付の書式に変換
      const date = String(value).ToDate()
      return date ? date.ToString(Constants.Format.DateFormatType.YMD_JP) : value
    }
    return value
  },
  /** 日付変換 YYYY-MM-DD */
  toDateNone(value: any): string {
    // 入力有無
    if (value !== undefined && value !== null && String(value).length > 0) {
      // 日付の書式に変換
      const date = String(value).ToDate()
      return date ? date.ToString(Constants.Format.DateFormatType.NONE) : value
    }
    return value
  },
  /** 全角変換 */
  toFull(value: any): string {
    // 入力有無
    if (value !== undefined && value !== null && String(value).length > 0) {
      // 入力あり
      return String(value).replace(/[A-Za-z0-9]/g, function (s) {
        return String.fromCharCode(s.charCodeAt(0) + 0xfee0)
      })
    }
    return value
  },
  /** カンマ区切り */
  toNumberFormat(value: any): string {
    // 入力有無
    if (value !== undefined && value !== null && String(value).length > 0) {
      return Number(value).toLocaleString()
    } else if (value === undefined || value === null) {
      return ''
    } else {
      return String(value)
    }
  },
  /** パディング */
  padStart(value: any, length: number, char?: string): string {
    // 入力有無
    if (value !== undefined && value !== null && String(value).length > 0) {
      return String(value).padStart(length, char ? char : ' ')
    } else {
      return String(value)
    }
  },
  /** 省略 */
  omit(value: any, length: number, omitChar?: string): string {
    // 入力有無
    if (value !== undefined && value !== null && length > 0 && String(value).length > length) {
      return String(value).substring(0, length - 1) + (omitChar ? omitChar : '…')
    } else {
      return String(value)
    }
  },
  /* 作業日変換 */
  toWorkDate(value: any): string {
    // 入力有無
    if (value !== undefined && value !== null && String(value).length > 0) {
      // 日付の書式に変換
      const date = String(value).ToDate()
      return date ? date.ToString(Constants.Format.DateFormatType.WORK_LIST) : value
    }
    return value
  },
  /** 日付変換 M月D日 */
  toMonthDate(value: any): string {
    // 入力有無
    if (value !== undefined && value !== null && String(value).length > 0) {
      // 日付の書式に変換
      const date = String(value).ToDate()
      return date ? date.ToString(Constants.Format.DateFormatType.MONTH_DAY) : value
    }
    return value
  },
  /** 日付変換 M/D */
  toMonthDateShort(value: any): string {
    // 入力有無
    if (value !== undefined && value !== null && String(value).length > 0) {
      // 日付の書式に変換
      const date = String(value).ToDate()
      return date ? date.ToString(Constants.Format.DateFormatType.MONTH_DAY_SHORT) : value
    }
    return value
  }
}
/** エラーハンドリング */
app.config.errorHandler = (ex: unknown) => {
  console.log('global error:', ex)
}
app.component('DatePicker', VueDatePicker)
app.use(router).use(store).use(VueCookies, { expires: '365d' }).mount('#app')
