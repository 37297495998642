<template>
  <default-layout>
    <div class="row ctm_margin-b-no ctm_margin-t-10">
      <div class="col-md-12">
        <ol class="breadcrumb ctm_margin-b-no">
          <li><router-link :to="{ name: 'top' }">ホーム</router-link></li>
          <li class="active">システム管理者</li>
        </ol>
      </div>
    </div>
    <h1 class="page-header h3"><i class="glyphicon glyphicon-user"></i> システム管理者</h1>
    <div class="page-header">
      <p>
        <router-link :to="{ name: 'admin-regist' }" class="btn btn-success" role="button">
          <i class="glyphicon glyphicon-pencil"></i> 新規登録する
        </router-link>
      </p>
    </div>
    <div class="page-header">
      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th class="col-xs-1 text-center">ステータス</th>
                  <th class="col-xs-4 text-center">ユーザー名</th>
                  <th class="col-xs-2 text-center">ログインID</th>
                  <th class="col-xs-2 text-center">権限</th>
                  <th class="col-xs-3 text-center">編集</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, rowIndex) in lists" :key="rowIndex">
                  <td class="text-center">
                    <span v-if="data.isActive" class="badge badge-success d-block">利用中</span>
                    <span v-else class="badge d-block">停止中</span>
                  </td>
                  <td class="text-left">{{ data.userName }}</td>
                  <td class="text-center">{{ data.loginId }}</td>
                  <td class="text-center">{{ getUserType(data.userType) }}</td>
                  <td class="text-center">
                    <button
                      type="button"
                      class="btn btn-primary"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="編集"
                      @click="onEdit(data.id)"
                    >
                      <i class="glyphicon glyphicon-edit"></i> 編集
                    </button>
                    <button
                      type="button"
                      class="btn"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="削除"
                      @click="onDelete(data.id)"
                    >
                      <i class="glyphicon glyphicon-trash"></i> 削除
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-if="false" class="col-md-12 text-center ogn_pagination-box">
          <ul class="pagination">
            <li class="arrow">
              <a href="#"><i class="glyphicon glyphicon-chevron-left"></i></a>
            </li>
            <li class="active"><a href="#">1</a></li>
            <li><a href="#">2</a></li>
            <li><a href="#">3</a></li>
            <li><a href="#">4</a></li>
            <li><a href="#">5</a></li>
            <li class="arrow">
              <a href="#"><i class="glyphicon glyphicon-chevron-right"></i></a>
            </li>
          </ul>
          <p class="ogn_dsp-page">1-30件目 / 00件中</p>
        </div>
      </div>
    </div>
  </default-layout>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { BaseView } from '@/components/view'
import { DefaultLayout } from '../../components/layout'
import { ApiService } from '@/modules/services/ApiService'
import { BaseApiRequest } from '@/modules/types'
import { Constants } from '@/modules/constants'

/** システム管理者一覧画面 */
export default defineComponent({
  /** 名称 */
  name: 'admin-list',
  /** 継承元指定 */
  extends: BaseView,
  /** コンポーネント */
  components: {
    DefaultLayout
  },
  /** データ定義 */
  data: () => ({
    /** 一覧データ */
    lists: Array<{
      /** ID */
      id: string
      /** ユーザー種別 */
      userType: string
      /** ユーザー名 */
      userName: string
      /** ログインID */
      loginId: string
      /** パスワード */
      password: string
      /** 活性フラグ */
      isActive: boolean
    }>()
  }),
  /** セットアップスクリプト */
  setup(props, context) {},
  /** 関数定義 */
  methods: {
    /** 初期化処理 */
    async initialize() {
      // View定義
      this.setView('システム管理者｜一覧', 'admin-list')
      // 検索処理
      await this.loadData(false)
    },
    /** 検索処理 */
    async loadData(showAlert: boolean): Promise<void> {
      try {
        // ローディング開始
        this.showLoading()

        // リスト初期化
        this.lists = []

        // API定義
        const api: ApiService = new ApiService('/user/list')

        // POSTパラメータ
        const postData: BaseApiRequest = new BaseApiRequest({ userType: ['99'] })

        // POST処理
        const response = await api.post(postData)

        // 結果判定
        if (response.statusCode == Constants.Api.ResponseCode.OK) {
          // ユーザー情報取得
          const users: any[] = response.responseData
          for (let i = 0; i < users.length; i++) {
            // 一覧に追加
            this.lists.push({
              id: users[i].id,
              userType: users[i].user_type,
              userName: users[i].user_name,
              loginId: users[i].login_id,
              password: users[i].password,
              isActive: users[i].is_effective == 1
            })
          }
        }
        // メッセージ表示有無
        if (showAlert && this.lists.length == 0) {
          // 対象データなし
          this.showInformation('対象データがありません', '検索結果')
        }
      } finally {
        // ローディング終了
        this.hideLoading()
      }
    },
    /** ユーザー種別取得 */
    getUserType(userType: string) {
      return Constants.ListItem.AccountTypeItems.getText(userType)
    },
    /** 編集処理 */
    onEdit(id: string) {
      // 編集画面へ遷移
      this.goto('admin-edit', { userId: id })
    },
    /** 削除処理 */
    onDelete(id: string) {
      // 削除確認
      this.showConfirm(
        '削除します<br />よろしいですか？',
        '削除確認',
        async (response: Constants.Message.ResponseCode) => {
          // 応答結果判定
          if (response == Constants.Message.ResponseCode.YES) {
            try {
              // ローディング開始
              this.showLoading()
              // API定義
              const api: ApiService = new ApiService('/user/delete')
              // POSTデータ定義
              const postData: BaseApiRequest = new BaseApiRequest({ id: id })
              // 削除処理
              const response = await api.post(postData)
              // 処理結果判定
              if (response.statusCode == Constants.Api.ResponseCode.OK) {
                // 削除成功
                this.showInformation('削除しました', '削除完了', async () => {
                  // データ再読み込み
                  await this.loadData(true)
                })
              } else {
                // 削除失敗
                this.showWarning(response.statusMessage, '削除失敗')
              }
            } finally {
              // ローティング解除
              this.hideLoading()
            }
          }
        }
      )
    }
  }
})
</script>
