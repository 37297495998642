import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id", "name", "disabled"]
const _hoisted_2 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass({ 'no-label': !_ctx.label })
  }, [
    _withDirectives(_createElementVNode("input", {
      ref: "checkboxControl",
      class: "checkbox-control",
      type: "checkbox",
      id: _ctx.name,
      name: _ctx.name,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelValue) = $event)),
      disabled: _ctx.disabled
    }, null, 8, _hoisted_1), [
      [_vModelCheckbox, _ctx.modelValue]
    ]),
    _createElementVNode("label", {
      for: _ctx.name,
      class: "checkbox-control-label"
    }, _toDisplayString(_ctx.label), 9, _hoisted_2)
  ], 2))
}