import axios, { AxiosResponse } from 'axios'
import { BaseApiRequest, BaseApiResponse } from '@/modules/types'
import { Constants } from '@/modules/constants'
import FormData from 'form-data'
// import { loginModule } from '@/store/LoginModule'
import { loginInfo, LoginState } from '@/store/LoginInfo'

// デフォルトヘッダー情報
const defaultHeader = { 'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8' }
const defaultFileHeader = { 'Content-Type': 'multipart/form-data' }

/** GETパラメータインターフェース */
export interface GetParameter {
  /** パラメータキー */
  key: string
  /** パラメータ値 */
  value: any
}

/**
 * APIサービスクラス
 */
export class ApiService {
  // APIURL
  private _baseUrl = String(process.env.VUE_APP_API_BASE_URL)
  private _apiPath?: string = undefined

  /**
   * コンストラクタ
   * @param apiPath APIパス
   * @param baseUrl APIベースURL
   */
  public constructor(apiPath: string, baseUrl?: string) {
    // メンバ変数設定
    this._baseUrl = baseUrl ? baseUrl : this._baseUrl
    this._apiPath = apiPath ? apiPath : this._apiPath
  }

  /**
   * POST処理
   * @param param POSTパラメータ
   * @param apiPath APIパス
   */
  public async post<T extends BaseApiResponse>(
    param?: BaseApiRequest,
    files?: { [key: string]: File | Array<File> }
  ): Promise<T> {
    try {
      // APIベースURL作成
      const apiUrl: string = this._baseUrl + this._apiPath
      // 投稿データ
      const parameter = param ? param : new BaseApiRequest()

      // 共通項目設定
      parameter.append(
        'loginUserInfo',
        JSON.stringify({
          userId: String(loginInfo.userId),
          loginId: String(loginInfo.loginId),
          userName: String(loginInfo.userName),
          userType: String(loginInfo.userType)
        })
      )

      // POST処理
      let res: AxiosResponse
      const keys: string[] = Object.keys(parameter.keys)
      // 投稿データを追加
      for (let i = 0; i < keys.length; i++) {
        if ((parameter as any)[keys[i]] === undefined || (parameter as any)[keys[i]] === null) {
        } else if ((parameter as any)[keys[i]] instanceof Object) {
          // 投稿データを設定
          parameter.append(keys[i], JSON.stringify((parameter as any)[keys[i]]))
        } else {
          // 投稿データを設定
          parameter.append(keys[i], (parameter as any)[keys[i]])
        }
      }

      // ファイル指定有無
      if (files) {
        // ファイルキー取得
        const fileKeys: string[] = Object.keys(files)
        for (let i = 0; i < fileKeys.length; i++) {
          // 配列判定
          if (files[fileKeys[i]] instanceof Array) {
            const fileList: Array<File> = files[fileKeys[i]] as Array<File>
            if (fileList.length == 1) {
              // ファイルを設定
              parameter.append(fileKeys[i], fileList[0])
            } else {
              for (let j = 0; j < fileList.length; j++) {
                // ファイルを設定
                parameter.append(fileKeys[i], fileList[j])
              }
            }
          } else {
            // ファイルを設定
            parameter.append(fileKeys[i], files[fileKeys[i]] as Blob)
          }
        }
        // POST処理
        res = await axios.post(apiUrl, parameter, { headers: defaultFileHeader })
      } else {
        // 不要項目削除
        parameter.files = undefined
        res = await axios.post(apiUrl, parameter, { headers: defaultHeader })
      }
      // ステータスコード判定
      if (res.status == 200) {
        // 正常終了
        // 戻り値定義
        const response: T = new BaseApiResponse() as T
        // 結果をインポート
        await response.import(res.data)
        // 結果を返す
        return response
      } else {
        // APIエラー
        throw new Error('APIの呼び出しに失敗しました')
      }
    } catch (exception) {
      // 上位へthrow
      throw exception
    }
  }

  /**
   * GETURL取得
   * @param apiPath APIパス
   * @param param パラメータ
   */
  public static get(apiPath: string, param?: GetParameter[]): string {
    // パラメータリスト
    const parameters: string[] = []

    // 共通項目設定
    parameters.push(['userId', encodeURIComponent(loginInfo.userId)].join('='))
    parameters.push(
      ['requestAt', encodeURIComponent(new Date().ToString(Constants.Format.DateFormatType.DATE_TIME))].join('=')
    )

    // パラメータ有無
    if (param) {
      for (let i = 0; i < param.length; i++) {
        parameters.push([param[i].key, encodeURIComponent(String(param[i].value))].join('='))
      }
    }

    // URL作成
    return [String(process.env.VUE_APP_API_BASE_URL), apiPath, '/?', parameters.join('&')].join('')
  }
}
