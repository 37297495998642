<template>
  <nav role="navigation" class="navbar navbar-custom">
    <div class="container-fluid">
      <!-- Brand and toggle get grouped for better mobile display -->
      <div class="navbar-header">
        <button
          data-target="#bs-content-row-navbar-collapse-5"
          data-toggle="collapse"
          class="navbar-toggle"
          type="button"
        >
          <span class="sr-only">Toggle navigation</span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <router-link :to="{ name: 'top' }" class="navbar-brand">⽂唱堂｜タスク管理システム</router-link>
      </div>

      <!-- Collect the nav links, forms, and other content for toggling -->
      <div id="bs-content-row-navbar-collapse-5" class="collapse navbar-collapse">
        <ul class="nav navbar-nav navbar-right">
          <!-- <li class="active"><a href="//www.amu-miyazaki.com/" target="_blank">サイトを見る</a></li> -->
          <li class="dropdown">
            <a data-toggle="dropdown" class="dropdown-toggle" href="#">アカウント管理<b class="caret"></b></a>
            <ul role="menu" class="dropdown-menu">
              <li class="dropdown-header">アカウント管理</li>
              <li><router-link :to="{ name: 'update-user' }">アカウント情報変更</router-link></li>
              <li><router-link :to="{ name: 'change-password' }">パスワード変更</router-link></li>
              <li class="divider"></li>
              <li class="disabled logout"><a href="" @click="logout()">ログアウト</a></li>
            </ul>
          </li>
        </ul>
      </div>
      <!-- /.navbar-collapse -->
    </div>
    <!-- /.container-fluid -->
  </nav>
  <!--header-->
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { loginInfo } from '@/store/LoginInfo'

/**
 * ヘッダーパーツ
 */
export default defineComponent({
  /** 名称 */
  name: 'header-parts',
  /** プロパティ */
  props: {
    /** 高さ */
    height: { type: String, default: '48px' },
    /** 背景色 */
    color: { type: String, default: 'headerBackground' },
    /** 文字色 */
    textColor: { type: String, default: 'headerColor' },
    /** ロゴイメージ表示 */
    showLogo: { type: Boolean, default: true },
    /** タイトル表示 */
    showTitle: { type: Boolean, default: true },
    /** 画面名表示 */
    showScreenName: { type: Boolean, default: false },
    /** メニュー表示 */
    showMenu: { type: Boolean, default: true },
    /** タイトル */
    title: { type: String, default: undefined },
    /** サブタイトル */
    subTitle: { type: String, default: undefined },
    /** トップページリンク */
    topPage: { type: String, default: '' },
    /** メニューアイコン表示位置 */
    location: { type: String, default: 'right' }
  },
  /** セットアップスクリプト */
  setup(props, context) {},
  /** 関数定義 */
  methods: {
    /** ログアウト処理 */
    logout() {
      // ログアウト
      loginInfo.logout()
      // ログイン画面に遷移
      this.$router.push('/')
    }
  }
})
</script>
