import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_dialog = _resolveComponent("base-dialog")!

  return (_openBlock(), _createBlock(_component_base_dialog, {
    id: _ctx.id,
    title: _ctx.title,
    headerBackgroundColor: "#F9690E",
    headerColor: "#FFF",
    showClose: false,
    center: ""
  }, {
    footer: _withCtx(() => [
      _createElementVNode("button", {
        type: "button",
        class: "btn btn-secondary",
        role: "button",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClickClose && _ctx.onClickClose(...args)))
      }, "閉じる")
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: "message-body",
        innerHTML: _ctx.message
      }, null, 8, _hoisted_1)
    ]),
    _: 1
  }, 8, ["id", "title"]))
}