<template>
  <default-layout>
    <div class="row ctm_margin-b-no ctm_margin-t-10">
      <div class="col-md-12">
        <ol class="breadcrumb ctm_margin-b-no">
          <li><router-link :to="{ name: 'top' }">ホーム</router-link></li>
          <li class="active">依頼一覧</li>
        </ol>
      </div>
    </div>
    <h1 class="page-header h3"><i class="glyphicon glyphicon-flag"></i> 依頼一覧</h1>
    <div class="page-header">
      <div class="row">
        <div class="col-md-4">
          <a href="" class="btn btn-success btn-block" role="button">
            <i class="glyphicon glyphicon-upload"></i> XMLファイルを読み込む
          </a>
        </div>
        <div class="col-md-2">
          <router-link :to="{ name: 'request-regist' }" class="btn btn-success btn-block" role="button">
            <i class="glyphicon glyphicon-plus"></i> 追加する
          </router-link>
        </div>
      </div>
    </div>
    <div class="page-header">
      <h2 class="h4">検索</h2>
      <div class="row">
        <div class="form-group clearfix">
          <div class="d-block">
            <div class="col-md-2">
              <select class="form-control">
                <option value="">すべて</option>
                <option value="">条件A</option>
                <option value="">条件B</option>
                <option value="">条件C</option>
              </select>
            </div>
            <div class="d-block">
              <div class="col-md-4">
                <div class="form-search search-only">
                  <i class="search-icon glyphicon glyphicon-search"></i>
                  <input
                    type="text"
                    class="form-control search-query"
                    placeholder="キーワードを入力"
                    v-model="conditions.keyword"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <button type="button" class="btn btn-primary btn-block" @click="onClickSearch">
                  <i class="glyphicon glyphicon-search"></i> 検索する
                </button>
              </div>
              <div class="col-md-3">
                <button type="button" class="btn btn-block" @click="clearConditions()">
                  <i class="glyphicon glyphicon-remove"></i> 検索条件をリセット
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- form-group -->
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive">
            <table class="table table-bordered delivery_table">
              <thead>
                <tr>
                  <th class="col-xs-1 text-center">
                    <a href="#" @click.prevent.stop="onChangeOrderNo">
                      受注番号
                      <i v-if="conditions.order.orderNo == 'ASC'" class="glyphicon glyphicon-arrow-up"></i>
                      <i v-if="conditions.order.orderNo == 'DESC'" class="glyphicon glyphicon-arrow-down"></i>
                    </a>
                  </th>
                  <th class="col-xs-6 text-center">品名</th>
                  <th class="col-xs-1 text-center">部数</th>
                  <th class="col-xs-2 text-center">
                    <a href="#" @click.prevent.stop="onChangeDeliverDate">
                      納品日
                      <i v-if="conditions.order.deliverDate == 'ASC'" class="glyphicon glyphicon-arrow-up"></i>
                      <i v-if="conditions.order.deliverDate == 'DESC'" class="glyphicon glyphicon-arrow-down"></i>
                    </a>
                  </th>
                  <th class="col-xs-2 text-center">削除</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, rowIndex) in lists" :key="rowIndex">
                  <td class="text-center">{{ data.orderNo }}</td>
                  <td class="text-center">
                    <a href="" @click="onEdit(data.id)">
                      {{ data.magazineName }}<i class="glyphicon glyphicon-pencil"></i
                    ></a>
                  </td>
                  <td class="text-center">{{ $filters.toNumberFormat(data.amount) }}</td>
                  <td :class="{ 'text-center': true, 'no-deliver-date': !data.deliverDate }">
                    {{ $filters.toWorkDate(data.deliverDate) }}
                  </td>
                  <td class="text-center">
                    <button
                      type="button"
                      class="btn"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="削除する"
                      @click="onDelete(data.id)"
                    >
                      <i class="glyphicon glyphicon-trash"></i> 削除する
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-if="lists && lists.length > 0" class="col-md-12 text-center ogn_pagination-box">
          <ul class="pagination">
            <li class="arrow">
              <a
                href="#"
                :disabled="conditions.pageNo == 1 ? true : undefined"
                @click="
                  (e) => {
                    e.preventDefault()
                    if (conditions.pageNo == 1) return
                    onMovePage(conditions.pageNo - 1)
                    return
                  }
                "
              >
                <i class="glyphicon glyphicon-chevron-left"></i>
              </a>
            </li>
            <li v-for="page in maxPageNumber" :key="page" :class="{ active: conditions.pageNo == page }">
              <a
                href="#"
                @click="
                  (e) => {
                    e.preventDefault()
                    if (conditions.pageNo == page) return
                    onMovePage(page)
                    return
                  }
                "
              >
                {{ page }}
              </a>
            </li>
            <li class="arrow">
              <a
                href="#"
                :disabled="conditions.pageNo == maxPageNumber ? true : undefined"
                @click="
                  (e) => {
                    e.preventDefault()
                    if (conditions.pageNo == maxPageNumber) return
                    onMovePage(conditions.pageNo + 1)
                    return
                  }
                "
              >
                <i class="glyphicon glyphicon-chevron-right"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </default-layout>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { BaseView } from '@/components/view'
import { DefaultLayout } from '../../components/layout'
import { ApiService } from '@/modules/services/ApiService'
import { BaseApiRequest } from '@/modules/types'
import { Constants } from '@/modules/constants'
import { EnvironmentService } from '@/modules/services'

/** 依頼一覧画面 */
export default defineComponent({
  /** 名称 */
  name: 'request-list',
  /** 継承元指定 */
  extends: BaseView,
  /** コンポーネント */
  components: {
    DefaultLayout
  },
  /** データ定義 */
  data: () => ({
    /** 検索条件 */
    conditions: {
      /** キーワード */
      keyword: '',
      /** ページ番号 */
      pageNo: 1,
      /** 1ページあたりの件数 */
      countPerPage: EnvironmentService.get('VUE_APP_LIST_COUNT_PER_PAGE', '20').ToNumber(),
      /** 最大ページ数 */
      maxPageCount: 1,
      /** 並び順 */
      order: { orderNo: '', deliverDate: '' }
    },
    /** 初期検索条件 */
    defaultConditions: {
      /** キーワード */
      keyword: '',
      /** ページ番号 */
      pageNo: 1,
      /** 1ページあたりの件数 */
      countPerPage: EnvironmentService.get('VUE_APP_LIST_COUNT_PER_PAGE', '20').ToNumber(),
      /** 最大ページ数 */
      maxPageCount: 1,
      /** 並び順 */
      order: { orderNo: '', deliverDate: '' }
    },
    /** 一覧データ */
    lists: Array<{
      /** ID */
      id: string
      /** 受注番号 */
      orderNo: string
      /** 雑誌名 */
      magazineName: string
      /** 部数 */
      amount: number
      /** 納品日 */
      deliverDate: string
      /** 削除フラグ */
      isDelete: boolean
    }>(),
    /** 最大ページ番号 */
    maxPageNumber: 1
  }),
  /** セットアップスクリプト */
  setup(props, context) {},
  /** 関数定義 */
  methods: {
    /** 初期化処理 */
    async initialize() {
      // View定義
      this.setView('依頼一覧', 'request-list')
      // 条件初期化
      await this.clearConditions()
      // 検索処理
      await this.loadData(false)
    },
    /** 検索条件初期化 */
    async clearConditions(): Promise<void> {
      // 条件初期化
      // this.conditions = Object.assign(this.conditions, this.defaultConditions)
      this.conditions.keyword = this.defaultConditions.keyword
    },
    /** 受注番号並び順変更 */
    async onChangeOrderNo(): Promise<void> {
      // 並び順判定
      if (this.conditions.order.orderNo == 'DESC') {
        this.conditions.order.orderNo = 'ASC'
      } else {
        this.conditions.order.orderNo = 'DESC'
      }
      // 検索条件初期化
      this.conditions.pageNo = this.defaultConditions.pageNo
      this.conditions.countPerPage = this.defaultConditions.countPerPage
      this.conditions.order.deliverDate = this.defaultConditions.order.deliverDate
      // 再検索
      await this.loadData(false)
    },
    /** 納品日並び順変更 */
    async onChangeDeliverDate(): Promise<void> {
      // 並び順判定
      if (this.conditions.order.deliverDate == 'DESC') {
        this.conditions.order.deliverDate = 'ASC'
      } else {
        this.conditions.order.deliverDate = 'DESC'
      }
      // 検索条件初期化
      this.conditions.pageNo = this.defaultConditions.pageNo
      this.conditions.countPerPage = this.defaultConditions.countPerPage
      this.conditions.order.orderNo = this.defaultConditions.order.orderNo
      // 再検索
      await this.loadData(false)
    },
    /** ページ遷移 */
    async onMovePage(pageNo: number): Promise<void> {
      // ページ番号設定
      this.conditions.pageNo = pageNo
      // 再検索
      await this.loadData(false)
    },
    /** 検索ボタン押下 */
    async onClickSearch(): Promise<void> {
      // 検索条件初期化
      this.conditions.pageNo = this.defaultConditions.pageNo
      this.conditions.countPerPage = this.defaultConditions.countPerPage
      this.conditions.order.orderNo = this.defaultConditions.order.orderNo
      this.conditions.order.deliverDate = this.defaultConditions.order.deliverDate
      // 検索処理
      await this.loadData(true)
    },
    /** 検索処理 */
    async loadData(showAlert: boolean): Promise<void> {
      try {
        // ローディング開始
        this.showLoading()

        // リスト初期化
        this.lists = []
        // 最大ページ番号初期化
        this.maxPageNumber = 1

        // API定義
        const api: ApiService = new ApiService('/deliver/list')
        // POSTデータ
        const postData: BaseApiRequest = new BaseApiRequest({
          keyword: this.conditions.keyword,
          pageNo: this.conditions.pageNo,
          countPerPage: this.conditions.countPerPage,
          listMaxRows: EnvironmentService.get('VUE_APP_LIST_MAX_ROWS', '200').ToNumber(),
          order: JSON.stringify({
            orderNo: this.conditions.order.orderNo,
            deliverDate: this.conditions.order.deliverDate
          }),
          showDelete: false
        })

        // POST処理
        const response = await api.post(postData)

        // 検索結果判定
        if (response.statusCode == Constants.Api.ResponseCode.OK) {
          // 依頼一覧取得
          const deliverInfos: any[] = response.responseData.deliverInfos
          this.maxPageNumber = response.responseData.maxPageNumber as number
          // 一覧追加
          for (let i = 0; i < deliverInfos.length; i++) {
            this.lists.push({
              id: deliverInfos[i].id,
              orderNo: deliverInfos[i].order_no,
              magazineName: deliverInfos[i].magazine_name,
              amount: deliverInfos[i].amount,
              deliverDate: deliverInfos[i].deliver_date,
              isDelete: deliverInfos[i].is_delete == 1
            })
          }
        }

        // メッセージ表示有無
        if (showAlert && this.lists.length == 0) {
          // 対象データなし
          this.showInformation('対象データがありません', '検索結果')
        }
      } finally {
        // ローディング終了
        this.hideLoading()
      }
    },
    /** 編集処理 */
    onEdit(id: string) {
      // 編集ページへ遷移
      this.goto('request-edit', { requestId: id })
    },
    /** 削除処理 */
    onDelete(id: string) {
      // 削除確認
      this.showConfirm(
        '削除します<br />よろしいですか？',
        '削除確認',
        async (response: Constants.Message.ResponseCode) => {
          // 応答結果判定
          if (response == Constants.Message.ResponseCode.YES) {
            // 「はい」応答の場合
            try {
              // ローディング開始
              this.showLoading()

              // API低フィ
              const api: ApiService = new ApiService('/deliver/delete')

              // POSTデータ定義
              const postData: BaseApiRequest = new BaseApiRequest({ id: id })

              // 削除処理
              const response = await api.post(postData)

              // 処理結果判定
              if (response.statusCode == Constants.Api.ResponseCode.OK) {
                // 削除成功
                this.showInformation('削除しました', '削除成功', async () => {
                  // 再読み込み
                  await this.loadData(true)
                })
              } else {
                // 削除失敗
                this.showWarning('削除に失敗しました', '削除失敗')
              }
            } finally {
              // ローディング解除
              this.hideLoading()
            }
          }
        }
      )
    }
  }
})
</script>
