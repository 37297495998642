<template>
  <default-layout>
    <div class="row ctm_margin-b-no ctm_margin-t-10">
      <div class="col-md-12">
        <ol class="breadcrumb ctm_margin-b-no">
          <li><router-link :to="{ name: 'top' }">ホーム</router-link></li>
          <li class="active">作業予定表</li>
        </ol>
      </div>
    </div>
    <h1 class="h3"><i class="glyphicon glyphicon-list-alt"></i> 作業予定表</h1>
    <div class="page-header">
      <div class="row">
        <div class="col-md-3">
          <h2 class="h4"><span class="heading-note">作業予定表</span></h2>
        </div>
        <div class="col-md-2">
          <a v-if="isShowBeforeDate" :href="'/work/' + prevDate" class="btn btn-primary btn-block" role="button">
            <i class="glyphicon glyphicon-chevron-left"></i> 前日へ
          </a>
        </div>
        <div class="col-md-2">
          <a href="/work" class="btn btn-success btn-block" role="button"> 当日 </a>
        </div>
        <div class="col-md-2">
          <a :href="'/work/' + nextDate" class="btn btn-primary btn-block" role="button">
            翌日へ <i class="glyphicon glyphicon-chevron-right"></i>
          </a>
        </div>
        <div class="col-md-2">
          <date-control
            id="calendarDate"
            :editable="false"
            v-model="conditions.calendarDate"
            :minDate="minDate"
            @update:model-value="
              () => {
                onCalendarChange()
              }
            "
          />
          <a href="#" id="moveCalendar" />
        </div>
        <template v-if="pageList && pageList[pageNo]">
          <div
            v-for="(page, pageIndex) in pageList[pageNo]"
            :key="pageIndex"
            :class="{ 'col-md-12': pageIndex == 0, 'col-md-6': pageIndex != 0 }"
          >
            <work-table
              :showTodayTitle="lists[page].workDate == today"
              :workDate="lists[page].workDate"
              :timeAreas="timeAreas"
              :workItems="taskItems"
              :works="lists[page].works"
              @showDetail="onShowDetail"
              @move="onMoveTask"
              isDraggable
              :canMove="this.loginInfo.userType != '01'"
            />
          </div>
        </template>
        <div v-if="false && pageList && pageList[pageNo]" class="col-md-12 text-center ogn_pagination-box">
          <ul class="pagination">
            <li class="arrow">
              <a href="#" :disabled="pageNo == 1 ? true : undefined" @click="onDecrementPage">
                <i class="glyphicon glyphicon-chevron-left"></i>
              </a>
            </li>
            <li :class="{ active: pageNo == 1 }">
              <a
                href="#"
                @click="
                  (e) => {
                    e.preventDefault()
                    onMovePage(1)
                    return
                  }
                "
              >
                1
              </a>
            </li>
            <li :class="{ active: pageNo == 2 }">
              <a
                href="#"
                @click="
                  (e) => {
                    e.preventDefault()
                    onMovePage(2)
                    return
                  }
                "
              >
                2
              </a>
            </li>
            <li :class="{ active: pageNo == 3 }">
              <a
                href="#"
                @click="
                  (e) => {
                    e.preventDefault()
                    onMovePage(3)
                    return
                  }
                "
              >
                3
              </a>
            </li>
            <li class="arrow">
              <a href="#" :disabled="pageNo == 3 ? true : undefined" @click="onIncrementPage">
                <i class="glyphicon glyphicon-chevron-right"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <base-dialog id="editDialog" width="720px">
      <template v-slot:header-title>
        <h4>
          <div class="modal-title" id="myModalLabel">{{ modalParameter.title }}&nbsp;</div>
        </h4>
        <template v-if="loginInfo.userType != '01'">
          <div class="table-responsive">
            <table class="table table-bordered task_card_modal">
              <tbody>
                <tr>
                  <th class="col-xs-3 text-center">ボード</th>
                  <td class="col-xs-9">
                    <date-control id="workDate" v-model="modalParameter.workDate" />
                    <p v-if="modalErrors.workDate" class="caution" v-html="modalErrors.workDate" />
                  </td>
                </tr>
                <tr>
                  <th class="col-xs-3 text-center">工程</th>
                  <td class="col-xs-9">
                    <select class="form-control" v-model="modalParameter.taskItemCode">
                      <option v-for="(task, index) in taskItems" :key="index" :value="task.code">
                        {{ task.value }}
                      </option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <th class="col-xs-3 text-center">リスト</th>
                  <td class="col-xs-9">
                    <select class="form-control" v-model="modalParameter.timeAreaCode">
                      <option v-for="(timeArea, index) in timeAreas" :key="index" :value="timeArea.code">
                        {{ timeArea.value }}
                      </option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row">
            <div class="col-md-2 mr-auto">
              <a href="#" class="btn btn-primary btn-block" role="button" @click="onModalMoveTask">移動</a>
            </div>
          </div>
        </template>
      </template>
      <template v-slot:body>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-6">
              <a
                href="#"
                class="btn btn-primary btn-block"
                role="button"
                :disabled="
                  modalParameter.startAt != '' ||
                  modalParameter.completeAt != '' ||
                  modalParameter.cancelAt != '' ||
                  modalParameter.isMissLoss
                    ? true
                    : undefined
                "
                @click="onModalTaskStart"
              >
                作業開始
              </a>
            </div>
            <div class="col-md-6">
              <a
                href="#"
                class="btn btn-primary btn-block"
                role="button"
                :disabled="
                  modalParameter.completeAt != '' || modalParameter.cancelAt != '' || modalParameter.isMissLoss
                    ? true
                    : undefined
                "
                @click="onModalTaskComplete"
              >
                作業完了
              </a>
            </div>
            <div class="col-md-6">
              <a
                href="#"
                class="btn btn-primary btn-block"
                role="button"
                :disabled="
                  modalParameter.completeAt != '' || modalParameter.cancelAt != '' || modalParameter.isMissLoss
                    ? true
                    : undefined
                "
                @click="onModalTaskCancel"
              >
                作業キャンセル
              </a>
            </div>
            <div v-if="loginInfo.userType != '01'" class="col-md-6">
              <a href="#" class="btn btn-primary btn-block" role="button" @click="onModalTaskClone">作業複製</a>
            </div>
            <div class="col-md-6">
              <a
                href="#"
                class="btn btn-danger btn-block"
                role="button"
                :disabled="
                  modalParameter.completeAt != '' || modalParameter.cancelAt != '' || modalParameter.isMissLoss
                    ? true
                    : undefined
                "
                @click="onModalTaskMissLoss"
              >
                ミス・ロス
              </a>
            </div>
            <div v-if="loginInfo.userType != '01'" class="col-md-6">
              <a href="#" class="btn btn-primary btn-block" role="button" @click="onModalTaskArchive">アーカイブ</a>
            </div>
          </div>
        </div>
        <!-- modal-body -->
        <div v-if="loginInfo.userType != '01'" class="modal-body">
          <div class="row">
            <div class="col-md-6">
              <h6>担当者</h6>
              <p>
                <select class="form-control" v-model="modalParameter.userId">
                  <option value="">選択してください</option>
                  <option v-for="(person, index) in persons" :key="index" :value="person.id">
                    {{ person.user_name }}
                  </option>
                </select>
              </p>
              <p v-if="modalErrors.userId" class="caution" v-html="modalErrors.userId" />
            </div>
          </div>
        </div>
        <!-- modal-body -->
        <div class="modal-body bikou">
          <h6>備考</h6>
          <textarea
            class="form-control"
            id="floatingTextarea"
            :readonly="loginInfo.userType != '01'"
            v-model="modalParameter.remarks"
          />
        </div>
      </template>
      <template v-slot:footer>
        <div class="row">
          <div class="col-md-12 mr-auto">
            <a href="" class="btn btn-success btn-block btn-lg" role="button" @click="onDialogRegist"> 登録 </a>
          </div>
        </div>
      </template>
    </base-dialog>
  </default-layout>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { BaseView } from '@/components/view'
import { DefaultLayout } from '../../components/layout'
import { ApiService } from '@/modules/services/ApiService'
import { BaseApiRequest, WorkItem } from '@/modules/types'
import { Constants } from '@/modules/constants'
import { WorkTable } from '../common/parts'
import { BaseDialog } from '@/components/dialog'
import { DateControl } from '@/components/controls'
import { loginInfo } from '@/store/LoginInfo'

/** 作業予定表画面 */
export default defineComponent({
  /** 名称 */
  name: 'work-list',
  /** 継承元指定 */
  extends: BaseView,
  /** コンポーネント */
  components: {
    DefaultLayout,
    WorkTable,
    BaseDialog,
    DateControl
  },
  /** コンピュート定義 */
  computed: {
    /** 前日表示判定 */
    isShowBeforeDate(): boolean {
      return this.conditions.workDateFrom > this.minDate
    },
    /** 前日 */
    prevDate(): string | undefined {
      return this.conditions.workDateFrom.ToDate()?.addDate(-1).ToString()
    },
    /** 翌日 */
    nextDate(): string | undefined {
      return this.conditions.workDateFrom.ToDate()?.addDate(1).ToString()
    }
  },
  /** データ定義 */
  data: () => ({
    /** ログイン情報 */
    loginInfo: loginInfo,
    /** 最小日付 */
    minDate: new Date().addDate(-7).ToString(),
    /** 当日 */
    today: new Date().ToString(),
    /** 読み込み判定 */
    isLoading: true,
    /** ページ番号 */
    pageNo: 1,
    /** 1ページあたりの件数 */
    countPerPage: 3,
    /** ページ数 */
    pageCount: 0,
    /** ページリスト */
    pageList: Array<number[]>(),
    /** 時間帯 */
    timeAreas: Array<any>(),
    /** 作業項目 */
    taskItems: Array<any>(),
    /** 一覧データ */
    lists: Array<any>(),
    /** 選択パラメータ */
    selectedParameter: undefined,
    /** 検索条件 */
    conditions: {
      /** 作業日開始 */
      workDateFrom: new Date().ToString(),
      /** 作業日終了 */
      workDateTo: new Date().addDate(2).ToString(),
      /** カレンダー日付 */
      calendarDate: new Date().ToString()
    },
    /** 担当者リスト */
    persons: Array<any>(),
    /** モーダルパラメータ */
    modalParameter: {
      /** ID */
      id: '',
      /** タイトル */
      title: '',
      /** 作業日 */
      workDate: '',
      /** タスク項目コード */
      taskItemCode: '',
      /** 時間帯コード */
      timeAreaCode: '',
      /** 担当者 */
      userId: '',
      /** ミスロスフラグ */
      isMissLoss: false,
      /** 開始日時 */
      startAt: '',
      /** 完了日時 */
      completeAt: '',
      /** キャンセル日時 */
      cancelAt: '',
      /** 背景色 */
      backgroundColor: '',
      /** 備考 */
      remarks: '',
      /** 削除フラグ */
      isDelete: false
    },
    /** モーダルエラー表示 */
    modalErrors: {
      /** 作業日 */
      workDate: '',
      /** 担当者 */
      userId: ''
    }
  }),
  /** セットアップスクリプト */
  setup(props, context) {},
  /** 関数定義 */
  methods: {
    /** 初期化処理 */
    async initialize() {
      try {
        this.isLoading = true
        // 作業日パラメータ取得
        const workDate = this.$route.params.workDate ? this.$route.params.workDate.toString().ToDate() : undefined
        // パラメータ有無
        if (workDate) {
          // 作業日設定
          this.conditions.workDateFrom = workDate.ToString()
          this.conditions.workDateTo = workDate.addDate(2).ToString()
          this.conditions.calendarDate = workDate.ToString()
        }
        // View定義
        this.setView('作業予定表', 'work-list')
        // 検索処理
        await this.loadData(false)
      } finally {
        this.isLoading = false
      }
    },
    /** 検索処理 */
    async loadData(showAlert: boolean): Promise<void> {
      try {
        // ローディング開始
        this.showLoading()

        // リスト初期化
        this.lists = []

        // ページ番号初期化
        this.pageNo = 1

        // ページリスト初期化
        this.pageList = []

        // ページ数初期化
        this.pageCount = 0
        this.taskItems = []
        this.timeAreas = []
        this.persons = []

        // API定義
        const api: ApiService = new ApiService('/work/list')

        // POSTデータ定義
        const postData: BaseApiRequest = new BaseApiRequest({
          workDateFrom: this.conditions.workDateFrom,
          workDateTo: this.conditions.workDateTo,
          userId: this.loginInfo.userType == '01' ? this.loginInfo.userId : ''
        })

        // 作業情報取得
        const response = await api.post(postData)

        // 処理結果判定
        if (response.statusCode == Constants.Api.ResponseCode.OK) {
          // 時間帯取得
          const timeAreas: any[] = response.responseData.timeAreas
          // 時間帯を設定
          for (let i = 0; i < timeAreas.length; i++) {
            // 時間帯追加
            this.timeAreas.push(timeAreas[i])
          }
          // 作業項目取得
          const taskItems: any[] = response.responseData.taskItems
          // 作業項目を設定
          for (let i = 0; i < taskItems.length; i++) {
            // 作業項目追加
            this.taskItems.push(taskItems[i])
          }
          // 担当者取得
          const persons: any[] = response.responseData.persons
          // 担当者を設定
          for (let i = 0; i < persons.length; i++) {
            // 担当者追加
            this.persons.push({ id: String(persons[i].id), user_name: String(persons[i].user_name) })
          }
          const workLists: any = response.responseData.workInfos
          const workDateKeys = Object.keys(workLists)

          let pageListIndex = 0

          // 取得結果をループ処理
          for (let i = 0; i < workDateKeys.length; i++) {
            const workData = workLists[workDateKeys[i]]
            // 作業項目キー取得
            const taskItemKeys = Object.keys(workData)

            // タスク項目リスト
            const taskItemList: any = {}

            for (let j = 0; j < taskItemKeys.length; j++) {
              // 作業項目取得
              const taskItems: any = workData[taskItemKeys[j]]
              // 時間帯キー取得
              const timeAreaKeys = Object.keys(taskItems)

              // 時間帯リスト
              const timeAreaList: any = {}

              for (let k = 0; k < timeAreaKeys.length; k++) {
                // 作業情報取得
                const works: any[] = taskItems[timeAreaKeys[k]]
                // 作業項目リスト定義
                const workItemList: Array<WorkItem> = []

                for (let l = 0; l < works.length; l++) {
                  const work = works[l]
                  // 作業項目リストに追加
                  workItemList.push({
                    id: work.id,
                    magazineTitle: work.DeliverInfos.magazine_name,
                    workDate: work.work_date,
                    timeAreaCode: timeAreaKeys[k],
                    workCode: taskItemKeys[j],
                    workName: work.task_item_name,
                    userId: work.user_master_id ? String(work.user_master_id) : '',
                    userName: work.UserMasters ? work.UserMasters.user_name : '',
                    remarks: work.remarks ? work.remarks : '',
                    backgroundColor: work.background_color ? work.background_color : '',
                    isMissLoss: work.WorkStatusInfos ? work.WorkStatusInfos.is_miss_loss == 1 : false,
                    startAt: work.WorkStatusInfos && work.WorkStatusInfos.start_at ? work.WorkStatusInfos.start_at : '',
                    completeAt:
                      work.WorkStatusInfos && work.WorkStatusInfos.complete_at ? work.WorkStatusInfos.complete_at : '',
                    cancelAt:
                      work.WorkStatusInfos && work.WorkStatusInfos.cancel_at ? work.WorkStatusInfos.cancel_at : '',
                    isDelete: work.is_delete ? work.is_delete == 1 : false
                  })
                }
                // 時間帯リストに追加
                timeAreaList[timeAreaKeys[k]] = workItemList
              }
              // タスク項目リストに追加
              taskItemList[taskItemKeys[j]] = timeAreaList
            }
            // ページリスト変更有無
            if (i % this.countPerPage == 0) {
              // ページリストインデックス
              pageListIndex = Math.floor(i / this.countPerPage) + 1
              this.pageList[pageListIndex] = []
            }
            this.pageList[pageListIndex].push(i)
            // リストに追加
            this.lists.push({ workDate: workDateKeys[i], works: taskItemList })
          }

          // ページ数設定
          this.pageCount = Math.ceil(workDateKeys.length / this.countPerPage)
        }

        // メッセージ表示有無
        if (showAlert && this.lists.length == 0) {
          // 対象データなし
          this.showInformation('対象データがありません', '検索結果')
        }
      } finally {
        // ローディング終了
        this.hideLoading()
      }
    },
    /** 作業日移動 */
    onMoveWorkDate(workDate?: string) {
      // ローディング判定
      if (this.isLoading) return
      // 作業日指定有無
      if (!workDate || workDate == '') return
      // 現在の作業日と比較
      if (this.conditions.workDateFrom == workDate) return
      // 指定された作業日に遷移
      if (workDate == this.today) {
        this.$router.push({
          name: 'work-list',
          force: true
        })
      } else {
        this.$router.push({
          name: 'work-list',
          params: { workDate: workDate },
          force: true
        })
      }
    },
    onCalendarChange() {
      // ローディング判定
      if (this.isLoading) return
      // 入力有無
      if (this.conditions.calendarDate == '' || !this.conditions.calendarDate) return
      const link = document.getElementById('moveCalendar') as any
      if (!link) return
      link.href = '/work/' + this.conditions.calendarDate
      link.click()
      return
      const workDate = this.conditions.calendarDate
      // 作業日移動
      this.$router.push({
        name: 'work-list',
        params: { workDate: workDate },
        force: true
      })
    },
    /** 次ページ移動 */
    async onIncrementPage(e: any) {
      // 標準イベントキャンセル
      e.preventDefault()
      if (this.pageNo == 3) return
      this.pageNo += 1
      return
    },
    /** ページ移動 */
    async onMovePage(pageNo: number) {
      this.pageNo = pageNo
    },
    /** 前ページ移動 */
    async onDecrementPage(e: any) {
      // 標準イベントキャンセル
      e.preventDefault()
      if (this.pageNo == 1) return
      this.pageNo -= 1
      return
    },
    /** 明細表示 */
    async onShowDetail(parameter: any) {
      // コンポーネント取得
      const dialog = $('#editDialog') as any
      // ダイアログ有無
      if (dialog) {
        this.selectedParameter = Object.assign({}, parameter.item)
        const item = parameter.item as WorkItem
        // パラメータ設定
        this.modalParameter.id = String(item.id)
        this.modalParameter.title = '{0}　{1}'.format(
          String(item.magazineTitle),
          new Date(String(item.workDate)).ToString(Constants.Format.DateFormatType.WORK_LIST)
        )
        this.modalParameter.workDate = String(item.workDate)
        this.modalParameter.taskItemCode = String(item.workCode)
        this.modalParameter.timeAreaCode = String(item.timeAreaCode)
        this.modalParameter.isMissLoss = item.isMissLoss ? item.isMissLoss : false
        this.modalParameter.startAt = String(item.startAt)
        this.modalParameter.completeAt = String(item.completeAt)
        this.modalParameter.cancelAt = String(item.cancelAt)
        this.modalParameter.backgroundColor = String(item.backgroundColor)
        this.modalParameter.userId = String(item.userId)
        this.modalParameter.remarks = String(item.remarks)
        this.modalParameter.isDelete = item.isDelete ? item.isDelete : false
        // ダイアログ表示
        dialog.modal('show')
      }
    },
    /** 移動ボタンクリック */
    async onModalMoveTask(e: any) {
      // 標準イベントキャンセル
      e.preventDefault()
      // エラークリア
      this.modalErrors = { workDate: '', userId: '' }

      // 作業日入力チェック
      if (!this.modalParameter.workDate) {
        // 作業日未入力
        this.modalErrors.workDate = '入力してください'
        return
      }
      // 実施確認
      this.showConfirm(
        '移動します<br />よろしいですか？',
        '移動確認',
        async (result: Constants.Message.ResponseCode) => {
          // 応答結果判定
          if (result == Constants.Message.ResponseCode.YES) {
            // 移動処理
            try {
              // ローディング開始
              this.showLoading()
              // API定義
              const api: ApiService = new ApiService('/task/update/move')
              // POSTデータ定義
              const postData: BaseApiRequest = new BaseApiRequest({
                id: this.modalParameter.id,
                workDate: this.modalParameter.workDate,
                taskItemCode: this.modalParameter.taskItemCode,
                timeAreaCode: this.modalParameter.timeAreaCode
              })
              // 更新処理
              const response = await api.post(postData)
              // 更新結果判定
              if (response.statusCode == Constants.Api.ResponseCode.OK && this.selectedParameter) {
                // 処理OKの場合
                const selected = this.selectedParameter as WorkItem
                // 移動元リスト取得
                let fromList = this.lists.find((x) => x.workDate == selected.workDate).works
                fromList = fromList[String(selected.workCode)][String(selected.timeAreaCode)] as any[]
                // 対象の項目取得
                const target = fromList.find((x: any) => x.id == this.modalParameter.id) as WorkItem
                // 移動先範囲内判定
                if (
                  this.conditions.workDateFrom <= this.modalParameter.workDate &&
                  this.modalParameter.workDate <= this.conditions.workDateTo
                ) {
                  // 範囲内の場合
                  // 移動先リスト取得
                  let toList = this.lists.find((x) => x.workDate == this.modalParameter.workDate).works
                  toList = toList[this.modalParameter.taskItemCode][this.modalParameter.timeAreaCode] as any[]
                  // 対象を上書き
                  target.workDate = this.modalParameter.workDate
                  target.workCode = this.modalParameter.taskItemCode
                  target.timeAreaCode = this.modalParameter.timeAreaCode
                  // 移動先へコピー
                  toList.push(Object.assign({}, target))
                }
                const targetIndex = fromList.indexOf(target)
                // 移動元から削除
                fromList.splice(targetIndex, 1)
                // コンポーネント取得
                const dialog = $('#editDialog') as any
                // ダイアログ有無
                if (dialog) {
                  // ダイアログ表示
                  dialog.modal('hide')
                }
              }
            } finally {
              // ローディング解除
              this.hideLoading()
            }
          }
        }
      )
      return
    },
    /**
     * タスク開始処理
     */
    async onModalTaskStart(e: any) {
      // 標準イベントキャンセル
      e.preventDefault()
      // 実施確認
      this.showConfirm(
        '開始します<br />よろしいですか？',
        '開始確認',
        async (result: Constants.Message.ResponseCode) => {
          // 応答結果判定
          if (result == Constants.Message.ResponseCode.YES) {
            try {
              // ローディング開始
              this.showLoading()
              // API定義
              const api: ApiService = new ApiService('/task/update/start')
              // POSTデータ定義
              const postData: BaseApiRequest = new BaseApiRequest({ id: this.modalParameter.id })
              // 開始更新
              const response = await api.post(postData)

              // 処理結果判定
              if (response.statusCode == Constants.Api.ResponseCode.OK && this.selectedParameter) {
                // 作業ステータス情報取得
                const workStatusInfo = response.responseData.workStatusInfo
                // 開始日時設定
                this.modalParameter.startAt = workStatusInfo.start_at
                // 処理OKの場合
                const selected = this.selectedParameter as WorkItem
                // 移動元リスト取得
                let fromList = this.lists.find((x) => x.workDate == selected.workDate).works
                fromList = fromList[String(selected.workCode)][String(selected.timeAreaCode)] as any[]
                // 対象の項目取得
                const target = fromList.find((x: any) => x.id == this.modalParameter.id) as WorkItem
                // 開始日時設定
                target.startAt = workStatusInfo.start_at
              }
            } finally {
              // ローディング解除
              this.hideLoading()
            }
          }
        }
      )
      return
    },
    /**
     * タスク完了処理
     */
    async onModalTaskComplete(e: any) {
      // 標準イベントキャンセル
      e.preventDefault()
      // 実施確認
      this.showConfirm(
        '完了します<br />よろしいですか？',
        '完了確認',
        async (result: Constants.Message.ResponseCode) => {
          // 応答結果判定
          if (result == Constants.Message.ResponseCode.YES) {
            try {
              // ローディング開始
              this.showLoading()
              // API定義
              const api: ApiService = new ApiService('/task/update/complete')
              // POSTデータ定義
              const postData: BaseApiRequest = new BaseApiRequest({ id: this.modalParameter.id })
              // 完了更新
              const response = await api.post(postData)

              // 処理結果判定
              if (response.statusCode == Constants.Api.ResponseCode.OK && this.selectedParameter) {
                // 作業ステータス情報取得
                const workStatusInfo = response.responseData.workStatusInfo
                // 日時設定
                this.modalParameter.startAt = workStatusInfo.start_at
                this.modalParameter.completeAt = workStatusInfo.complete_at
                // 処理OKの場合
                const selected = this.selectedParameter as WorkItem
                // 移動元リスト取得
                let fromList = this.lists.find((x) => x.workDate == selected.workDate).works
                fromList = fromList[String(selected.workCode)][String(selected.timeAreaCode)] as any[]
                // 対象の項目取得
                const target = fromList.find((x: any) => x.id == this.modalParameter.id) as WorkItem
                // 日時設定
                target.startAt = workStatusInfo.start_at
                target.completeAt = workStatusInfo.complete_at
              }
            } finally {
              // ローディング解除
              this.hideLoading()
            }
          }
        }
      )
      return
    },
    /**
     * タスクキャンセル処理
     */
    async onModalTaskCancel(e: any) {
      // 標準イベントキャンセル
      e.preventDefault()
      // 実施確認
      this.showConfirm(
        'キャンセルします<br />よろしいですか？',
        'キャンセル確認',
        async (result: Constants.Message.ResponseCode) => {
          // 応答結果判定
          if (result == Constants.Message.ResponseCode.YES) {
            try {
              // ローディング開始
              this.showLoading()
              // API定義
              const api: ApiService = new ApiService('/task/update/cancel')
              // POSTデータ定義
              const postData: BaseApiRequest = new BaseApiRequest({ id: this.modalParameter.id })
              // キャンセル更新
              const response = await api.post(postData)

              // 処理結果判定
              if (response.statusCode == Constants.Api.ResponseCode.OK && this.selectedParameter) {
                // 作業ステータス情報取得
                const workStatusInfo = response.responseData.workStatusInfo
                // キャンセル日時設定
                this.modalParameter.cancelAt = workStatusInfo.cancel_at
                // 処理OKの場合
                const selected = this.selectedParameter as WorkItem
                // 移動元リスト取得
                let fromList = this.lists.find((x) => x.workDate == selected.workDate).works
                fromList = fromList[String(selected.workCode)][String(selected.timeAreaCode)] as any[]
                // 対象の項目取得
                const target = fromList.find((x: any) => x.id == this.modalParameter.id) as WorkItem
                // キャンセル日時設定
                target.cancelAt = workStatusInfo.cancel_at
              }
            } finally {
              // ローディング解除
              this.hideLoading()
            }
          }
        }
      )
      return
    },
    /**
     * タスクミス・ロス処理
     */
    async onModalTaskMissLoss(e: any) {
      // 標準イベントキャンセル
      e.preventDefault()
      // 実施確認
      this.showConfirm(
        'ミス・ロスにします<br />よろしいですか？',
        '更新確認',
        async (result: Constants.Message.ResponseCode) => {
          // 応答結果判定
          if (result == Constants.Message.ResponseCode.YES) {
            try {
              // ローディング開始
              this.showLoading()
              // API定義
              const api: ApiService = new ApiService('/task/update/miss')
              // POSTデータ定義
              const postData: BaseApiRequest = new BaseApiRequest({ id: this.modalParameter.id })
              // ミス・ロス更新
              const response = await api.post(postData)

              // 処理結果判定
              if (response.statusCode == Constants.Api.ResponseCode.OK && this.selectedParameter) {
                // 作業ステータス情報取得
                const workStatusInfo = response.responseData.workStatusInfo
                // ミス・ロス設定
                this.modalParameter.isMissLoss = workStatusInfo.is_miss_loss == 1
                // 処理OKの場合
                const selected = this.selectedParameter as WorkItem
                // 移動元リスト取得
                let fromList = this.lists.find((x) => x.workDate == selected.workDate).works
                fromList = fromList[String(selected.workCode)][String(selected.timeAreaCode)] as any[]
                // 対象の項目取得
                const target = fromList.find((x: any) => x.id == this.modalParameter.id) as WorkItem
                // ミス・ロス設定
                target.isMissLoss = workStatusInfo.is_miss_loss == 1
              }
            } finally {
              // ローディング解除
              this.hideLoading()
            }
          }
        }
      )
      return
    },
    /**
     * アーカイブ処理
     */
    async onModalTaskArchive(e: any) {
      // 標準イベントキャンセル
      e.preventDefault()
      // 実施確認
      this.showConfirm(
        'タスクを非表示にします<br />よろしいですか？',
        '更新確認',
        async (result: Constants.Message.ResponseCode) => {
          // 応答結果判定
          if (result == Constants.Message.ResponseCode.YES) {
            try {
              // ローディング開始
              this.showLoading()
              // API定義
              const api: ApiService = new ApiService('/task/update/archive')
              // POSTデータ定義
              const postData: BaseApiRequest = new BaseApiRequest({ id: this.modalParameter.id, isArchive: 1 })
              // ミス・ロス更新
              const response = await api.post(postData)

              // 処理結果判定
              if (response.statusCode == Constants.Api.ResponseCode.OK && this.selectedParameter) {
                // ミス・ロス設定
                this.modalParameter.isDelete = true
                // 処理OKの場合
                const selected = this.selectedParameter as WorkItem
                // 移動元リスト取得
                let fromList = this.lists.find((x) => x.workDate == selected.workDate).works
                fromList = fromList[String(selected.workCode)][String(selected.timeAreaCode)] as any[]
                // 対象の項目取得
                const target = fromList.find((x: any) => x.id == this.modalParameter.id) as WorkItem
                // 削除フラグ設定
                target.isDelete = true
                // 完了メッセージ
                this.showInformation('更新しました', '更新完了', async () => {
                  // コンポーネント取得
                  const dialog = $('#editDialog') as any
                  // ダイアログ有無
                  if (dialog) {
                    // ダイアログ表示
                    dialog.modal('hide')
                  }
                })
              }
            } finally {
              // ローディング解除
              this.hideLoading()
            }
          }
        }
      )
      return
    },
    /**
     * タスク複製処理
     */
    async onModalTaskClone(e: any) {
      // 標準イベントキャンセル
      e.preventDefault()
      // 実施確認
      this.showConfirm(
        '複製します<br />よろしいですか？',
        '複製確認',
        async (result: Constants.Message.ResponseCode) => {
          // 応答結果判定
          if (result == Constants.Message.ResponseCode.YES) {
            try {
              // ローディング開始
              this.showLoading()
              // API定義
              const api: ApiService = new ApiService('/task/clone')
              // POSTデータ定義
              const postData: BaseApiRequest = new BaseApiRequest({ id: this.modalParameter.id })
              // 作業複製
              const response = await api.post(postData)

              // 処理結果判定
              if (response.statusCode == Constants.Api.ResponseCode.OK && this.selectedParameter) {
                // 処理OKの場合
                // 作業情報ID取得
                const workInfoId = response.responseData.workInfoId
                // 複写元取得
                const selected = this.selectedParameter as WorkItem
                // 移動元リスト取得
                let fromList = this.lists.find((x) => x.workDate == selected.workDate).works
                fromList = fromList[String(selected.workCode)][String(selected.timeAreaCode)] as any[]
                // 初期化
                selected.id = workInfoId
                selected.startAt = ''
                selected.completeAt = ''
                selected.cancelAt = ''
                selected.isMissLoss = false
                // リストに追加
                fromList.push(selected)
                // 完了メッセージ
                this.showInformation('複製しました', '複製完了', async () => {
                  // コンポーネント取得
                  const dialog = $('#editDialog') as any
                  // ダイアログ有無
                  if (dialog) {
                    // ダイアログ表示
                    dialog.modal('hide')
                  }
                })
              }
            } finally {
              // ローディング解除
              this.hideLoading()
            }
          }
        }
      )
      return
    },
    /**
     * タスク移動処理
     */
    async onMoveTask(parameter: any) {
      // 対象タスクID取得
      const targetId = String(parameter.id)
      // 移動元、移動先取得
      const from: {
        workDate: string
        timeAreaCode: string
        WorkItemCode: string
      } = parameter.from
      const to: {
        workDate: string
        timeAreaCode: string
        WorkItemCode: string
      } = parameter.to
      // 移動元取得
      const toWorks = this.lists.find((x) => x.workDate == to.workDate).works
      const toItem = toWorks[to.WorkItemCode][to.timeAreaCode].find((x: any) => x.id == targetId) as WorkItem
      // 対象有無
      if (toItem) {
        try {
          // ローディング開始
          this.showLoading()
          // API定義
          const api: ApiService = new ApiService('/task/update/move')
          // POSTデータ定義
          const postData: BaseApiRequest = new BaseApiRequest({
            id: targetId,
            workDate: to.workDate,
            taskItemCode: to.WorkItemCode,
            timeAreaCode: to.timeAreaCode
          })
          // タスク更新
          const response = await api.post(postData)
          // 更新結果判定
          if (response.statusCode == Constants.Api.ResponseCode.OK) {
            // 要素の編集
            toItem.workDate = to.workDate
            toItem.timeAreaCode = to.timeAreaCode
            toItem.workCode = to.WorkItemCode
          }
        } finally {
          // ローディング非表示
          this.hideLoading()
        }
      }
    },
    /**
     * 登録処理
     */
    async onDialogRegist(e: any) {
      e.preventDefault()
      // ユーザー種別判定
      if (this.loginInfo.userType == '01') {
        // 担当者の場合
        // 登録処理
        await this.executeRegistNormal()
      } else {
        // 担当者以外の場合
        // 登録処理
        await this.executeRegistAdmin()
      }
      return
    },
    /**
     * 担当者向け登録処理
     */
    async executeRegistNormal() {
      // 更新確認
      this.showConfirm(
        '更新します<br />よろしいですか？',
        '更新確認',
        async (result: Constants.Message.ResponseCode) => {
          // 応答結果判定
          if (result == Constants.Message.ResponseCode.YES) {
            try {
              // ローディング開始
              this.showLoading()
              // API定義
              const api: ApiService = new ApiService('/task/update/remarks')
              // POSTデータ定義
              const postData: BaseApiRequest = new BaseApiRequest({
                id: this.modalParameter.id,
                remarks: this.modalParameter.remarks
              })
              // 担当者更新
              const response = await api.post(postData)
              // 更新結果判定
              if (response.statusCode == Constants.Api.ResponseCode.OK) {
                // 更新OK
                // 更新完了
                this.showInformation('更新しました', '更新完了', () => {
                  if (this.selectedParameter) {
                    // 処理OKの場合
                    const selected = this.selectedParameter as WorkItem
                    // 移動元リスト取得
                    let fromList = this.lists.find((x) => x.workDate == selected.workDate).works
                    fromList = fromList[String(selected.workCode)][String(selected.timeAreaCode)] as any[]
                    // 対象の項目取得
                    const target = fromList.find((x: any) => x.id == this.modalParameter.id) as WorkItem
                    // 備考設定
                    target.remarks = this.modalParameter.remarks
                  }
                })
              }
            } finally {
              // ローディング解除
              this.hideLoading()
            }
          }
        }
      )
    },
    /**
     * 管理者向け登録処理
     */
    async executeRegistAdmin() {
      // エラークリア
      this.modalErrors = { workDate: '', userId: '' }

      // 担当者入力チェック
      if (!this.modalParameter.userId) {
        // 担当者未入力
        this.modalErrors.userId = '担当者を指定してください'
        return
      }
      // 更新確認
      this.showConfirm(
        '更新します<br />よろしいですか？',
        '更新確認',
        async (result: Constants.Message.ResponseCode) => {
          // 応答結果判定
          if (result == Constants.Message.ResponseCode.YES) {
            try {
              // ローディング開始
              this.showLoading()
              // API定義
              const api: ApiService = new ApiService('/task/update/person')
              // POSTデータ定義
              const postData: BaseApiRequest = new BaseApiRequest({
                id: this.modalParameter.id,
                userId: this.modalParameter.userId
              })
              // 担当者更新
              const response = await api.post(postData)
              // 更新結果判定
              if (response.statusCode == Constants.Api.ResponseCode.OK) {
                // 更新OK
                // 更新完了
                this.showInformation('更新しました', '更新完了', () => {
                  if (this.selectedParameter) {
                    // 処理OKの場合
                    const selected = this.selectedParameter as WorkItem
                    // 移動元リスト取得
                    let fromList = this.lists.find((x) => x.workDate == selected.workDate).works
                    fromList = fromList[String(selected.workCode)][String(selected.timeAreaCode)] as any[]
                    // 対象の項目取得
                    const target = fromList.find((x: any) => x.id == this.modalParameter.id) as WorkItem
                    // 担当者設定
                    target.userId = this.modalParameter.userId
                  }
                })
              }
            } finally {
              // ローディング解除
              this.hideLoading()
            }
          }
        }
      )
    }
  }
})
</script>
<style lang="stylus">
.work-list
  #calendarDate
    .dp__clear_icon
      display none
</style>
